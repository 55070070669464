<!-- @format -->

<style scoped>
  /* header Start*/

  .displaynone {
    display: none;
  }

  .blurBackground {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1% 6%;
    box-shadow: 0 4px 2px -2px #4141415a;
    top: 0;
    background: #ffffff;
    z-index: 1;
    position: sticky;
  }

  .header-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  #current {
    color: #034ea2;
  }

  #current-one {
    text-decoration: underline;
  }

  .header-one p {
    font-size: 17px;
    cursor: pointer;
  }

  .header-one button {
    height: 6vh;
    width: 9vw;
    border: none;
    background-color: #03931b;
    color: #ffffff;
    font-size: 13px;
    border-radius: 7px;
    cursor: pointer;
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 600px) {
    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      width: 10vw;
      text-align: center;
    }

    .header-one img {
      display: none;
    }

    .applynow img {
      width: 40%;
    }

    .mail {
      color: #034ea2;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1% 8%;
      box-shadow: 0 4px 2px -2px #4141415a;
      top: 0;
      background: #ffffff;
      z-index: 1;
      position: sticky;
    }

    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      text-align: center;
    }

    .header-one button,
    .header-one p {
      display: none;
    }

    .header-one {
      display: block;
      width: 30vw;
    }

    .header-one img {
      float: right;
      right: 0;
      display: block;
    }

    .header.responsive-one {
      background-color: #1f2934;
    }

    .credin-logo.responsive-two {
      display: none;
    }

    .header-one.responsive {
      width: 75%;
      margin-left: 25%;
      background-color: #ffffff;
      color: #000000;
      position: fixed;
      height: 100%;
    }

    .header-one.responsive img {
      position: absolute;
      margin-right: 50%;
      margin-top: 25px;
    }

    .header-one.responsive p {
      float: none;
      display: block;
      text-align: center;
      margin-top: 25px;
      font-size: 20px;
      font-weight: 400;
    }

    .header-one.responsive p {
      margin: 0;
      margin-top: 25px;
      width: 90%;
    }
  }

  /* header end */

  * {
    font-family: "Lato", sans-serif;
  }

  body {
    width: auto;
    display: grid;
    overflow-x: hidden;
  }

  .bg1 {
    background-color: #ffffff;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  /********** Nav Bar CSS **********/

  .part {
    /* color: #000000; */
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 2px -2px rgb(130, 131, 134);
    /* border-bottom: 1px solid #034ea2; */
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5% 2.5%;
    /* margin-left: 10px;
    margin-right: 10px; */
    cursor: pointer;
    z-index: 1;
  }

  /* .navbar {
    margin-right: 60px;
    color:#034ea2;
    text-decoration: none;
    /* padding: 20px; */
  /* } */

  .img {
    width: 125px;
    height: 75px;
  }

  .menu {
    float: right;
    margin: 2px;
    text-decoration: none;
    font-size: 17px;
  }

  .dropdown {
    float: left;
    overflow: hidden;
  }

  .dropdown .dropbtn {
    font-size: 17px;
    border: none;
    color: #000000;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }

  .dropdown:hover .dropbtn:hover {
    background-color: #ffffff;
    color: #034ea2;
    border-radius: 4px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: #000000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    color: #034ea2;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    color: #034ea2;
  }

  .btn {
    margin-left: 50px;
    margin-right: 10px;
    margin-top: 5px;
    /* padding: 10px 28px 10px 28px; */
    background-color: #00944a;
    color: #ffffff;
    font-size: 20px;
    border: none;
    border-radius: 3px;
    font-weight: 600;
    width: 120px;
    text-align: center;
    height: 44px;
  }

  .navbarsmap:hover {
    border-bottom: 2px solid #034ea2;
  }

  .menu:hover {
    color: #034ea2;
  }

  .navbarsmap:focus {
    border-bottom: 2px solid #034ea2;
  }

  .menu:active {
    color: #034ea2;
  }
  .menu:focus {
    background-color: #e6e6e6;
  }

  #current {
    color: #034ea2;
  }

  #current-one {
    text-decoration: underline;
  }

  /* main-portion */

  .nbfcpartner {
    background-image: url("../assets/nbfcpartner/NBFC.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 25vh;
    color: #ffffff;
    text-align: center;
    font-size: 30px;
    padding-top: 10vh;
  }

  .nbfcpartner-one {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin: 4% 10%;
    justify-content: space-evenly;
  }

  .nbfcpartner-one button {
    height: 5vh;
    width: 6vw;
    border: none;
    color: #034ea2;
    font-weight: bold;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin-top: 2vh;
    cursor: pointer;
  }

  .nbfspartner-img {
    width: 20vw;
    height: 12vh;
  }

  .nbfcpartner-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 8px;
    border: 1.5px solid gray;
    margin: 0 2%;
    padding: 1% 2%;
    font-size: 15px;
  }

  .nbfcpartner-two h1 {
    font-size: 24px;
    text-align: center;
    margin-top: 2vh;
  }

  .nbfcpartner-three {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-2000px);
    }
  }

  /********* Footer CSS **********/

  .sub p {
    margin: 0;
    margin-top: 2.5vh;
  }

  @media (min-width: 650px) {
    .footer-main {
      height: 350px;
    }

    .footer {
      font-size: 14px;
      background: url("../assets/footer/WebsiteFooter.gif");
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #000000;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom;
    }

    .row2-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 350px;
      width: 100%;
    }

    .foota {
      color: #000000;
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .sub {
      display: flex;
      flex-direction: column;
      margin: 0 25px;
      text-align: left;
      font-size: 18px;
      width: 100%;
    }

    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #fff;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      background-color: #034da2;
    }
  }

  @media (max-width: 650px) {
    .footer {
      background-color: #034ea2;
      font-size: 14px;
      background-size: contain;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #fff;
      padding-top: 3vh;
    }

    .row-footer {
      display: flex;
      flex-direction: column;
    }

    .row2-footer {
      display: flex;
      flex-direction: column;
    }

    .foota {
      color: #ffffff;
      margin-top: 1em;
    }

    .img {
      display: none;
    }

    .sub {
      font-size: 16px;
      margin-top: 1vh;
      padding-bottom: 1vh;
      border-bottom: 2px solid white;
    }
    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #034da2;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      background-color: white;
    }
  }

  .foota {
    margin-bottom: 5px;
    text-decoration: none;
  }

  /* footer end */

  .mn1 {
    align-items: center;
    justify-content: center;
    padding: 0px 6px 8px 6px;
    margin: 1px;
    color: #ffffff;
    text-decoration: none;
  }

  .copytop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 40px;
    background-color: #6c6c6d;
    border-top: 2px solid #ffffff;
  }

  @media (min-width: 600px) {
    .colunm1 {
      display: flex;
      flex-direction: column;
      margin: 10px;
      align-items: flex-start;
    }

    .navbar {
      margin-left: 115px;
      text-decoration: none;
    }

    .navnav {
      float: right;
      display: flex;
      margin-right: 140px;
    }

    .btn {
      margin-left: 80px;
      margin-right: 50px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #03931b;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }

    #in {
      margin-left: 230px;
      margin-right: 60px;
    }

    #in1 {
      margin-right: 200px;
      margin-left: 60px;
    }

    #box-item {
      /* width: 800px; */
      height: 300px;
      margin-left: 20px;
      margin-right: 20px;
    }

    .text {
      margin: 20px;
      /* margin-top: 100px; */
      text-align: center;
      color: #000000;
      font-style: bold;
    }
  }

  @media (max-width: 600px) {
    .nbfcpartner {
      background-image: url(http://localhost:8080/img/NBFC.50c1a8e5.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: auto;
      color: #ffffff;
      text-align: center;
      font-size: 24px;
      padding-top: 15%;
      padding-bottom: 15%;
    }

    .nbfspartner-img {
      width: 300px;
      height: 10%;
      margin-top: 5%;
    }

    .nbfcpartner-one button {
      height: 30px;
      width: 100px;
      border: none;
      color: #034ea2;
      font-weight: bold;
      background-color: #fff;
      box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
      margin-top: 2%;
      cursor: pointer;
      margin-bottom: 2%;
    }

    .nbfcpartner-two {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      box-shadow: rgb(0 0 0 / 15%) 0px 3px 8px;
      border: 1.5px solid gray;
      margin: 5% 5%;
      padding: 1% 2%;
      height: 80vh;
      font-size: 16px;
      margin-bottom: 1%;
    }

    .nbfcpartner-one {
      display: flex;
      flex-direction: column;
      margin: 5%;
      overflow: hidden;
      height: auto;
    }

    .container {
      max-width: 100%;
      overflow-x: hidden;
    }

    #current {
      color: #034ea2;
    }

    .header-one button,
    .header-one p {
      display: none;
    }

    .header-one {
      display: block;
      width: 30vw;
    }

    /* .credin-logo{
        margin-top:-25%;
    } */

    .header-one img {
      float: right;
      right: 0;
      display: block;
    }

    .header.responsive-one {
      background-color: #1f2934;
    }

    .credin-logo.responsive-two {
      display: none;
    }

    .header-one.responsive {
      width: 75%;
      margin-left: 25%;
      background-color: #ffffff;
      color: #000000;
      position: fixed;
      height: 100%;
      z-index: 1;
    }

    .header-one.responsive img {
      position: absolute;
      margin-right: 48%;
      margin-top: 25px;
    }

    .header-one.responsive p {
      float: none;
      display: block;
      text-align: center;
      margin-top: 25px;
      font-size: 20px;
      font-weight: 400;
    }

    .header-one.responsive p:nth-child(1) {
      margin: 0;
      margin-top: 25px;
    }

    .blackshadow {
      background-color: rgba(0, 0, 0, 0.6);
    }

    .containermain {
      max-height: 100%;
      overflow-y: hidden;
    }

    .nav {
      display: none;
    }

    .navbar {
      margin-right: 50px;
      color: #000000;
      text-decoration: none;
      /* padding: 20px; */
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .navnav {
      float: right;
      display: flex;
      margin-left: 50px;
    }

    .btn {
      margin-left: 40px;
      margin-right: 20px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #00944a;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }

    .colunm {
      display: flex;
      flex-direction: row;
    }

    .colunm1 {
      display: flex;
      flex-direction: column;
      margin: 10px;
    }

    .row {
      display: flex;
      flex-direction: column;
      /* text-align: center; */
      align-items: center;
      /* justify-content: center; */
    }

    #in,
    #in1,
    #box-item {
      margin-left: 10px;
      margin-right: 10px;
    }

    .text {
      margin: 20px 35px;
      text-align: center;
      color: #000000;
      font-style: bold;
    }
  }
</style>
<template>
  <div class="header" id="myTopnavOne">
    <img
      v-if="!viewIcon"
      src="../assets/CREDINLOGO.svg"
      class="credin-logo"
      id="myTopnavTwo"
      alt="logo.svg"
      height="70"
      width="140"
      @click="$router.push('/home')"
      style="cursor: pointer"
    />
    <div class="header-one" id="myTopnav">
      <p class="menu" @click="$router.push('/home')">
        <span class="navbarsmap">Ho</span>me
      </p>
      <div v-if="!viewIcon">
        <div class="menu">
          <div class="dropdown">
            <button class="dropbtn">Product</button>
            <div class="dropdown-content">
              <!-- <a @click="$router.push('/pratham')">Pratham</a> -->
              <a href="/aarambh">Aarambh</a>
              <!-- <a href="/shiksha">Shiksha</a> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- <p class="menu" @click="$router.push('/pratham')">Pratham</p> -->
        <p class="menu" @click="$router.push('/aarambh')">Aarambh</p>
        <!-- <p class="menu" @click="$router.push('/shiksha')">Shiksha</p> -->
      </div>
      <p class="menu" @click="$router.push('/ourjourney')">
        <span class="navbarsmap">Our</span> Journey
      </p>
      <div class="menu">
        <button style="height: 6vh; margin-left: 1vw" @click="goToWebsite">
          Download App
        </button>
      </div>
      <img
        @click="displayHeader"
        src="../assets/menu-hor.svg"
        alt="menu-bar.svg"
        style="height: 2vh"
        v-if="!viewIcon"
      />
      <img
        @click="displayHeader"
        src="../assets/Close1.svg"
        alt="menu-bar.svg"
        style="height: 4vh"
        v-else
      />
    </div>
  </div>

  <div class="gif">
    <div></div>
  </div>

  <div :class="{ blurBackground: viewIcon }">
    <div class="container">
      <div class="nbfcpartner">
        <h1>NBFC Partner</h1>
      </div>
      <div class="nbfcpartner-one">
        <div class="nbfcpartner-two">
          <img
            class="nbfspartner-img"
            src="../assets/nbfcpartner/HDB1.svg"
            alt="hdb.svg"
          />
          <h1>HDB Financial Services Limited</h1>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Web.svg" alt="web.svg" />
            <a style="text-decoration: none" href="https://www.hdbfs.com/"
              ><p style="margin-left: 1vw; cursor: pointer">
                https://www.hdbfs.com/
              </p></a
            >
          </div>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Mail.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">
              : customer.support@hdbfs.com
            </p>
          </div>
          <p style="color: #034ea2; font-weight: bold">
            Grievance Redressal Officer Details
          </p>
          <p>Mr. Harish Kumar</p>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Call.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">: 044 42984542</p>
          </div>
          <p>gro@hdbfs.com</p>
        </div>
        <div class="nbfcpartner-two">
          <img
            class="nbfspartner-img"
            src="../assets/nbfcpartner/pincab.png"
            alt="pincap.png"
          />
          <h1>Pinnacle Capital Solutions Pvt Ltd</h1>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Web.svg" alt="web.svg" />
            <a style="text-decoration: none" href="https://www.pincap.in/"
              ><p style="margin-left: 1vw; cursor: pointer">
                : https://www.pincap.in/
              </p></a
            >
          </div>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Mail.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">: support@pincap.in</p>
          </div>
          <p style="color: #034ea2; font-weight: bold">
            Grievance Redressal Officer Details
          </p>
          <p>Yogesh Jha</p>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Call.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">: +91 0813019 5773</p>
          </div>
          <p>support@pincap.in</p>
        </div>
        <div class="nbfcpartner-two">
          <img
            class="nbfspartner-img"
            src="../assets/nbfcpartner/CTLlogo.svg"
            alt="ctl.logo"
          />
          <h1>Capital Trade Links Limited</h1>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Web.svg" alt="web.svg" />
            <a style="text-decoration: none" href="http://www.capitaltrade.in/"
              ><p style="margin-left: 0.5vw; cursor: pointer">
                : http://www.capitaltrade.in/
              </p></a
            >
          </div>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Mail.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">
              : info@capitaltrade.in
            </p>
          </div>
          <p style="color: #034ea2; font-weight: bold">
            Grievance Redressal Officer Details
          </p>
          <p>Mr. Aryan Chaudhary</p>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Call.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">: +91 7042353322</p>
          </div>
          <div class="nbfcpartner-three">
            <p style="margin-left: 1vw; cursor: pointer">
              admin@capitaltrade.in
            </p>
          </div>
        </div>
      </div>
      <div class="nbfcpartner-one">
        <div class="nbfcpartner-two">
          <img
            class="nbfspartner-img"
            src="../assets/nbfcpartner/Maxemo.svg"
            alt="hdb.svg"
          />
          <h1>Maxemo Capital Services Pvt Ltd</h1>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Web.svg" alt="web.svg" />
            <a style="text-decoration: none" href="https://maxemocapital.com/"
              ><p style="margin-left: 1vw; cursor: pointer">
                https://maxemocapital.com/
              </p></a
            >
          </div>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Mail.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">
              : contact@maxemocapital.com
            </p>
          </div>
          <p style="color: #034ea2; font-weight: bold">
            Grievance Redressal Officer Details
          </p>
          <p>Mr. Pankaj Dixit</p>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Call.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">: +91 78272 18200</p>
          </div>
          <p>Nodalofficer@maxemocapital.com</p>
        </div>
        <div class="nbfcpartner-two">
          <a
            href="https://www.arvog.com/wp-content/uploads/2022/11/Credin.docx.pdf"
            ><img
              class="nbfspartner-img"
              src="../assets/nbfcpartner/Aarvog.svg"
              alt="pincap.png"
          /></a>
          <h1>Finkurve Financial Services Limited</h1>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Web.svg" alt="web.svg" />
            <a style="text-decoration: none" href="https://www.arvog.com/">
              <p style="margin-left: 1vw; cursor: pointer">
                : https://www.arvog.com/
              </p></a
            >
          </div>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Mail.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">
              :support.loans@arvog.com
            </p>
          </div>
          <p style="color: #034ea2; font-weight: bold">
            Grievance Redressal Officer Details
          </p>
          <p>Mr. Darshit Jain</p>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Call.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">: +917506339484</p>
          </div>
          <p>darshit.jain@arvog.com</p>
        </div>
        <div class="nbfcpartner-two">
          <img
            class="nbfspartner-img"
            src="../assets/nbfcpartner/Lendtree.svg"
            alt="ctl.logo"
          />
          <h1>Peerfintech Solutions Pvt Ltd</h1>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Web.svg" alt="web.svg" />
            <a style="text-decoration: none" href="https://www.lendtree.in/">
              <p style="margin-left: 0.5vw; cursor: pointer">
                : https://www.lendtree.in/
              </p></a
            >
          </div>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Mail.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">
              : support@lendtree.in
            </p>
          </div>
          <p style="color: #034ea2; font-weight: bold">
            Grievance Redressal Officer Details
          </p>
          <p>Veena Manglae</p>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Call.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">: +91 9999973029</p>
          </div>
          <div class="nbfcpartner-three">
            <p style="margin-left: 1vw; cursor: pointer">
              : http://veena.Mangla@lendtree.in/
            </p>
          </div>
        </div>
      </div>

      <div class="nbfcpartner-one">
        <!-- <div class="nbfcpartner-two">
          <img
            class="nbfspartner-img"
            src="../assets/nbfcpartner/ambit_logo.png"
            alt="hdb.svg"
          />
          <h1>Ambit Finvest Private Limited</h1>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Web.svg" alt="web.svg" />
            <a style="text-decoration: none" href="https://finvest.ambit.co/"
              ><p style="margin-left: 1vw; cursor: pointer">
                https://finvest.ambit.co/
              </p></a
            >
          </div>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Mail.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">
              : info.retail@ambit.co
            </p>
          </div>
          <p style="color: #034ea2; font-weight: bold">
            Grievance Redressal Officer Details
          </p>
          <p>Mr. Vaseem Khan</p>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Call.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">
              : +9191159 98000 / +91 22 3043 3020
            </p>
          </div>
          <p>Grievance.sme@ambit.co</p>
        </div> -->
        <div class="nbfcpartner-two">
          <img
            style="width: 13vw"
            class="nbfspartner-img"
            src="../assets/nbfcpartner/Northern_Arc-Logo.png"
            alt="pincap.png"
          />
          <h1>Northern Arc Capital Limited</h1>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Web.svg" alt="web.svg" />
            <a
              style="text-decoration: none"
              href="https://www.northernarc.com/"
            >
              <p style="margin-left: 1vw; cursor: pointer">
                : https://www.northernarc.com/
              </p></a
            >
          </div>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Mail.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">
              :customersupport@northernarc.com
            </p>
          </div>
          <p style="color: #034ea2; font-weight: bold">
            Grievance Redressal Officer Details
          </p>
          <p>Mr. Yuvaraj Sivakumar</p>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Call.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">: 1800 4198 766</p>
          </div>
          <p>gro@northernarc.com</p>
        </div>
        <div class="nbfcpartner-two">
          <img
            class="nbfspartner-img"
            src="../assets/nbfcpartner/Arthmate-final-logo.png"
            alt="ctl.logo"
          />
          <h1>Arthmate Financing India Private Limited</h1>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Web.svg" alt="web.svg" />
            <a style="text-decoration: none" href="https://www.arthmate.com/">
              <p style="margin-left: 0.5vw; cursor: pointer">
                : https://www.arthmate.com/
              </p></a
            >
          </div>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Mail.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">
              : customercare@arthmate.com
            </p>
          </div>
          <p style="color: #034ea2; font-weight: bold">
            Grievance Redressal Officer Details
          </p>
          <p>Mr. Yogesh Rohilla</p>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Call.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">: +91-7835009643</p>
          </div>
          <div class="nbfcpartner-three">
            <p style="margin-left: 1vw; cursor: pointer">
              : statutory.compliance@arthmate.com
            </p>
          </div>
        </div>
        <div class="nbfcpartner-two">
          <img
            class="nbfspartner-img"
            src="../assets/nbfcpartner/ramaiah.jpg"
            alt="ctl.logo"
          />
          <h1>Ramaiah Capital Private Limited</h1>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Web.svg" alt="web.svg" />
            <a style="text-decoration: none" href="https://www.ramaiahcapital.com/">
              <p style="margin-left: 0.5vw; cursor: pointer">
                : https://www.ramaiahcapital.com/
              </p></a
            >
          </div>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Mail.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">
              : info@ramaiahcapital.com
            </p>
          </div>
          <p style="color: #034ea2; font-weight: bold">
            Grievance Redressal Officer Details
          </p>
          <p>Sri Pradeep Vaddaraase</p>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Call.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">: +91-9663628386</p>
          </div>
          <div class="nbfcpartner-three">
            <p style="margin-left: 1vw; cursor: pointer">
              : chiefmanager@ramaiahcapital.com
            </p>
          </div>
        </div>
      </div>

      <div class="nbfcpartner-one">
        <div class="nbfcpartner-two">
          <img
            class="nbfspartner-img"
            src="../assets/nbfcpartner/fintree.jpg"
            alt="ctl.logo"
          />
          <h1>Fintree Finance Private Limited</h1>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Web.svg" alt="web.svg" />
            <a style="text-decoration: none" href="https://fintreefinance.com/">
              <p style="margin-left: 0.5vw; cursor: pointer">
                : https://fintreefinance.com/
              </p></a
            >
          </div>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Mail.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">
              : wecarefintree@gmail.com
            </p>
          </div>
          <p style="color: #034ea2; font-weight: bold">
            Grievance Redressal Officer Details
          </p>
          <p>Ms Sweta Parekh</p>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Call.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">: 18002678111</p>
          </div>
          <div class="nbfcpartner-three">
            <p style="margin-left: 1vw; cursor: pointer">
              : sweta.parekh@fintreefinance.com
            </p>
          </div>
        </div>
        <div class="nbfcpartner-two">
          <img
            class="nbfspartner-img"
            src="../assets/nbfcpartner/trimurti.png"
            alt="ctl.logo"
          />
          <h1>Trimurti Fincap Private Limited</h1>
          <p style="color: #034ea2; font-weight: bold">
            Grievance Redressal Officer Details
          </p>
          <p>Hitesh Patel</p>
          <div class="nbfcpartner-three">
            <img src="../assets/nbfcpartner/Call.svg" alt="web.svg" />
            <p style="margin-left: 1vw; cursor: pointer">: +91-7698889886</p>
          </div>
          <div class="nbfcpartner-three">
            <p style="margin-left: 1vw; cursor: pointer">
              : patelhiteshpg769@gmail.com
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-main">
    <div class="footer">
      <div style="padding: 0 10%" class="row-footer">
        <div class="row2-footer" id="row2">
          <div class="sub">
            <img
              class="img"
              src="../assets/img/Credinlogo.svg"
              alt="credin"
              @click="$router.push('/home')"
              style="cursor: pointer"
            />
            <p
              style="cursor: pointer; font-weight: bold; margin-top: 0"
              @click="$router.push('/contact-us')"
            >
              Contact Us
            </p>
            <p>+919879015554</p>
            <p><a class="foota" href="help@credin.in">help@credin.in</a></p>
            <div style="display: flex; margin-top: 3vh">
              <a
                href="https://www.facebook.com/credin25"
                class="mn1"
                style="padding: 0px 10px 8px 0"
              >
                <img src="../assets/Fb.svg"
              /></a>
              <a href="https://www.instagram.com/credinindia/" class="mn1"
                ><img src="../assets/Insta.svg"
              /></a>
              <a href="https://twitter.com/home-" class="mn1"
                ><img src="../assets/Twitter.svg"
              /></a>
              <a
                href="https://www.youtube.com/channel/UC-H2CRfZIdPDsqk_JrfPRtw"
                class="mn1"
                ><img src="../assets/YouTube.svg"
              /></a>
              <a href="https://in.linkedin.com/company/upkram" class="mn1"
                ><img src="../assets/Linkdin.svg"
              /></a>
            </div>
          </div>
          <div class="sub">
            <p style="cursor: pointer" @click="$router.push('/omnichannel')">
              Omni Channel
            </p>
            <p style="cursor: pointer" @click="$router.push('/nbfc-partners')">
              NBFC Partners
            </p>
            <p style="cursor: pointer" @click="$router.push('/faq')">FAQs</p>
            <p style="cursor: pointer" @click="$router.push('/privacy-policy')">
              Privacy policy
            </p>
            <p
              style="cursor: pointer"
              @click="$router.push('/terms-conditions')"
            >
              Terms & Conditions
            </p>
          </div>
          <div class="sub" style="border-bottom: none">
            <p style="font-weight: bold">Registered office</p>
            <p style="font-size: 14px; font-weight: bold; color: #034ea2">
              Upkram Technologies Pvt. Ltd.
            </p>
            <p>3-4,3rd Floor, Riyaplex,</p>
            <p>80 Urmi Society, BPC Road,</p>
            <p>Vadodara, 390 020</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <p>All right reserved &#169; Upkram Technologies Pvt. Ltd. @{{ new Date().getFullYear() }}</p>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        viewIcon: false,
      };
    },

    async created() {
      window.scrollTo(0, 0);
    },

    methods: {
      displayHeader() {
        this.viewIcon = !this.viewIcon;
        var x = document.getElementById("myTopnav");
        if (x.className === "header-one") {
          x.className += " responsive";
        } else {
          x.className = "header-one";
        }
        var y = document.getElementById("myTopnavOne");
        if (y.className === "header") {
          y.className += "responsive-one";
        } else {
          y.className = "header";
        }
        var z = document.getElementById("myTopnavTwo");
        if (z.className === "credin-logo") {
          z.className += "responsive-two";
        } else {
          z.className = "credin-logo";
        }
      },

      goToWhatsapp() {
        window.location.href = "https://wa.me/+919879015554";
      },

      goToWebsite() {
        window.location.href = "https://play.google.com/store/apps/details?id=com.credin.app";
      },

      goToMap() {
        window.location.href = "https://goo.gl/maps/rR6763JJZUVbi3bv8";
      },

      goToFacebook() {
        window.location.href = "https://www.facebook.com/credin25/";
      },

      goToTwitter() {
        window.location.href = "https://twitter.com/credin4";
      },

      goToInstagram() {
        window.location.href = "https://www.instagram.com/credinindia/?hl=en";
      },

      goToLinkdin() {
        window.location.href = "https://in.linkedin.com/company/upkram";
      },
    },
  };
</script>

<!-- @format -->

<style scoped>
  /* header Start*/

  .displaynone {
    display: none;
  }

  .blurBackground {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1% 6%;
    box-shadow: 0 4px 2px -2px #4141415a;
    top: 0;
    background: #ffffff;
    z-index: 1;
    position: sticky;
  }

  .header-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  #current {
    color: #034ea2;
  }

  #current-one {
    text-decoration: underline;
  }

  .header-one p {
    font-size: 17px;
    cursor: pointer;
  }

  .header-one button {
    height: 6vh;
    width: 9vw;
    border: none;
    background-color: #03931b;
    color: #ffffff;
    font-size: 13px;
    border-radius: 7px;
    cursor: pointer;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 600px) {
    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      width: 10vw;
      text-align: center;
    }

    .header-one img {
      display: none;
    }

    .applynow img {
      width: 40%;
    }

    .mail {
      color: #034ea2;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1% 8%;
      box-shadow: 0 4px 2px -2px #4141415a;
      top: 0;
      background: #ffffff;
      z-index: 1;
      position: sticky;
    }

    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      text-align: center;
    }

    .header-one button,
    .header-one p {
      display: none;
    }

    .header-one {
      display: block;
      width: 30vw;
    }

    .header-one img {
      float: right;
      right: 0;
      display: block;
    }

    .header.responsive-one {
      background-color: #1f2934;
    }

    .credin-logo.responsive-two {
      display: none;
    }

    .header-one.responsive {
      width: 75%;
      margin-left: 25%;
      background-color: #ffffff;
      color: #000000;
      position: fixed;
      height: 100%;
      z-index: 1;
    }

    .header-one.responsive img {
      position: absolute;
      margin-right: 50%;
      margin-top: 25px;
    }

    .header-one.responsive p {
      float: none;
      display: block;
      text-align: center;
      margin-top: 25px;
      font-size: 20px;
      font-weight: 400;
    }

    .header-one.responsive p {
      margin: 0;
      margin-top: 25px;
      width: 90%;
    }
  }

  /* header end */

  /********** Nav Bar CSS **********/

  .part {
    /* color: #000000; */
    position: fixed;
    top: 0;
    width: 95%;
    background-color: #ffffff;
    box-shadow: 0 4px 2px -2px rgb(130, 131, 134);
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5% 4%;
    cursor: pointer;
  }

  .img {
    width: 125px;
    height: 75px;
  }

  .dropdown {
    float: left;
    overflow: hidden;
  }

  .dropdown .dropbtn {
    font-size: 17px;
    border: none;
    color: #000000;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }

  .dropdown:hover .dropbtn:hover {
    background-color: #ffffff;
    color: #034ea2;
    border-radius: 4px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: #000000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    color: #034ea2;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    color: #034ea2;
  }

  .btn {
    margin-left: 50px;
    margin-right: 10px;
    margin-top: 5px;
    /* padding: 10px 28px 10px 28px; */
    background-color: #00944a;
    color: #ffffff;
    font-size: 20px;
    border: none;
    border-radius: 3px;
    font-weight: 600;
    width: 120px;
    text-align: center;
    height: 44px;
  }

  .navbarsmap:hover {
    border-bottom: 2px solid #034ea2;
  }

  .menu:hover {
    color: #034ea2;
  }

  .menu:focus {
    background-color: #e6e6e6;
  }

  /********* main portion ********/
  .career {
    margin: 1% 10%;
  }

  .career-btn {
    width: 120px;
    height: 35px;
    font-size: 18px;
    border-radius: 5px;
    color: #ffffff;
    background-color: #03931b;
    border: none;
  }

  .career-btn:hover {
    width: 120px;
    height: 35px;
    font-size: 18px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #03931b;
    border: 1.5px solid #03931b;
  }

  .highlight-p {
    margin: 1.2rem;
    margin-left: 5px;
    /* align-items: center; */
    display: flex;
    align-items: flex-start;
  }

  .highlight-p p {
    margin: 0;
    font-size: 12px;
  }
  .highlight-img {
    margin: 0px 20px 3px 0px;
    animation: animate 2s linear infinite;
  }

  @keyframes animate {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 0.7;
    }

    50% {
      opacity: 1;
    }

    75% {
      opacity: 0.7;
    }

    100% {
      opacity: 0;
    }
  }

  /********* Footer CSS **********/

  .sub p {
    margin: 0;
    margin-top: 2.5vh;
  }

  @media (min-width: 650px) {
    .career-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .footer-main {
      height: 350px;
    }

    .footer {
      font-size: 14px;
      background: url("../assets/footer/WebsiteFooter.gif");
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #000000;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom;
    }

    .row2-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 350px;
      width: 100%;
    }

    .foota {
      color: #000000;
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .sub {
      display: flex;
      flex-direction: column;
      margin: 0 25px;
      text-align: left;
      font-size: 18px;
      width: 100%;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #fff;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      background-color: #034da2;
    }
  }

  @media (max-width: 650px) {
    .career-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* justify-content: space-between; */
    }

    .footer {
      background-color: #034ea2;
      font-size: 14px;
      background-size: contain;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #fff;
      padding-top: 3vh;
    }

    .row-footer {
      display: flex;
      flex-direction: column;
    }

    .row2-footer {
      display: flex;
      flex-direction: column;
    }

    .foota {
      color: #ffffff;
      margin-top: 1em;
    }

    .img {
      display: none;
    }

    .sub {
      font-size: 16px;
      margin-top: 1vh;
      padding-bottom: 1vh;
      border-bottom: 2px solid white;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #034da2;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      background-color: white;
    }
  }

  .foota {
    margin-bottom: 5px;
    text-decoration: none;
  }

  /* footer end */

  .mn1 {
    align-items: center;
    justify-content: center;
    padding: 0px 6px 8px 6px;
    margin: 1px;
    color: #ffffff;
    text-decoration: none;
  }

  @media screen and (max-width: 551px) {
    .colunm {
      display: flex;
      flex-direction: row;
    }

    .colunm1 {
      display: flex;
      flex-direction: column;
      margin: 10px;
    }

    .highlight-img {
      margin: 0px 10px 3px 0px;
    }
  }

  @media screen and (min-width: 551px) {
    .highlight-img {
      margin: 0px 5px 0px 0px;
      float: top;
      padding-top: 0;
    }

    /****Header css ****/
    .header-one img {
      display: none;
    }
  }

  @media (max-width: 1200px) and (min-width: 1000px) {
    .sub {
      margin-left: 60px;
      margin-right: 60px;
      text-align: left;
      font-size: 18px;
    }
    #row2 {
      padding: 5px;
    }
  }
</style>

<template>
  <div class="header" id="myTopnavOne">
    <img
      v-if="!viewIcon"
      src="../assets/CREDINLOGO.svg"
      class="credin-logo"
      id="myTopnavTwo"
      alt="logo.svg"
      height="70"
      width="140"
      @click="$router.push('/home')"
      style="cursor: pointer"
    />
    <div class="header-one" id="myTopnav">
      <p class="menu" @click="$router.push('/home')">
        <span class="navbarsmap">Ho</span>me
      </p>
      <div v-if="!viewIcon">
        <div class="menu">
          <div class="dropdown">
            <button class="dropbtn">Product</button>
            <div class="dropdown-content">
              <!-- <a @click="$router.push('/pratham')">Pratham</a> -->
              <a href="/aarambh">Aarambh</a>
              <!-- <a href="/shiksha">Shiksha</a> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- <p class="menu" @click="$router.push('/pratham')">Pratham</p> -->
        <p class="menu" @click="$router.push('/aarambh')">Aarambh</p>
        <!-- <p class="menu" @click="$router.push('/shiksha')">Shiksha</p> -->
      </div>
      <p class="menu" @click="$router.push('/ourjourney')">
        <span class="navbarsmap">Our</span> Journey
      </p>
      <div class="menu">
        <button style="height: 6vh; margin-left: 1vw" @click="goToWebsite">
          Download App
        </button>
      </div>
      <img
        @click="displayHeader"
        src="../assets/menu-hor.svg"
        alt="menu-bar.svg"
        style="height: 2vh"
        v-if="!viewIcon"
      />
      <img
        @click="displayHeader"
        src="../assets/Close1.svg"
        alt="menu-bar.svg"
        style="height: 4vh"
        v-else
      />
    </div>
  </div>

  <div class="career">
    <div>
      <div class="career-div">
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            <span style="font-size: 16px">IT intern </span> VADODARA, GUJARAT,
            INDIA
          </p>
        </div>
        <div><button class="career-btn">Apply now</button></div>
      </div>

      <div class="career-div">
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            <span style="font-size: 16px">
              Manager – Credit (BNPL)5 months ago </span
            >MUMBAI, MAHARASHTRA, INDIA CREDIT FULL TIME
          </p>
        </div>
        <div><button class="career-btn">Apply now</button></div>
      </div>

      <div class="career-div">
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            <span style="font-size: 16px">IT intern </span> VADODARA, GUJARAT,
            INDIA
          </p>
        </div>
        <div><button class="career-btn">Apply now</button></div>
      </div>

      <div class="career-div">
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            <span style="font-size: 16px">
              Manager – Credit (BNPL)5 months ago </span
            >MUMBAI, MAHARASHTRA, INDIA CREDIT FULL TIME
          </p>
        </div>
        <div><button class="career-btn">Apply now</button></div>
      </div>

      <div class="career-div">
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            <span style="font-size: 16px">IT intern </span> VADODARA, GUJARAT,
            INDIA
          </p>
        </div>
        <div><button class="career-btn">Apply now</button></div>
      </div>

      <div class="career-div">
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            <span style="font-size: 16px">
              Manager – Credit (BNPL)5 months ago </span
            >MUMBAI, MAHARASHTRA, INDIA CREDIT FULL TIME
          </p>
        </div>
        <div><button class="career-btn">Apply now</button></div>
      </div>

      <div class="career-div">
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            <span style="font-size: 16px">IT intern </span> VADODARA, GUJARAT,
            INDIA
          </p>
        </div>
        <div><button class="career-btn">Apply now</button></div>
      </div>

      <div class="career-div">
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            <span style="font-size: 16px">
              Manager – Credit (BNPL)5 months ago </span
            >MUMBAI, MAHARASHTRA, INDIA CREDIT FULL TIME
          </p>
        </div>
        <div><button class="career-btn">Apply now</button></div>
      </div>
    </div>
  </div>
  <div class="footer-main">
    <div class="footer">
      <div style="padding: 0 10%" class="row-footer">
        <div class="row2-footer" id="row2">
          <div class="sub">
            <img
              class="img"
              src="../assets/img/Credinlogo.svg"
              alt="credin"
              @click="$router.push('/home')"
              style="cursor: pointer"
            />
            <p
              style="cursor: pointer; font-weight: bold; margin-top: 0"
              @click="$router.push('/contact-us')"
            >
              Contact Us
            </p>
            <p>+919879015554</p>
            <p><a class="foota" href="help@credin.in">help@credin.in</a></p>
            <div style="display: flex; margin-top: 3vh">
              <a
                href="https://www.facebook.com/credin25"
                class="mn1"
                style="padding: 0px 10px 8px 0"
              >
                <img src="../assets/Fb.svg"
              /></a>
              <a href="https://www.instagram.com/credinindia/" class="mn1"
                ><img src="../assets/Insta.svg"
              /></a>
              <a href="https://twitter.com/home-" class="mn1"
                ><img src="../assets/Twitter.svg"
              /></a>
              <a
                href="https://www.youtube.com/channel/UC-H2CRfZIdPDsqk_JrfPRtw"
                class="mn1"
                ><img src="../assets/YouTube.svg"
              /></a>
              <a href="https://in.linkedin.com/company/upkram" class="mn1"
                ><img src="../assets/Linkdin.svg"
              /></a>
            </div>
          </div>
          <div class="sub">
            <p style="cursor: pointer" @click="$router.push('/omnichannel')">
              Omni Channel
            </p>
            <p style="cursor: pointer" @click="$router.push('/nbfc-partners')">
              NBFC Partners
            </p>
            <p style="cursor: pointer" @click="$router.push('/faq')">FAQs</p>
            <p style="cursor: pointer" @click="$router.push('/privacy-policy')">
              Privacy policy
            </p>
            <p
              style="cursor: pointer"
              @click="$router.push('/terms-conditions')"
            >
              Terms & Conditions
            </p>
          </div>
          <div class="sub" style="border-bottom: none">
            <p style="font-weight: bold">Registered office</p>
            <p style="font-size: 14px; font-weight: bold; color: #034ea2">
              Upkram Technologies Pvt. Ltd.
            </p>
            <p>3-4,3rd Floor, Riyaplex,</p>
            <p>80 Urmi Society, BPC Road,</p>
            <p>Vadodara, 390 020</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <p>All right reserved &#169; Upkram Technologies Pvt. Ltd. @{{ new Date().getFullYear() }}</p>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        privacyPopUp: false,
        CUPI: false,
        CFSI: false,
        CDDI: false,
        CCI: false,
        CIA: false,
        camera: false,
        microphone: false,
        storage: false,
        CONI: false,
        UDYO: false,
        PCI: false,
        DTP: false,
        LTS: false,
        CPP: false,
        AIC: false,
        YPC: false,
        SP: false,
        LOS: false,
        YC: false,
        GO: false,
        FOR: false,
        //Header
        viewIcon: false,
      };
    },

    async created() {
      window.scrollTo(0, 0);
    },

    methods: {
      displayHeader() {
        this.viewIcon = !this.viewIcon;
        var x = document.getElementById("myTopnav");
        if (x.className === "header-one") {
          x.className += " responsive";
        } else {
          x.className = "header-one";
        }
        var y = document.getElementById("myTopnavOne");
        if (y.className === "header") {
          y.className += "responsive-one";
        } else {
          y.className = "header";
        }
        var z = document.getElementById("myTopnavTwo");
        if (z.className === "credin-logo") {
          z.className += "responsive-two";
        } else {
          z.className = "credin-logo";
        }
      },

      goToWhatsapp() {
        window.location.href = "https://wa.me/+919879015554";
      },

      goToWebsite() {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.credin.app";
      },

      goToMap() {
        window.location.href = "https://goo.gl/maps/rR6763JJZUVbi3bv8";
      },

      goToFacebook() {
        window.location.href = "https://www.facebook.com/credin25/";
      },

      goToTwitter() {
        window.location.href = "https://twitter.com/credin4";
      },

      goToInstagram() {
        window.location.href = "https://www.instagram.com/credinindia/?hl=en";
      },

      goToLinkdin() {
        window.location.href = "https://in.linkedin.com/company/upkram";
      },
    },
  };
</script>

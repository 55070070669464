<!-- @format -->

<style scoped>
/* header Start*/

.displaynone {
  display: none;
}

.blurBackground {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.container {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1% 6%;
  box-shadow: 0 4px 2px -2px #4141415a;
  top: 0;
  background: #ffffff;
  z-index: 1;
  position: sticky;
}

.header-one {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#current {
  color: #034ea2;
}

#current-one {
  text-decoration: underline;
}

.header-one p {
  font-size: 17px;
  cursor: pointer;
}

.header-one button {
  height: 6vh;
  width: 9vw;
  border: none;
  background-color: #03931b;
  color: #ffffff;
  font-size: 13px;
  border-radius: 7px;
  cursor: pointer;
}

.container {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.row2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.colunm {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .menu {
    float: right;
    margin: 2px;
    text-decoration: none;
    font-size: 17px;
    width: 10vw;
    text-align: center;
  }

  .header-one img {
    display: none;
  }

  .applynow img {
    width: 40%;
  }

  .mail {
    color: #034ea2;
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1% 8%;
    box-shadow: 0 4px 2px -2px #4141415a;
    top: 0;
    background: #ffffff;
    z-index: 1;
    position: sticky;
  }

  .menu {
    float: right;
    margin: 2px;
    text-decoration: none;
    font-size: 17px;
    text-align: center;
  }

  .header-one button,
  .header-one p {
    display: none;
  }

  .header-one {
    display: block;
    width: 30vw;
  }

  .header-one img {
    float: right;
    right: 0;
    display: block;
  }

  .header.responsive-one {
    background-color: #1f2934;
  }

  .credin-logo.responsive-two {
    display: none;
  }

  .header-one.responsive {
    width: 75%;
    margin-left: 25%;
    background-color: #ffffff;
    color: #000000;
    position: fixed;
    height: 100%;
    z-index: 1;
  }

  .header-one.responsive img {
    position: absolute;
    margin-right: 50%;
    margin-top: 25px;
  }

  .header-one.responsive p {
    float: none;
    display: block;
    text-align: center;
    margin-top: 25px;
    font-size: 20px;
    font-weight: 400;
  }

  .header-one.responsive p {
    margin: 0;
    margin-top: 25px;
    width: 90%;
  }
}

/* header end */

/********** Nav Bar CSS **********/

.part {
  /* color: #000000; */
  position: fixed;
  top: 0;
  width: 95%;
  background-color: #ffffff;
  box-shadow: 0 4px 2px -2px rgb(130, 131, 134);
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5% 4%;
  cursor: pointer;
}

.img {
  width: 125px;
  height: 75px;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  color: #000000;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown:hover .dropbtn:hover {
  background-color: #ffffff;
  color: #034ea2;
  border-radius: 4px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: #000000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: #034ea2;
}

.dropdown:hover .dropdown-content {
  display: block;
  color: #034ea2;
}

.btn {
  margin-left: 50px;
  margin-right: 10px;
  margin-top: 5px;
  /* padding: 10px 28px 10px 28px; */
  background-color: #00944a;
  color: #ffffff;
  font-size: 20px;
  border: none;
  border-radius: 3px;
  font-weight: 600;
  width: 120px;
  text-align: center;
  height: 44px;
}

.navbarsmap:hover {
  border-bottom: 2px solid #034ea2;
}

.menu:hover {
  color: #034ea2;
}

.menu:focus {
  background-color: #e6e6e6;
}

/********* main portion ********/

.privacy-contant {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #ebf4ff 0%, #5991cf 100%);
  padding-top: 2%;
  padding-bottom: 2%;
}

.privacy-contant-main {
  margin: 1.5% 6%;
  padding: 1% 4%;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 5%) 0px 5px 15px;
}

.privacy-contant-one {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  cursor: pointer;
}

.privacy-contant-two {
  font-size: 16px;
  color: gray;
  text-align: justify;
  margin-top: 4vh;
}

.highlight-p {
  margin: 1.2rem;
  margin-left: 5px;
  /* align-items: center; */
  display: flex;
  align-items: flex-start;
}

.highlight-p p {
  margin: 0;
  font-size: 17px;
}

/********* Footer CSS **********/

.sub p {
  margin: 0;
  margin-top: 2.5vh;
}

@media (min-width: 650px) {
  .footer-main {
    height: 350px;
  }

  .footer {
    font-size: 14px;
    background: url("../assets/footer/WebsiteFooter.gif");
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    color: #000000;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
  }

  .row2-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 350px;
    width: 100%;
  }

  .foota {
    color: #000000;
  }

  .img {
    width: 100px;
    height: 75px;
    margin-right: 50px;
  }

  .sub {
    display: flex;
    flex-direction: column;
    margin: 0 25px;
    text-align: left;
    font-size: 18px;
    width: 100%;
  }

  .subh {
    text-align: left;
    font-size: 18px;
  }

  .copyright {
    display: flex;
    height: 5vh;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background-color: #034da2;
  }
}

@media (max-width: 650px) {
  .footer {
    background-color: #034ea2;
    font-size: 14px;
    background-size: contain;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    color: #fff;
    padding-top: 3vh;
  }

  .row-footer {
    display: flex;
    flex-direction: column;
  }

  .row2-footer {
    display: flex;
    flex-direction: column;
  }

  .foota {
    color: #ffffff;
    margin-top: 1em;
  }

  .img {
    display: none;
  }

  .sub {
    font-size: 16px;
    margin-top: 1vh;
    padding-bottom: 1vh;
    border-bottom: 2px solid white;
  }

  .subh {
    text-align: left;
    font-size: 18px;
  }

  .copyright {
    display: flex;
    height: 5vh;
    color: #034da2;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background-color: white;
  }
}

.foota {
  margin-bottom: 5px;
  text-decoration: none;
}

/* footer end */

.mn1 {
  align-items: center;
  justify-content: center;
  padding: 0px 6px 8px 6px;
  margin: 1px;
  color: #ffffff;
  text-decoration: none;
}

.copytop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  background-color: #6c6c6d;
  border-top: 2px solid #ffffff;
}

@media only screen and (max-device-width: 600px) {
  .privacy-header {
    font-size: 17px;
    font-weight: 500;
  }

  .privacy-contant-main {
    margin: 2% 8%;
    padding: 1% 4%;
    box-shadow: rgb(0 0 0 / 35%) 0px 2px 5px;
  }
}

@media screen and (max-width: 551px) {
  .nav {
    display: none;
  }

  .colunm {
    display: flex;
    flex-direction: row;
  }

  .colunm1 {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }

  .blackshadow {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .containermain {
    position: fixed;
    max-height: 100%;
    overflow-y: hidden;
  }

  .highlight-img {
    margin: 0px 10px 3px 0px;
  }
}

@media screen and (min-width: 551px) {
  .highlight-img {
    margin: 0px 5px 0px 0px;
    float: top;
    padding-top: 0;
  }

  /********Navigation CSS ********/

  .colunm1 {
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: flex-start;
  }

  .navbar {
    margin-left: 55px;
    color: pink;
    text-decoration: none;
    /* padding: 20px; */
  }

  /* .img {
      width: 10px;
      height: 75px;
      margin-right: 60px;
    } */

  .navnav {
    float: right;
    display: flex;
    margin-right: 135px;
  }

  .btn {
    margin-left: 50px;
    margin-right: 10px;
    margin-top: 5px;
    padding: 10px 25px 10px 25px;
    background-color: #03931b;
    color: #ffffff;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    font-weight: 600;
  }

  /****Header css ****/
  .header-one img {
    display: none;
  }

  .applynow img {
    width: 40%;
  }

  .mail {
    color: #034ea2;
    font-size: 16px;
  }
}

@media (max-width: 1000px) and (min-width: 650px) {
  .img2 {
    margin: auto;
    width: 690px;
  }

  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .item {
    background: #ffffff;
    text-align: center;
    margin-bottom: 30px;
    height: auto;
    padding: 25px 5px;
    border-radius: 4px;
  }
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .sub {
    margin-left: 60px;
    margin-right: 60px;
    text-align: left;
    font-size: 18px;
  }

  #row2 {
    padding: 5px;
  }
}

.submit-btn {
  background-color: #034ea2;
  /* Green */
  border: none;
  color: white;
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-left: 2vw;
  cursor: pointer;
}
</style>

<template>
  <div class="header" id="myTopnavOne">
    <img v-if="!viewIcon" src="../assets/CREDINLOGO.svg" class="credin-logo" id="myTopnavTwo" alt="logo.svg" height="70"
      width="140" @click="$router.push('/home')" style="cursor: pointer" />
    <div class="header-one" id="myTopnav">
      <p class="menu" @click="$router.push('/home')">
        <span class="navbarsmap">Ho</span>me
      </p>
      <div v-if="!viewIcon">
        <div class="menu">
          <div class="dropdown">
            <button class="dropbtn">Product</button>
            <div class="dropdown-content">
              <!-- <a @click="$router.push('/pratham')">Pratham</a> -->
              <a href="/aarambh">Aarambh</a>
              <!-- <a href="/shiksha">Shiksha</a> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- <p class="menu" @click="$router.push('/pratham')">Pratham</p> -->
        <p class="menu" @click="$router.push('/aarambh')">Aarambh</p>
        <!-- <p class="menu" @click="$router.push('/shiksha')">Shiksha</p> -->
      </div>
      <p class="menu" @click="$router.push('/ourjourney')">
        <span class="navbarsmap">Our</span> Journey
      </p>
      <div class="menu">
        <button style="height: 6vh; margin-left: 1vw" @click="goToWebsite">
          Download App
        </button>
      </div>
      <img @click="displayHeader" src="../assets/menu-hor.svg" alt="menu-bar.svg" style="height: 2vh" v-if="!viewIcon" />
      <img @click="displayHeader" src="../assets/Close1.svg" alt="menu-bar.svg" style="height: 4vh" v-else />
    </div>
  </div>

  <div :class="{ blurBackground: viewIcon }">
    <div class="privacy-contant">
      <div class="privacy-contant-main" @click="showPolicy">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Privacy Policy</h1>
          <img v-if="!privacyPopUp" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="privacyPopUp">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              This privacy policy (privacy policy) sets forth the modes of
              collection, use and disclosure of your information gathered on the
              platform. This privacy policy applies only to personal information
              collected on the platform. This privacy policy does not apply to
              information collected by the company in other ways, including
              information collected offline. Please read this privacy policy
              carefully. By continuing to use the services, or access the
              platform you agree to this privacy policy. If you do not agree to
              this privacy policy, you may not avail the services.
            </p>
          </div>

          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              Access to the Platform is subject to the Terms and Conditions
              (Terms) accessible on the Platform, capitalized terms used and not
              defined in this Privacy Policy, shall have the same meaning
              ascribed to them in the Terms.
            </p>
          </div>
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              By visiting the website, www.credin.in (“Website”) or the Credin
              mobile and/or web application (the “App”) (collectively, the
              “Platform”) and availing the services provided by us (“Services”)
              you agree to be bound by the terms and conditions of this Privacy
              Policy.
            </p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              If you do not agree to this Policy or any part thereof, please do
              not use/ access/ download or install the Platform or any part
              thereof.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCUPI">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Introduction</h1>
          <img v-if="!CUPI" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="CUPI">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We, Upkram Technologies Pvt Ltd (Company; We; us; our) have
              developed a mobile application called Credin (Application/ App)
              and Web Application through which you may apply for a Loan to be
              granted by various non-banking financial company OR Banks
              (Lender), based on the loan agreement executed between you and
              LENDER. LENDER has appointed and authorized us to collect, store,
              authenticate, verify and distribute the Personal Information
              (defined below) as may be required by LENDER to sanction the Loan.
              The Personal Information shall be collected through the mobile
              application form (Mobile Application Form) available on the Mobile
              Application and Website login.
            </p>
          </div>

          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We are committed to protect your privacy and have made this
              Privacy Policy to describe the procedures we adhere to for
              collecting, using, and disclosing the Personal Information. We
              recommend you to read this Privacy Policy carefully so that you
              understand our approach towards the use of your Personal
              Information.
            </p>
          </div>

          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We are committed to protect your privacy and have made this
              Privacy Policy to describe the procedures we adhere to for
              collecting, using, and disclosing the Personal Information. We
              recommend you to read this Privacy Policy carefully so that you
              understand our approach towards the use of your Personal
              Information.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCFSI">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Personal information collected</h1>
          <img v-if="!CFSI" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="CFSI">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              In order to avail the Services, you are required to register with
              the Company by downloading the Credin app from Google Play Store
              or Websit login (User Account). During the application process,
              you shall be required to share/upload certain personal
              information, your name, e-mail address, gender, date of birth,
              mobile number, passwords, photograph, mobile phone information,
              financial information such as bank documents, salary slips, bank
              statements, PAN card, bank account no., data from Credit
              Information Companies, data required for Know Your Customer
              compliances, requirement and other relevant details (Personal
              Information). You undertake that you shall be solely responsible
              for the accuracy and truthfulness of the Personal Information you
              share with us.
            </p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              You understand and acknowledge that the Company reserves the right
              to track your location (Track) during the provision of the
              Services, and also in the event that you stop, cease, discontinue
              to use or avail the Services, through the deletion or
              uninstallation of the Mobile App or otherwise, till the event that
              your obligations to pay the Outstanding Amount(s) to LENDER,
              exist. Deletion, uninstallation, and/or discontinuation of our
              Services, shall not release you from the responsibility,
              obligation and liability to repay the Outstanding Amount(s).
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCDDI">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Governing statute</h1>
          <img v-if="!CDDI" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="CDDI">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              This Privacy Policy is governed by and is compliant with the
              Information Technology (Reasonable Security Practices and
              Procedures and Sensitive Personal Data or Information)Rules 2011,
              which is designed to protect Personal Information of the
              End-User(s) of the Services; and other applicable rules and
              regulations related to privacy.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCCI">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Cookies</h1>
          <img v-if="!CCI" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="CCI">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We may set cookies to track your use of the Platform. Cookies are
              small encrypted files, that a site or its service provider
              transfers to your device’s hard drive that enables the sites or
              service provider’s systems to recognize your device and capture
              and remember certain information. By using the Application, you
              signify your consent to our use of cookies.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCIA">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Disclosures</h1>
          <img v-if="!CIA" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="CIA">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We do not sell, rent, lease your Personal Information to anybody
              and will never do so. Keeping this in mind, we may disclose your
              Personal Information in the following cases:
            </p>
          </div>
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              <b>Administrators:</b> We shall provide access to your Personal
              information to our authorized administrative(s) for internal
              business purposes, who shall be under confidentiality obligations
              towards the same.
            </p>
          </div>

          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              <b>Affiliates:</b> We may provide Personal Information we collect
              to our affiliates. For example, we may disclose Personal
              Information to our affiliates in order to respond to your requests
              for information or the Services, or to help limit your receipt of
              marketing materials you have requested not to receive.
            </p>
          </div>

          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              <b>Business Partners:</b> We may use certain trusted third party
              companies and individuals to help us provide, analyse, and improve
              the Services including but not limited to data storage,
              maintenance services, database management, credit bureaus, rating
              agencies, web analytics, payment processing, and improvement of
              the Platform’s features. These third parties may have access to
              your information only for purposes of performing these tasks on
              our behalf and under obligations similar to those in this Privacy
              Policy. We may disclose your Personal Information to partners who
              perform business functions or hosting services on our behalf and
              who may be located outside India.
            </p>
          </div>

          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              <b>Service Providers:</b> We may share your Personal Information
              with the service providers, including LENDER, who are working with
              us in connection with the operation of the Services or the
              Platform, so long as such service providers are subject to
              confidentiality restrictions consistent with this Privacy Policy.
            </p>
          </div>
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              <b>Joint Marketing Arrangements:</b> Where permitted by law, we
              may share your Personal Information with joint marketers with whom
              we have a marketing arrangement, we would require all such joint
              marketers to have written contracts with us that specify the
              appropriate use of your Personal Information, require them to
              safeguard your Personal Information, and prohibit them from making
              unauthorized or unlawful use of your Personal Information
            </p>
          </div>

          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              <b>Persons Who Acquire Our Assets or Business:</b> If we sell or
              transfer any of our business or assets, certain Personal
              Information may be a part of that sale or transfer. In the event
              of such a sale or transfer, we will notify you.
            </p>
          </div>
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              <b>Legal and Regulatory Authorities:</b> We may be required to
              disclose your Personal Information due to legal or regulatory
              requirements. In such instances, we reserve the right to disclose
              your Personal Information as required in order to comply with our
              legal obligations, including but not limited to complying with
              court orders, warrants, or discovery requests. We may also
              disclose your Personal Information(a) to law enforcement officers
              or others; (b) to Credit Information Companies; (c) to comply with
              a judicial proceeding, court order, or legal process served on us
              or the Platform; (d) to enforce or apply this Privacy Policy or
              the Terms of Service or our other policies or Agreements; (e) for
              an insolvency proceeding involving all or part of the business or
              asset to which the information pertains; (f) respond to claims
              that any Personal Information violates the rights of
              third-parties; (g) or protect the rights, property, or personal
              safety of the Company, or the general public. You agree and
              acknowledge that we may not inform you prior to or after
              disclosures made according to this section.
            </p>
          </div>

          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              Notwithstanding anything mentioned hereinabove, the Company shall
              not be responsible for the actions or omissions of the service
              providers or parties with whom the Personal Information is shared,
              nor shall the Company be responsible and/or liable for any
              additional information you may choose to provide directly to any
              service provider or any third party.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCamera">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Data retention</h1>
          <img v-if="!camera" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="camera">
          <!-- <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We require camera access to scan and capture the required KYC
              documents thereby allowing us to auto-fill relevant fields.
            </p>
          </div> -->
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We will retain your Personal Information for as long as your registration with us is valid and the
              Outstanding Amount(s) is due and payable and/or is required to carry out our operations. We may also retain
              and use your Personal Information as necessary to comply with our legal obligations, resolve disputes, and
              enforce our agreements.
            </p>
          </div>
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              You may choose to delete and/or change/ withdraw your consent for use of your data at any point. You may do
              so by visiting User Account & Data Deletion on Privacy Policy Page. Upon receiving such request we will
              delete your Personal Information subject to sucessful submission of your request. Please note, however, that
              there might be latency in deleting Personal Information from our servers and backed-up versions might exist
              even after deletion. Also upon withdrawal of such request you might not be further eligible for any services
              and it shall be at sole discretion of the Company.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCameraOne">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Camera Access and Image Uploads</h1>
          <img v-if="!cameraOne" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="cameraOne">
          <!-- <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We require camera access to scan and capture the required KYC
              documents thereby allowing us to auto-fill relevant fields.
            </p>
          </div> -->
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              Our mobile application requires access to your device's camera to
              facilitate the Know Your Customer (KYC) process for personal loan
              applications. During the KYC process, you will be prompted to
              capture images of specific identification documents, such as your
              government-issued ID card and pan card, or driver's licence. These
              images will be securely uploaded to our servers for verification
              purposes.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showKyc">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">KYC Document Handling</h1>
          <img v-if="!kyc" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="kyc">
          <!-- <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We require camera access to scan and capture the required KYC
              documents thereby allowing us to auto-fill relevant fields.
            </p>
          </div> -->
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              The images of your identification documents obtained during the
              KYC process will only be used for verification and validation
              purposes. We will store these images securely and strictly in
              accordance with industry best practices and applicable data
              protection regulations.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showMicrophone">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Data Security and Confidentiality</h1>
          <img v-if="!microphone" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="microphone">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We value your Personal Information, and protect it on the Platform
              against loss, misuse or alteration by taking extensive security
              measures. In order to protect your Personal Information, we have
              implemented adequate technology and will update these measures as
              new technology becomes available, as appropriate. All Personal
              Information is securely stored on a secure cloud setup and all
              communication happens via bank-grade secure SSL communication
              channels. The Personal Information is stored on Amazon AWS and
              Google cloud. Although we provide appropriate firewalls and
              protections, we cannot warrant the security of any Personal
              Information transmitted as our systems are not hack proof. Data
              pilferage due to unauthorized hacking, virus attacks, technical
              issues is possible and we take no liabilities or responsibilities
              for it.
            </p>
          </div>

          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              You are responsible for all actions that take place under your
              User Account. If you choose to share your User Account details and
              password or any Personal Information with third parties, you are
              solely responsible for the same. If you lose control of your User
              Account, you may lose substantial control over your Personal
              Information and may be subject to legally binding actions.
            </p>
          </div>
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We prioritise the security and confidentiality of your personal
              information. All data, including the images captured during the
              KYC process, will be encrypted and stored on secure servers.
              Access to this data will be restricted to authorised personnel
              solely for verification and loan processing purposes.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showTps">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Third-Party Service Providers</h1>
          <img v-if="!tps" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="tps">
          <!-- <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We require camera access to scan and capture the required KYC
              documents thereby allowing us to auto-fill relevant fields.
            </p>
          </div> -->
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              To facilitate the KYC process and identity verification, we may
              engage with third-party service providers who adhere to strict
              data protection standards. These providers will have access to the
              images you upload, but they are strictly prohibited from using the
              data for any other purpose beyond the verification process.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showStorage">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">
            Accessing and modifying personal information
          </h1>
          <img v-if="!storage" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="storage">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              In case you need to access, review, and/or make changes to the
              Personal Information, you shall have to login to your User Account
              and change the requisite details. You shall keep your Personal
              Information updated to help us better serve you.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCONI">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Ancilliary services</h1>
          <img v-if="!CONI" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="CONI">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We may provide you with certain ancillary services such as chat
              rooms, blogs and reviews for the Services. Subject to any
              applicable laws, any communication shared by you via the Platform
              or through the blogs, reviews or otherwise to us (including
              without limitation contents, images, audio, financial information,
              feedback etc. collectively Feedback) is on a non-confidential
              basis, and we are under no obligation to refrain from reproducing,
              publishing or otherwise using it in any way or for any purpose.
              You shall be responsible for the content and information contained
              in any Feedback shared by you through the Platform or otherwise to
              us, including without limitation for its truthfulness and
              accuracy. Sharing your Feedback with us, constitutes an assignment
              to us of all worldwide rights, titles and interests in all
              copyrights and other intellectual property rights in the Feedback
              and you authorize us to use the Feedback for any purpose, which we
              may deem fit.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showUDYO">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Communications from the platform</h1>
          <img v-if="!UDYO" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="UDYO">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              <b>Special Offers and Updates:</b> We may send you information on
              products, services, special deals, and a newsletter of the
              Company. Out of respect for your privacy, we present you with the
              option of not receiving these types of communications. You may
              unsubscribe via the unsubscribe mechanism provided in each such
              communication or by emailing us at help@credin.in
            </p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              <b>Service Announcements: </b>On certain occasions or under law,
              we are required to send out Service or Platform related
              announcements. We respect your privacy, however you may not
              opt-out of these communications. These communications would not be
              promotional in nature.
            </p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              <b>Customer Service:</b> We communicate with Customer(s) on a
              regular basis to provide requested services and in regards to
              issues relating to their User Account, we reply via email or
              phone, based on Customer(s) requirements and convenience
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showPCI">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Indemnification</h1>
          <img v-if="!PCI" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="PCI">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              You agree to indemnify us, our subsidiaries, affiliates, officers,
              agents, co-branders or other partners, and employees and Directors
              and hold us harmless from and against any claims and demand,
              including reasonable attorneys fees, made by any third party
              arising out of or relating to: (i) Personal Information and
              contents that you submit or share through the Platform; (ii) your
              violation of this Privacy Policy, (iii) or your violation of
              rights of another Customer(s).
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showDTP">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Limitations of liability</h1>
          <img v-if="!DTP" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="DTP">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              You expressly understand and agree that the Company shall not be
              liable for any direct, indirect, incidental, special,
              consequential or exemplary damages, including but not limited to,
              damages for loss of profits, goodwill, use, data, information,
              details or other intangible losses (even if the Company has been
              advised of the possibility of such damages), resulting from: (i)
              the use or the inability to use the Services; (ii) unauthorized
              access to or alteration of your Personal Information.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showLTS">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Governing laws and duties</h1>
          <img v-if="!LTS" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="LTS">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              You expressly understand and agree that the Company, including its
              directors, officers, employees, representatives or the service
              provider, shall not be liable for any direct, indirect,
              incidental, special, consequential or exemplary damages, including
              but not limited to, damages for loss of profits, goodwill, use,
              data or other intangible losses (even if the Company has been
              advised of the possibility of such damages), resulting from; (a)
              use or the inability to avail the Services (b) inability to use
              the Platform (c) failure or delay in providing the Services or
              access to the Platform (d) any performance or non-performance by
              the Company (e) any damages to or viruses that may infect your
              electronic devices or other property as the result of your access
              to the Platform or your downloading of any content from the
              Platform and (f) server failure or otherwise or in any way
              relating to the Services.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showFOR">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Force majeure</h1>
          <img v-if="!FOR" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="FOR">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              This Agreement shall be construed and governed by the laws of
              India and courts of law at Vadodara, Gujarat shall have exclusive
              jurisdiction over such disputes without regard to principles of
              conflict of laws.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCPP">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Changes to this policy</h1>
          <img v-if="!CPP" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="CPP">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              We may update this Privacy Policy without notice to you. You are
              encouraged to check this Privacy Policy on a regular basis to be
              aware of the changes made to it. Continued use of the Services and
              access to the Platform shall be deemed to be your acceptance of
              this Privacy Policy.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showAIC">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Your consent</h1>
          <img v-if="!AIC" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="AIC">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              By using the Platform and by providing your information, you
              consent to the collection, sharing, disclosure and usage of the
              information that you disclose on the Platform in accordance with
              this Privacy Policy.
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="privacy-contant-main" @click="showYPC">
      <div class="privacy-contant-one">
        <h1 class="privacy-header">YOUR PRIVACY CONTROLS</h1>
        <img
          v-if="!YPC"
          src="../assets/homepage/Navigationicon.svg"
          alt="navigation.io"
        />
        <img
          v-else
          src="../assets/homepage/Upperarrow.svg"
          alt="navigation.io"
        />
      </div>
      <div class="privacy-contant-two" v-if="YPC">
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            You have certain choices regarding the information we collect and
            how it is used:
          </p>
        </div>

        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            a. Device-level settings: Your device may have controls that
            determine what information we collect. For example, you can modify
            permissions on your Android device for access to Camera or Audio
            permissions.<br />b. Delete your entire App account.<br />c. You can
            also request to remove content from our servers based on applicable
            law or by writing to our Grievance Officer.
          </p>
        </div>
      </div>
    </div>
    <div class="privacy-contant-main" @click="showSP">
      <div class="privacy-contant-one">
        <h1 class="privacy-header">SECURITY PRECAUTIONS</h1>
        <img
          v-if="!SP"
          src="../assets/homepage/Navigationicon.svg"
          alt="navigation.io"
        />
        <img
          v-else
          src="../assets/homepage/Upperarrow.svg"
          alt="navigation.io"
        />
      </div>
      <div class="privacy-contant-two" v-if="SP">
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            The Platform intends to protect your information and to maintain its
            accuracy as confirmed by you. We implement reasonable physical,
            administrative and technical safeguards to help us protect your
            information from unauthorized access, use and disclosure. For
            example, we encrypt all information when we transmit over the
            internet. We also require that our registered third-party service
            providers protect such information from unauthorized access, use and
            disclosure.
          </p>
        </div>
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            Our Platform has stringent security measures in place to protect the
            loss, misuse and alteration of information under control. We
            endeavor to safeguard and ensure the security of the information
            provided by you. We use Secure Sockets Layers (SSL) based
            encryption, for the transmission of the information, which is
            currently the required level of encryption in India as per
            applicable law.
          </p>
        </div>
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            We blend security at multiple steps within our products with the
            state-of-the-art technology to ensure our systems maintain strong
            security measures and the overall data and privacy security design
            allow us to defend our systems ranging from low hanging issue up to
            sophisticated attacks.We aim to protect from unauthorized access,
            alteration, disclosure or destruction of information we hold,
            including:
          </p>
        </div>
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            a. We use encryption to keep your data private while in transit;<br />b.
            We offer security feature like an OTP verification to help you
            protect your account;<br />c. We review our information collection,
            storage, and processing practices, including physical security
            measures, to prevent unauthorized access to our systems;<br />d. We
            restrict access to personal information to our employees,
            contractors, and agents who need that information in order to
            process it. Anyone with this access is subject to strict contractual
            confidentiality obligations and may be disciplined or terminated if
            they fail to meet these obligations;<br />e. Compliance &amp;
            Cooperation with Regulations and applicable laws;<br />f. We
            regularly review this Privacy Policy and make sure that we process
            your information in ways that comply with it.<br />g. Data
            transfers;<br />h. We ensure that Aadhaar number is not disclosed in
            any manner.
          </p>
        </div>
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            We or our affiliates maintain your information on servers located in
            India. Data protection laws vary among countries, with some
            providing more protection than others. We also comply with certain
            legal frameworks relating to the transfer of data as mentioned and
            required under the Information Technology Act, 2000 and rules made
            thereunder
          </p>
        </div>
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            When we receive formal written complaints, we respond by contacting
            the person who made the complaint. We work with the appropriate
            regulatory authorities, including local data protection authorities,
            to resolve any complaints regarding the transfer of your data that
            we cannot resolve with you directly.
          </p>
        </div>
      </div>
    </div>
    <div class="privacy-contant-main" @click="showLOS">
      <div class="privacy-contant-one">
        <h1 class="privacy-header">LINKS TO OTHER SITES</h1>
        <img
          v-if="!LOS"
          src="../assets/homepage/Navigationicon.svg"
          alt="navigation.io"
        />
        <img
          v-else
          src="../assets/homepage/Upperarrow.svg"
          alt="navigation.io"
        />
      </div>
      <div class="privacy-contant-two" v-if="LOS">
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            Our Platform links to other websites that may collect information
            about you. We are not responsible for the privacy practices or the
            content of those linked websites. With this Policy we’re only
            addressing the disclosure and use of data collected by Us. If You
            visit any websites through the links on the Website, please ensure
            You go through the privacy policies of each of those websites. Their
            data collection practices, and their policies might be different
            from this Policy and We do not have control over any of their
            policies neither do we have any liability in this regard.
          </p>
        </div>
      </div>
    </div>
    <div class="privacy-contant-main" @click="showYC">
      <div class="privacy-contant-one">
        <h1 class="privacy-header">YOUR CONSENT</h1>
        <img
          v-if="!YC"
          src="../assets/homepage/Navigationicon.svg"
          alt="navigation.io"
        />
        <img
          v-else
          src="../assets/homepage/Upperarrow.svg"
          alt="navigation.io"
        />
      </div>
      <div class="privacy-contant-two" v-if="YC">
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            By using the Platform and by providing your information, you consent
            to the collection, sharing, disclosure and usage of the information
            that you disclose on the Platform in accordance with this Privacy
            Policy.
          </p>
        </div>
        <div class="highlight-p">
          <img class="highlight-img" src="../assets/img/Check.svg" />
          <p>
            If we decide to change our Privacy Policy, we will post those
            changes on this page so to make you aware of the information we
            collect, how we use it, and under what circumstances we share and
            disclose it.
          </p>
        </div>
      </div>
    </div> -->

      <div class="privacy-contant-main" @click="showGO">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Grievance officer</h1>
          <img v-if="!GO" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" />
        </div>
        <div class="privacy-contant-two" v-if="GO">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              If you have questions, concerns or grievances regarding this
              Privacy Policy, you can contact below
            </p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>Name: Mr. Bhadresh Mehta</p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>Phone: 0265- 4003444</p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>Email: bkmehta@credin.in</p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>Time: Mon - Sat (10:00 - 18:00)</p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main">
        <div class="privacy-contant-one">
          <h1 class="privacy-header" @click="showDP">User Account & Data Deletion</h1>
          <img v-if="!DP" src="../assets/homepage/Navigationicon.svg" alt="navigation.io" @click="showDP" />
          <img v-else src="../assets/homepage/Upperarrow.svg" alt="navigation.io" @click="showDP" />
        </div>
        <div class="privacy-contant-two" v-if="DP">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <div style="display: flex; flex-direction: column;">
              <p>Steps to Delete Account and Associated Data</p>
              <ol>
                <li>Go at the bottom of olrivacy policy page and click on User Account & Data Deletion</li>
                <li>Choose the option from Account and Associated Data Deletion OR Only Data Deletion</li>
                <li>Enter the required details like Name, Mobile, Email & Customer/Borrower ID</li>
                <li>Submit the request by clicking the SUBMIT Button.</li>
                <li>After Submission, customer will receive acknowledgement email from Credin Team within 24 hrs and Team
                  will update the request status within 7 days.</li>
              </ol>
            </div>
          </div>
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <div style="display: flex; flex-direction: column;">
              <p>Account Deletion</p>
              <ol>
                <li>Account will only be deleted if customer has never availed any loan through Credin Platform.</li>
                <li>If Customer has availed loan through Credin Platform then, account deletion will not happen till
                  the loan is active in the books of the partner lender.Account deletion may get extended subject to
                  confirmation from the lending partner as per the regulatory requirements.</li>
              </ol>
            </div>
          </div>
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <div style="display: flex; flex-direction: column;">
              <p>Data Deletion</p>
              <ol>
                <li>Data will be deleted if customer has never availed any loan through Credin Platform. In such case, all
                  the customer’s personal identifiers like PAN, masked Aadhar Number, Address, any other KYC details and
                  documents will be deleted and you will no more be able to access any of the services.</li>
                <li>If customer has any active loan relationship with any of the partner lender, data will will not be
                  deleted till the loan is closed because of regulatory requirements of the partner lender.</li>
                <li>If customer has closed the loan relationship with the partner lender (availed through Credin
                  platform), customer’s personal identifiers will be delete. Please note, however, that there might be
                  latency in deleting Personal Information from our servers and backed-up versions might exist even after
                  deletion. Also upon withdrawal of such request you might not be further eligible for any services and it
                  shall be at sole discretion of the Company.</li>
              </ol>
            </div>
          </div>
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <select name="data-delete" id="data-delete" v-model="requestType">
              <option value="">--Select--</option>
              <option value="Account & Associate Data Deletion">Account & Associate Data Deletion</option>
              <option value="Only Data Deletion">Only Data Deletion</option>
            </select>
            <p style="color: red;">*</p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <input type="text" name="Name" id="name" placeholder="Enter Name" v-model="borrowerName">
            <p style="color: red;">*</p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <input type="text" name="Mobile" id="mobile" placeholder="Enter Phone Number" v-model="phoneNumber">
            <p style="color: red;">*</p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <input type="text" name="Email" id="email" placeholder="Enter Email Id" v-model="emailId">
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <input type="text" name="borrowerId" id="borrowerId" placeholder="Enter Borrower Id" v-model="borrowerId">
          </div>
          <div class="highlight-p">
            <!-- <img class="highlight-img" src="../assets/img/Check.svg" /> -->
            <button type="button" class="submit-btn" @click="submitButton">Submit</button>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-main">
      <div class="footer">
        <div style="padding: 0 10%" class="row-footer">
          <div class="row2-footer" id="row2">
            <div class="sub">
              <img class="img" src="../assets/img/Credinlogo.svg" alt="credin" @click="$router.push('/home')"
                style="cursor: pointer" />
              <p style="cursor: pointer; font-weight: bold; margin-top: 0" @click="$router.push('/contact-us')">
                Contact Us
              </p>
              <p>+919879015554</p>
              <p><a class="foota" href="help@credin.in">help@credin.in</a></p>
              <div style="display: flex; margin-top: 3vh">
                <a href="https://www.facebook.com/credin25" class="mn1" style="padding: 0px 10px 8px 0">
                  <img src="../assets/Fb.svg" /></a>
                <a href="https://www.instagram.com/credinindia/" class="mn1"><img src="../assets/Insta.svg" /></a>
                <a href="https://twitter.com/home-" class="mn1"><img src="../assets/Twitter.svg" /></a>
                <a href="https://www.youtube.com/channel/UC-H2CRfZIdPDsqk_JrfPRtw" class="mn1"><img
                    src="../assets/YouTube.svg" /></a>
                <a href="https://in.linkedin.com/company/upkram" class="mn1"><img src="../assets/Linkdin.svg" /></a>
              </div>
            </div>
            <div class="sub">
              <p style="cursor: pointer" @click="$router.push('/omnichannel')">
                Omni Channel
              </p>
              <p style="cursor: pointer" @click="$router.push('/nbfc-partners')">
                NBFC Partners
              </p>
              <p style="cursor: pointer" @click="$router.push('/faq')">FAQs</p>
              <p style="cursor: pointer" @click="$router.push('/privacy-policy')">
                Privacy policy
              </p>
              <p style="cursor: pointer" @click="$router.push('/terms-conditions')">
                Terms & Conditions
              </p>
            </div>
            <div class="sub" style="border-bottom: none">
              <p style="font-weight: bold">Registered office</p>
              <p style="font-size: 14px; font-weight: bold; color: #034ea2">
                Upkram Technologies Pvt. Ltd.
              </p>
              <p>3-4,3rd Floor, Riyaplex,</p>
              <p>80 Urmi Society, BPC Road,</p>
              <p>Vadodara, 390 020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>
        All right reserved &#169; Upkram Technologies Pvt. Ltd. @{{
          new Date().getFullYear()
        }}
      </p>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data() {
    return {
      privacyPopUp: false,
      CUPI: false,
      CFSI: false,
      CDDI: false,
      CCI: false,
      CIA: false,
      camera: false,
      cameraOne: false,
      kyc: false,
      tps: false,
      microphone: false,
      storage: false,
      CONI: false,
      UDYO: false,
      PCI: false,
      DTP: false,
      LTS: false,
      CPP: false,
      AIC: false,
      YPC: false,
      SP: false,
      LOS: false,
      YC: false,
      GO: false,
      FOR: false,
      DP: false,
      //Header
      viewIcon: false,
      requestType: '',
      borrowerName: '',
      phoneNumber: '',
      emailId: '',
      borrowerId: ''
    };
  },

  async created() {
    window.scrollTo(0, 0);
  },

  methods: {
    submitButton() {
      if (this.requestType == '' || this.borrowerName == '' || this.phoneNumber == '') return alert('Mandatory fields are missing.')
      const data = {
        dynamic_template_data: {
          requestType: this.requestType,
          borrowerName: this.borrowerName,
          phoneNumber: this.phoneNumber,
          emailId: this.emailId,
          borrowerId: this.borrowerId,
        }
      }
      axios.post(`https://consoleapi.credin.in/v3/email/credin-website/send`, data).then(res => {
        console.log(res.data)
        alert('Thanks for submitting request.')
        location.reload()
      }).catch(error => {
        console.error(error);
        alert('Something went wrong.')
      });
    },

    displayHeader() {
      this.viewIcon = !this.viewIcon;
      var x = document.getElementById("myTopnav");
      if (x.className === "header-one") {
        x.className += " responsive";
      } else {
        x.className = "header-one";
      }
      var y = document.getElementById("myTopnavOne");
      if (y.className === "header") {
        y.className += "responsive-one";
      } else {
        y.className = "header";
      }
      var z = document.getElementById("myTopnavTwo");
      if (z.className === "credin-logo") {
        z.className += "responsive-two";
      } else {
        z.className = "credin-logo";
      }
    },

    goToWhatsapp() {
      window.location.href = "https://wa.me/+919879015554";
    },

    goToWebsite() {
      window.location.href = "https://play.google.com/store/apps/details?id=com.credin.app";
    },

    goToMap() {
      window.location.href = "https://goo.gl/maps/rR6763JJZUVbi3bv8";
    },

    goToFacebook() {
      window.location.href = "https://www.facebook.com/credin25/";
    },

    goToTwitter() {
      window.location.href = "https://twitter.com/credin4";
    },

    goToInstagram() {
      window.location.href = "https://www.instagram.com/credinindia/?hl=en";
    },

    goToLinkdin() {
      window.location.href = "https://in.linkedin.com/company/upkram";
    },

    showPolicy() {
      this.privacyPopUp = !this.privacyPopUp;
    },

    showCUPI() {
      this.CUPI = !this.CUPI;
    },

    showCFSI() {
      this.CFSI = !this.CFSI;
    },

    showCDDI() {
      this.CDDI = !this.CDDI;
    },

    showCCI() {
      this.CCI = !this.CCI;
    },

    showCIA() {
      this.CIA = !this.CIA;
    },

    showCamera() {
      this.camera = !this.camera;
    },

    showCameraOne() {
      this.cameraOne = !this.cameraOne;
    },

    showKyc() {
      this.kyc = !this.kyc;
    },

    showMicrophone() {
      this.microphone = !this.microphone;
    },

    showTps() {
      this.tps = !this.tps;
    },

    showStorage() {
      this.storage = !this.storage;
    },

    showCONI() {
      this.CONI = !this.CONI;
    },

    showUDYO() {
      this.UDYO = !this.UDYO;
    },

    showPCI() {
      this.PCI = !this.PCI;
    },

    showDTP() {
      this.DTP = !this.DTP;
    },

    showLTS() {
      this.LTS = !this.LTS;
    },

    showCPP() {
      this.CPP = !this.CPP;
    },

    showAIC() {
      this.AIC = !this.AIC;
    },

    showYPC() {
      this.YPC = !this.YPC;
    },

    showSP() {
      this.SP = !this.SP;
    },

    showLOS() {
      this.LOS = !this.LOS;
    },

    showYC() {
      this.YC = !this.YC;
    },

    showGO() {
      this.GO = !this.GO;
    },

    showDP() {
      this.DP = !this.DP
    },

    showFOR() {
      this.FOR = !this.FOR;
    },
  },
};
</script>

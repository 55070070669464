<!-- @format -->

<style scoped>
  /* header Start*/

  .displaynone {
    display: none;
  }

  .blurBackground {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1% 6%;
    box-shadow: 0 4px 2px -2px #4141415a;
    top: 0;
    background: #ffffff;
    z-index: 1;
    position: sticky;
  }

  .header-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  #current {
    color: #034ea2;
  }

  #current-one {
    text-decoration: underline;
  }

  .header-one p {
    font-size: 17px;
    cursor: pointer;
  }

  .header-one button {
    height: 6vh;
    width: 9vw;
    border: none;
    background-color: #03931b;
    color: #ffffff;
    font-size: 13px;
    border-radius: 7px;
    cursor: pointer;
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    background: linear-gradient(to bottom, #ebf4ff 0%, #c8e1fd 100%);
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 600px) {
    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      width: 10vw;
      text-align: center;
    }

    .header-one img {
      display: none;
    }

    .applynow img {
      width: 40%;
    }

    .mail {
      color: #034ea2;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1% 8%;
      box-shadow: 0 4px 2px -2px #4141415a;
      top: 0;
      background: #ffffff;
      z-index: 1;
      position: sticky;
    }

    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      text-align: center;
    }

    .header-one button,
    .faq-five p {
      display: none;
    }

    .header-one {
      display: block;
      width: 30vw;
    }

    .header-one img {
      float: right;
      right: 0;
      display: block;
    }

    .header.responsive-one {
      background-color: #1f2934;
    }

    .credin-logo.responsive-two {
      display: none;
    }

    .header-one.responsive {
      width: 75%;
      margin-left: 25%;
      background-color: #ffffff;
      color: #000000;
      position: fixed;
      height: 100%;
      z-index: 1;
    }

    .header-one.responsive img {
      position: absolute;
      margin-right: 50%;
      margin-top: 25px;
    }

    .header-one.responsive p {
      float: none;
      display: block;
      text-align: center;
      margin-top: 25px;
      font-size: 20px;
      font-weight: 400;
    }

    .header-one.responsive p {
      margin: 0;
      margin-top: 25px;
      width: 90%;
    }
  }

  /* header end */

  * {
    font-family: "Lato", sans-serif;
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    background: linear-gradient(to bottom, #ebf4ff 0%, #c8e1fd 100%);
    padding-bottom: 1%;
  }
  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .phead {
    text-align: center;
    margin-top: 30px;
    color: #034ea2;
  }

  /********** Nav Bar CSS **********/

  .part {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 2px -2px rgb(130, 131, 134);
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5% 2.5%;
    cursor: pointer;
    z-index: 1;
  }

  .img {
    width: 125px;
    height: 75px;
  }

  .menu {
    float: right;
    margin: 2px;
    text-decoration: none;
    font-size: 17px;
  }

  .dropdown {
    float: left;
    overflow: hidden;
  }

  .dropdown .dropbtn {
    font-size: 17px;
    border: none;
    color: #000000;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }

  .dropdown:hover .dropbtn:hover {
    background-color: #ffffff;
    color: #034ea2;
    border-radius: 4px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: #000000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    color: #034ea2;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    color: #034ea2;
  }

  .btn {
    margin-left: 50px;
    margin-right: 10px;
    margin-top: 5px;
    /* padding: 10px 28px 10px 28px; */
    background-color: #00944a;
    color: #ffffff;
    font-size: 20px;
    border: none;
    border-radius: 3px;
    font-weight: 600;
    width: 120px;
    text-align: center;
    height: 44px;
  }

  .navbarsmap:hover {
    border-bottom: 2px solid #034ea2;
  }

  .menu:hover {
    color: #034ea2;
  }

  .navbarsmap:focus {
    border-bottom: 2px solid #034ea2;
  }

  .menu:active {
    color: #034ea2;
  }
  .menu:focus {
    background-color: #e6e6e6;
  }

  #current {
    color: #034ea2;
  }

  #current-one {
    text-decoration: underline;
  }

  /*********GIF CSS *******/
  .gif {
    margin: 0 10%;
  }

  /********Contact us CSS*******/

  .contact {
    margin: 0 10%;
  }
  /********BOX CSS *******/

  .box {
    margin: 2% 10%;
    border: 2px solid #034ea2;
    border-radius: 5px;
    padding: 20px;
  }
  #box-btn {
    font-size: 24px;
    width: 200px;
    height: 50px;
    color: #000000;
    background-color: transparent;
    border-radius: 5px;
    border: 2px solid #034ea2;
    margin: 10px 15px;
  }

  #box-btn:hover {
    font-size: 24px;
    width: 200px;
    height: 50px;
    color: #ffffff;
    background-color: #034ea2;
    border-radius: 5px;
    border: 1px solid #034ea2;
    margin: 10px 15px;
  }

  /*********Tooltip css ********/
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 250px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 10%;
    /* margin-left: -60px; */
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 20px;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  /********* Footer CSS **********/

  .sub p {
    margin: 0;
    margin-top: 2.5vh;
  }

  @media (min-width: 650px) {
    .box-main-branch{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .box-row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .footer-main {
      height: 350px;
    }

    .footer {
      font-size: 14px;
      background: url("../assets/footer/WebsiteFooter.gif");
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #000000;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom;
    }

    .row2-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 350px;
      width: 100%;
    }

    .foota {
      color: #000000;
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .sub {
      display: flex;
      flex-direction: column;
      margin: 0 25px;
      text-align: left;
      font-size: 18px;
      width: 100%;
    }

    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #fff;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      background-color: #034da2;
    }
  }

  @media (max-width: 650px) {
    .box-row {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .footer {
      background-color: #034ea2;
      font-size: 14px;
      background-size: contain;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #fff;
      padding-top: 3vh;
    }

    .row-footer {
      display: flex;
      flex-direction: column;
    }

    .row2-footer {
      display: flex;
      flex-direction: column;
    }

    .foota {
      color: #ffffff;
      margin-top: 1em;
    }

    .img {
      display: none;
    }

    .sub {
      font-size: 16px;
      margin-top: 1vh;
      padding-bottom: 1vh;
      border-bottom: 2px solid white;
    }
    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #034da2;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      background-color: white;
    }
  }

  .foota {
    margin-bottom: 5px;
    text-decoration: none;
  }

  /* footer end */

  .mn1 {
    align-items: center;
    justify-content: center;
    padding: 0px 6px 8px 6px;
    margin: 1px;
    color: #ffffff;
    text-decoration: none;
  }

  .copytop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 40px;
    background-color: #6c6c6d;
    border-top: 2px solid #ffffff;
  }

  @media (min-width: 600px) {
    .colunm1 {
      display: flex;
      flex-direction: column;
      margin: 10px;
      align-items: flex-start;
    }

    .navbar {
      margin-left: 115px;
      text-decoration: none;
    }

    .navnav {
      float: right;
      display: flex;
      margin-right: 140px;
    }

    .btn {
      margin-left: 80px;
      margin-right: 50px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #03931b;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }

    #in {
      margin-left: 230px;
      margin-right: 60px;
    }

    #in1 {
      margin-right: 200px;
      margin-left: 60px;
    }

    #box-item {
      height: 300px;
      margin-left: 20px;
      margin-right: 20px;
    }

    .text {
      margin: 20px;
      text-align: center;
      color: #000000;
      font-style: bold;
    }

    /********Contact us CSS*******/

    .Contact-row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  @media (max-width: 600px) {
    .nav {
      display: none;
    }

    .navbar {
      margin-right: 50px;
      color: #000000;
      text-decoration: none;
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .navnav {
      float: right;
      display: flex;
      margin-left: 50px;
    }

    .btn {
      margin-left: 40px;
      margin-right: 20px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #00944a;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }

    .colunm {
      display: flex;
      flex-direction: row;
    }

    .colunm1 {
      display: flex;
      flex-direction: column;
      margin: 10px;
    }

    .row {
      display: flex;
      flex-direction: row;
      /* text-align: center; */
      align-items: center;
      /* justify-content: center; */
    }

    #in,
    #in1,
    #box-item {
      margin-left: 10px;
      margin-right: 10px;
    }

    .text {
      margin: 20px 35px;
      /* margin-top: 100px; */
      text-align: center;
      color: #000000;
      font-style: bold;
    }

    /********Contact us CSS*******/

    .Contact-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
</style>
<template>
  <div class="header" id="myTopnavOne">
    <img
      v-if="!viewIcon"
      src="../assets/CREDINLOGO.svg"
      class="credin-logo"
      id="myTopnavTwo"
      alt="logo.svg"
      height="70"
      width="140"
      @click="$router.push('/home')"
      style="cursor: pointer"
    />
    <div class="header-one" id="myTopnav">
      <p class="menu" @click="$router.push('/home')">
        <span class="navbarsmap">Ho</span>me
      </p>
      <div v-if="!viewIcon">
        <div class="menu">
          <div class="dropdown">
            <button class="dropbtn">Product</button>
            <div class="dropdown-content">
              <!-- <a @click="$router.push('/pratham')">Pratham</a> -->
              <a href="/aarambh">Aarambh</a>
              <!-- <a href="/shiksha">Shiksha</a> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- <p class="menu" @click="$router.push('/pratham')">Pratham</p> -->
        <p class="menu" @click="$router.push('/aarambh')">Aarambh</p>
        <!-- <p class="menu" @click="$router.push('/shiksha')">Shiksha</p> -->
      </div>
      <p class="menu" @click="$router.push('/ourjourney')">
        <span class="navbarsmap">Our</span> Journey
      </p>
      <div class="menu">
        <button style="height: 6vh; margin-left: 1vw" @click="goToWebsite">
          Download App
        </button>
      </div>
      <img
        @click="displayHeader"
        src="../assets/menu-hor.svg"
        alt="menu-bar.svg"
        style="height: 2vh"
        v-if="!viewIcon"
      />
      <img
        @click="displayHeader"
        src="../assets/Close1.svg"
        alt="menu-bar.svg"
        style="height: 4vh"
        v-else
      />
    </div>
  </div>
  <div :class="{ blurBackground: viewIcon }">
    <div class="container">
      <div class="contact">
        <div class="Contact-row">
          <div class="row">
            <img
              style="width: 12%; margin-right: 10px"
              src="../assets/Call.svg"
            />
            <h1 style="font-size: 30px">: +91 98790 15554</h1>
          </div>
          <div class="row">
            <img
              style="width: 12%; margin-right: 10px"
              src="../assets/Mail.svg"
            />
            <h1 style="font-size: 30px">: help@credin.in</h1>
          </div>
        </div>
      </div>

      <div class="box">
        <h1 style="margin: 0px 0px 10px 0px; text-align: center">
          Smart Branches
        </h1>
        <div class="box-main-branch">
          <div class="box-row" v-for="branch in branchData" :key="branch.id">
            <button id="box-btn" class="tooltip">
              {{ branch.branchName }}
              <span class="tooltiptext"
                >Contact Person:<br />
                {{ branch.branchManger }} <br />
                Contact Details: {{ branch?.managerNumber }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-main">
      <div class="footer">
        <div style="padding: 0 10%" class="row-footer">
          <div class="row2-footer" id="row2">
            <div class="sub">
              <img
                class="img"
                src="../assets/img/Credinlogo.svg"
                alt="credin"
                @click="$router.push('/home')"
                style="cursor: pointer"
              />
              <p
                style="cursor: pointer; font-weight: bold; margin-top: 0"
                @click="$router.push('/contact-us')"
              >
                Contact Us
              </p>
              <p>+919879015554</p>
              <p><a class="foota" href="help@credin.in">help@credin.in</a></p>
              <div style="display: flex; margin-top: 3vh">
                <a
                  href="https://www.facebook.com/credin25"
                  class="mn1"
                  style="padding: 0px 10px 8px 0"
                >
                  <img src="../assets/Fb.svg"
                /></a>
                <a href="https://www.instagram.com/credinindia/" class="mn1"
                  ><img src="../assets/Insta.svg"
                /></a>
                <a href="https://twitter.com/home-" class="mn1"
                  ><img src="../assets/Twitter.svg"
                /></a>
                <a
                  href="https://www.youtube.com/channel/UC-H2CRfZIdPDsqk_JrfPRtw"
                  class="mn1"
                  ><img src="../assets/YouTube.svg"
                /></a>
                <a href="https://in.linkedin.com/company/upkram" class="mn1"
                  ><img src="../assets/Linkdin.svg"
                /></a>
              </div>
            </div>
            <div class="sub">
              <p style="cursor: pointer" @click="$router.push('/omnichannel')">
                Omni Channel
              </p>
              <p
                style="cursor: pointer"
                @click="$router.push('/nbfc-partners')"
              >
                NBFC Partners
              </p>
              <p style="cursor: pointer" @click="$router.push('/faq')">FAQs</p>
              <p
                style="cursor: pointer"
                @click="$router.push('/privacy-policy')"
              >
                Privacy policy
              </p>
              <p
                style="cursor: pointer"
                @click="$router.push('/terms-conditions')"
              >
                Terms & Conditions
              </p>
            </div>
            <div class="sub" style="border-bottom: none">
              <p style="font-weight: bold">Registered office</p>
              <p style="font-size: 14px; font-weight: bold; color: #034ea2">
                Upkram Technologies Pvt. Ltd.
              </p>
              <p>3-4,3rd Floor, Riyaplex,</p>
              <p>80 Urmi Society, BPC Road,</p>
              <p>Vadodara, 390 020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>
        All right reserved &#169; Upkram Technologies Pvt. Ltd. @{{
          new Date().getFullYear()
        }}
      </p>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        //header
        viewIcon: false,
        branchData: []
      };
    },

    async created() {
      window.scrollTo(0, 0);
      let result = await axios.get(`https://consoleapi.credin.in/credin/branch/data`)
      this.branchData = result.data.data
    },

    methods: {
      displayHeader() {
        this.viewIcon = !this.viewIcon;
        var x = document.getElementById("myTopnav");
        if (x.className === "header-one") {
          x.className += " responsive";
        } else {
          x.className = "header-one";
        }
        var y = document.getElementById("myTopnavOne");
        if (y.className === "header") {
          y.className += "responsive-one";
        } else {
          y.className = "header";
        }
        var z = document.getElementById("myTopnavTwo");
        if (z.className === "credin-logo") {
          z.className += "responsive-two";
        } else {
          z.className = "credin-logo";
        }
      },

      goToWhatsapp() {
        window.location.href = "https://wa.me/+919879015554";
      },

      goToWebsite() {
        window.location.href = "https://play.google.com/store/apps/details?id=com.credin.app";
      },

      goToMap() {
        window.location.href = "https://goo.gl/maps/rR6763JJZUVbi3bv8";
      },

      goToFacebook() {
        window.location.href = "https://www.facebook.com/credin25/";
      },

      goToTwitter() {
        window.location.href = "https://twitter.com/credin4";
      },

      goToInstagram() {
        window.location.href = "https://www.instagram.com/credinindia/?hl=en";
      },

      goToLinkdin() {
        window.location.href = "https://in.linkedin.com/company/upkram";
      },
    },
  };
</script>

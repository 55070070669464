<!-- @format -->

<style scoped>
/* header Start*/

.displaynone {
  display: none;
}

.india{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 10%;
}

.india img{
  height: 50vh;
  width: 45vw;
}

.blurBackground {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.container {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1% 6%;
  box-shadow: 0 4px 2px -2px #4141415a;
  top: 0;
  background: #ffffff;
  z-index: 1;
  position: sticky;
}

.header-one {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#current {
  color: #034ea2;
}

#current-one {
  text-decoration: underline;
}

.header-one p {
  font-size: 17px;
  cursor: pointer;
}

.header-one button {
  height: 6vh;
  width: 9vw;
  border: none;
  background-color: #03931b;
  color: #ffffff;
  font-size: 13px;
  border-radius: 7px;
  cursor: pointer;
}

.container {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.row-about {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.row2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.colunm {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .menu {
    float: right;
    padding: 10px 0 8px 0;
    margin: 2px;
    text-decoration: none;
    font-size: 17px;
    width: 10vw;
    text-align: center;
  }

  .header-one img {
    display: none;
  }

  .applynow img {
    width: 40%;
  }

  .mail {
    color: #034ea2;
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1% 8%;
    box-shadow: 0 4px 2px -2px #4141415a;
    top: 0;
    background: #ffffff;
    z-index: 1;
    position: sticky;
  }

  .menu {
    float: right;
    margin: 2px;
    text-decoration: none;
    font-size: 17px;
    text-align: center;
  }

  .header-one button,
  .header-one p {
    display: none;
  }

  .header-one {
    display: block;
    width: 30vw;
  }

  .header-one img {
    float: right;
    right: 0;
    display: block;
  }

  .header.responsive-one {
    background-color: #1f2934;
  }

  .credin-logo.responsive-two {
    display: none;
  }

  .header-one.responsive {
    width: 75%;
    margin-left: 25%;
    background-color: #ffffff;
    color: #000000;
    position: fixed;
    height: 100%;
    z-index: 1;
  }

  .header-one.responsive img {
    position: absolute;
    margin-right: 50%;
    margin-top: 25px;
  }

  .header-one.responsive p {
    float: none;
    display: block;
    text-align: center;
    margin-top: 25px;
    font-size: 20px;
    font-weight: 400;
  }

  .header-one.responsive p {
    margin: 0;
    margin-top: 25px;
    width: 90%;
  }
}

/* header end */

* {
  font-family: "Lato", sans-serif;
}

body {
  width: auto;
  display: grid;
  overflow-x: hidden;
}

.bg1 {
  background-color: #ffffff;
}

.row2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.colunm {
  display: flex;
  flex-direction: column;
}

.phead {
  text-align: center;
  margin-top: 50px;
  color: #034ea2;
}

/* navbar start */

.part {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 4px 2px -2px rgb(130, 131, 134);
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5% 2.5%;
  cursor: pointer;
  z-index: 1;
}

.img {
  width: 125px;
  height: 75px;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  color: #000000;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown:hover .dropbtn:hover {
  background-color: #ffffff;
  color: #034ea2;
  border-radius: 4px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: #000000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: #034ea2;
}

.dropdown:hover .dropdown-content {
  display: block;
  color: #034ea2;
}

.btn {
  margin-left: 50px;
  margin-right: 10px;
  margin-top: 5px;
  background-color: #00944a;
  color: #ffffff;
  font-size: 20px;
  border: none;
  border-radius: 3px;
  font-weight: 600;
  width: 120px;
  text-align: center;
  height: 44px;
}

.navbarsmap:hover {
  border-bottom: 1.5px solid #034ea2;
}

#current-span {
  border-bottom: 1.5px solid #034ea2;
}

.menu:hover {
  color: #034ea2;
}

.menu:focus {
  background-color: #e6e6e6;
}

#current {
  color: #034ea2;
}

#current-one {
  text-decoration: underline;
}

/* nav bar end */

/********About CSS *********/

.about {
  /* padding: 20px; */
  margin: 0 10%;
}

.img-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.about-item {
  margin: 10px;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.about-text {
  line-height: 2;
  font-size: 18px;
}

.about-mainimg {
  width: 75%;
}

.about-img {
  display: flex;
  justify-content: center;
}

/********Features CSS ********/

.feature {
  /* margin-top: 85px; */
  margin: 0 10%;
}

.feature-p {
  text-align: center;
  color: #000000;
  font-size: 18px;
  /* margin: 20px 30px; */
  /* line-height: 1.6; */
}

/********Impect CSS ********/

.impect {
  margin: 0 10%;
}

.rnd {
  margin: 10px 10px 15px 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.impect-text {
  margin-top: 10px;
  /* margin-right: 55px; */
  /* padding: 10px 25px 0px 0px; */
  text-align: center;
}

.impect-img {
  width: 90%;
}

/*********Product CSS*********/

.product {
  margin: 0 10%;
}

.product-img {
  text-align: left;
}

.product-text {
  text-align: left;
  line-height: 1.5;
  font-size: 18px;
}

/********API CSS *******/

.api {
  margin: 0 10%;
}

.api-item {
  margin: 10px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.api-text {
  line-height: 2.6;
  font-size: 18px;
  /* line-height: 1.6; */
  /* margin-left: 40px; */
  align-items: center;
  display: flex;
  font-size: 18px;
}

.highlight-img {
  margin: 0px 20px 3px 0px;
  animation: animate 2s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.7;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

.api-mainimg {
  width: 30%;
}

/********INVESTOR CSS *******/
.investor {
  margin: 0 10%;
}

.investor-main {
  height: 130px;
  margin-top: 20px;
  /* border: 2px solid #034ea2; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.invesor-p {
  text-align: center;
  font-weight: 600;
  font-size: 25px;
}

/********NBFC CSS *******/
.nbfc {
  margin: 0 10%;
}

.nbfc-main {
  height: 60px;
  margin-top: 20px;
  /* border: 2px solid #034ea2; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nbfc-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nbfc-img {
  width: 14%;
  /* margin: 10px; */
}

/********presence CSS *******/

.presence {
  margin: 0 10%;
}

.pre-item {
  background: #ffffff;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  height: auto;
  border: 1.5px solid #034ea2;
  border-radius: 5px;
}

.pre-circle {
  background: #ffffff;
  text-align: center;
  height: 140px;
  padding: 20px;
  width: 140px;
  margin: 15px 36px;
  border-radius: 100px;
  border: 1.5px solid #000000;
}

/*********GIF CSS ********/
.gif-main {
  margin: 0 10%;
}

.gif {
  margin-top: 25px;
  width: 100%;
}

/******** MA CSS********/

.ma {
  margin: 0 10%;
  width: auto;
  /* margin: 0 10% 2% 10%; */
  /* margin-left: 20px; */
  /* margin-right: 20px; */
}

.ma-three {
  display: flex;
  flex-direction: row;
  color: lightslategray;
}

.ma-img {
  text-align: center;
}

.ma-img1 {
  width: 100%;
  /* margin-bottom: 20px; */
  /* height: 15%; */
}

.ma-div {
  display: flex;
  flex-direction: initial;
  justify-content: left;
  align-items: center;
  font-size: 18px;
  color: #0059b3;
  text-decoration: none;
  font-weight: 600;
}

.ma-divp {
  font-size: 20px;
  color: #0059b3;
  font-weight: 600;
  text-decoration: none;
}

/********Enable CSS ********/

.enable {
  width: auto;
  padding: 0px 0 15px 0;
  margin: 0 10%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.enable-p2 {
  color: #e81922;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
}

.cases {
  font-size: 27px;
  font-weight: 800;
}

.customer {
  font-size: 27px;
  font-weight: 800;
}

.amount {
  font-size: 27px;
  font-weight: 800;
}

.team {
  font-size: 27px;
  font-weight: 800;
}

/********* Footer CSS **********/

.sub p {
  margin: 0;
  margin-top: 2.5vh;
}

@media screen and (min-width: 600px) {
  .footer-main {
    height: 350px;
  }

  .about-text {
    line-height: 2;
    font-size: 18px;
  }

  .footer {
    font-size: 14px;
    background: url("../assets/footer/WebsiteFooter.gif");
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    color: #000000;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
  }

  .row2-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 350px;
    width: 100%;
  }

  .foota {
    color: #000000;
  }

  .img {
    width: 100px;
    height: 75px;
    margin-right: 50px;
  }

  .sub {
    display: flex;
    flex-direction: column;
    margin: 0 25px;
    text-align: left;
    font-size: 18px;
    width: 100%;
  }

  .subh {
    text-align: left;
    font-size: 18px;
  }

  .copyright {
    display: flex;
    height: 5vh;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    background-color: #034da2;
  }
}

@media screen and (max-width: 600px) {
  .phead {
    text-align: center;
    margin-top: 35px;
  }

  .about-text {
    line-height: 2;
    font-size: 18px;
    text-align: center;
  }

  .about-mainimg {
    width: 75%;
  }

  .product {
    margin: 0 10%;
    margin-bottom: -30px;
  }

  .api-text {
    line-height: 1.5;
    font-size: 18px;
    /* line-height: 1.6; */
    /* margin-left: 40px; */
    align-items: center;
    display: flex;
    font-size: 18px;
  }

  .api-mainimg {
    width: 100%;
  }

  .nbfc-main {
    height: 130px;
    margin-top: 0px;
    /* border: 2px solid #034ea2; */
    display: contents;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .nbfc-img {
    width: 40%;
    margin: 15px;
  }

  .enable-p2 {
    color: #e81922;
    font-size: 13px;
    font-weight: 600;
  }

  .pre-item {
    background: #ffffff;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    /* margin: 30px 15px; */
    margin-bottom: 0px;
    /* width: 95%; */
    height: auto;
    border: 1.5px solid #034ea2;
    border-radius: 5px;
  }

  .footer {
    background-color: #034ea2;
    font-size: 14px;
    background-size: contain;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    color: #fff;
    padding-top: 3vh;
  }

  .row-footer {
    display: flex;
    flex-direction: column;
  }

  .row2-footer {
    display: flex;
    flex-direction: column;
  }

  .foota {
    color: #ffffff;
    margin-top: 1em;
  }

  .img {
    display: none;
  }

  .sub {
    font-size: 16px;
    margin-top: 1vh;
    padding-bottom: 1vh;
    border-bottom: 1.5px solid white;
  }

  .subh {
    text-align: left;
    font-size: 18px;
  }

  .copyright {
    display: flex;
    height: 5vh;
    color: #034da2;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background-color: white;
  }
}

.foota {
  margin-bottom: 5px;
  text-decoration: none;
}

/* footer end */

.mn1 {
  align-items: center;
  justify-content: center;
  padding: 0px 6px 8px 6px;
  margin: 1px;
  color: #ffffff;
  text-decoration: none;
}

.copytop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  background-color: #6c6c6d;
  border-top: 1.5px solid #ffffff;
}

@media screen and (min-width: 600px) {
  .colunm1 {
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: flex-start;
  }

  .navbar {
    margin-left: 115px;
    /* color: pink; */
    text-decoration: none;
    /* padding: 20px; */
  }

  /* .img {
      width: 100px;
      height: 75px;
      margin-right: 60px;
    } */

  .navnav {
    float: right;
    display: flex;
    margin-right: 140px;
  }

  .btn {
    margin-left: 80px;
    margin-right: 50px;
    margin-top: 5px;
    padding: 10px 25px 10px 25px;
    background-color: #03931b;
    color: #ffffff;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    font-weight: 600;
  }

  #in {
    margin-left: 230px;
    margin-right: 60px;
  }

  #in1 {
    margin-right: 200px;
    margin-left: 60px;
  }

  #box-item {
    /* width: 800px; */
    height: 300px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .text {
    margin: 20px;
    text-align: center;
    background: linear-gradient(to bottom right, #034ea2, #ed1c24);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    font-style: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
  }

  /******Gif CSS******/

  .gif-text {
    margin: 0 10%;
    /* margin: 20px 148px; */
    text-align: justify;
    line-height: 2;
    font-size: 18px;
  }

  #box-main {
    margin: 40px;
  }

  .img2 {
    margin-top: 10px;
    width: 85%;
  }

  /******Feature CSS******/

  .feature-item {
    background: #ffffff;
    height: 110px;
    padding: 0px 30px;
    width: 350px;
    margin: 10px 20px;
    border: 1.5px solid #034ea2;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
  }

  .feature-item-one {
    width: 75px;
  }

  .feature-item-two {
    width: 215px;
  }

  .feature-item:hover {
    background: #034ea2;
    color: #ffffff;
    height: 110px;
    padding: 0px 30px;
    width: 350px;
    margin: 10px 20px;
    border: 1.5px solid #034ea2;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
  }

  .feature-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .feature-row2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  /******Impect CSS******/

  .impect-item {
    background: #ffffff;
    text-align: center;
    height: 80px;
    padding: 25px;
    width: 80px;
    border-radius: 100px;
    border: 1.5px solid #034ea2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .let {
    font-size: 19px;
    text-align: center;
    margin: 10px;
  }

  /*********Product CSS ********/

  .product-item {
    padding: 20px 28px;
    background: #ffffff;
    text-align: center;
    margin: 0 30px;
    width: 25%;
    border: 1.5px solid #034ea2;
    border-radius: 5px;
  }

  .product-item:hover {
    padding: 20px 28px;
    background: #cdcdcd;
    text-align: center;
    margin: 0 30px;
    width: 25%;
    border: 1.5px solid #034ea2;
    border-radius: 5px;
    cursor: pointer;
  }

  /*******Presence CSS ********/

  .pre-text {
    margin: 30px 50px;
    line-height: 1.6;
    font-size: 18px;
  }

  /*******MA CSS *******/

  .ma-item {
    background: #ffffff;
    width: 300px;
    margin: 10px;
    /* height: auto; */
    padding: 20px;
    border: 1.5px solid rgb(130 131 134);
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation: scroll 120s linear infinite;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-5400px);
    }
  }

  .story-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
  }

  .ma-media-img {
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 200px;
    padding: 20px 0px 20px 0px;
  }

  .ma-p2 {
    color: gray;
    line-height: 1.6;
    font-size: 15px;
  }

  .ma-p1 {
    margin-left: 1vw;
    font-size: 17px;
  }

  .ma-p3 {
    font-size: 17px;
  }

  /*******Enable CSS *******/

  .enable-item {
    background: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 135px;
    padding: 20px;
    width: 145px;
    margin: 20px 40px;
  }

  .p2 {
    text-align: center;
    color: #ffffff;
  }

  .p1 {
    color: #0059b3;
    margin: 0;
    font-size: 30px;
    font-weight: 600;
  }

  .enable-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 600px) {
  /* .nav {
      display: none;
    }

    .navbar {
      margin-right: 50px;
      color: #000000;
      text-decoration: none;
    } */

  .img {
    width: 100px;
    height: 75px;
    margin-right: 50px;
  }

  .navnav {
    float: right;
    display: flex;
    margin-left: 50px;
  }

  .btn {
    margin-left: 40px;
    margin-right: 20px;
    margin-top: 5px;
    padding: 10px 25px 10px 25px;
    background-color: #00944a;
    color: #ffffff;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    font-weight: 600;
  }

  .colunm {
    display: flex;
    flex-direction: row;
  }

  .colunm1 {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .row-about {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }

  #in,
  #in1,
  #box-item {
    margin-left: 10px;
    margin-right: 10px;
  }

  .text {
    margin: 20px;
    text-align: center;
    background: linear-gradient(to bottom right, #034ea2, #ed1c24);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    font-style: bold;
  }

  /********India CSS *********/

  .india {
    margin: 0 10%;
    display: flex;
    flex-direction: column;
  }

  .india img{
    height: 50vh;
    width: 80vw;
  }

  .img2 {
    margin-top: 10px;
    width: 100%;
  }

  /******Gif CSS******/

  .gif-text {
    margin: 0 10%;
    /* margin: 20px 34px; */
    text-align: center;
    line-height: 2;
    font-size: 18px;
  }

  /********Feature CSS ********/

  .feature-item {
    text-align: center;
    height: 130px;
    padding: 10px;
    width: 130px;
    margin: 10px;
    /* border-radius: 105px; */
    border: 1.5px solid #034ea2;
    border-radius: 5px;
    font-size: 11px;
  }

  .feature-item:hover {
    text-align: center;
    color: #ffffff;
    background-color: #034ea2;
    height: 130px;
    padding: 10px;
    width: 130px;
    margin: 10px;
    /* border-radius: 105px; */
    border: 1.5px solid #034ea2;
    border-radius: 5px;
    font-size: 11px;
  }

  .feature-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .feature-row2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  /********Impect CSS ********/

  .impect-item {
    text-align: center;
    height: 115px;
    padding: 15px;
    width: 115px;
    margin: 10px;
    border-radius: 110px;
    border: 1.5px solid #034ea2;
    font-size: 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .let {
    font-size: 15px;
    text-align: center;
    margin: 10px;
  }

  /*********Product CSS ********/

  .product-item {
    padding: 20px 28px;
    background: #ffffff;
    text-align: center;
    margin-bottom: 30px;
    height: auto;
    width: 90%;
    border: 1.5px solid #034ea2;
    border-radius: 5px;
  }

  .product-item:hover {
    padding: 20px 28px;
    background: #cdcdcd;
    text-align: center;
    margin-bottom: 30px;
    height: auto;
    width: 90%;
    border: 1.5px solid #56a0ea;
    border-radius: 5px;
    cursor: pointer;
  }

  /*******Presence CSS ********/
  .pre-text {
    margin: 15px 0px;
    line-height: 1.6;
    font-size: 18px;
  }

  /********MA CSS ********/

  .ma {
    margin: 0 10%;
    width: auto;
    /* padding: 45px 0 15px 0; */
    /* margin: 20px;
      margin-left: 20px;
      margin-right: 20px; */
    max-width: 100%;
    overflow: hidden;
  }

  .story-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
  }

  .ma-item {
    background: #ffffff;
    width: 300px;
    margin: 15px 8px;
    height: auto;
    padding: 20px;
    border: 1px solid rgb(130 131 134);
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    animation: scroll 40s linear infinite;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-2240px);
    }
  }

  .ma-media-img {
    justify-content: center;
    align-items: center;
    width: 85%;
    /* height: 300px; */
    padding: 20px;
  }

  .ma-p2 {
    color: gray;
    line-height: 1.6;
    font-size: 18px;
  }

  .ma-p1 {
    margin-left: 1vw;
    font-size: 20px;
  }

  .ma-p3 {
    font-size: 22px;
    margin: 0px;
  }

  /********Enable CSS ********/

  .enable-item {
    background: #ffffff;
    text-align: center;
    height: 85px;
    padding: 10px;
    width: 137px;
    margin: 10px;
    font-size: 20px;
  }

  .p2 {
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    margin: 15px;
  }

  .p1 {
    color: #0059b3;
    margin: 0;
    font-size: 25px;
    font-weight: 600;
  }
}

@media (max-width: 850px) and (min-width: 600px) {
  .product-item {
    padding: 20px;
    background: #ffffff;
    text-align: center;
    margin: 0 15px;
    width: 60%;
    /* height: auto; */
    border: 1.5px solid #034ea2;
    border-radius: 5px;
  }

  .product-item:hover {
    padding: 20px;
    background: #cdcdcd;
    text-align: center;
    margin: 0 15px;
    width: 60%;
    border: 1.5px solid #034ea2;
    border-radius: 5px;
    cursor: pointer;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .enable-row {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .story-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media (max-width: 1160px) and (min-width: 850px) {
  .sub {
    /* margin-left: 60px; */
    /* margin-right: 50px; */
    text-align: left;
    font-size: 22px;
  }

  #row2 {
    padding: 5px;
  }

  .product-item {
    padding: 20px 28px;
    background: #ffffff;
    text-align: center;
    margin: 0 10px;
    width: 60%;
    border: 1.5px solid #034ea2;
    border-radius: 5px;
  }

  .product-item:hover {
    padding: 20px 28px;
    background: #cdcdcd;
    text-align: center;
    margin: 0 30px;
    width: 60%;
    border: 1.5px solid #034ea2;
    border-radius: 5px;
    cursor: pointer;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .story-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .enable-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .enable-item {
    background: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 135px;
    padding: 15px;
    width: 145px;
    margin: 20px;
  }
}
</style>

<template>
  <div class="header" id="myTopnavOne">
    <div style="display: flex;align-items: center;">
      <img
        v-if="!viewIcon"
        src="../assets/CREDINLOGO.svg"
        class="credin-logo"
        id="myTopnavTwo"
        alt="logo.svg"
        height="70"
        width="140"
        @click="$router.push('/home')"
        style="cursor: pointer"
      />
    </div>

    <div class="header-one" id="myTopnav">
      <p class="menu" @click="$router.push('/home')" id="current">
        <span class="navbarsmap" id="current-span">Ho</span>me
      </p>
      <div v-if="!viewIcon">
        <div class="menu">
          <div class="dropdown">
            <button class="dropbtn">Product</button>
            <div class="dropdown-content">
              <!-- <a href="/pratham">Pratham</a> -->
              <a href="/aarambh">Aarambh</a>
              <!-- <a href="/shiksha">Shiksha</a> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- <p class="menu" @click="$router.push('/pratham')">Pratham</p> -->
        <p class="menu" @click="$router.push('/aarambh')">Aarambh</p>
        <!-- <p class="menu" @click="$router.push('/shiksha')">Shiksha</p> -->
      </div>
      <p class="menu" @click="$router.push('/ourjourney')">
        <span class="navbarsmap">Our</span> Journey
      </p>
      <div class="menu">
        <button style="height: 6vh; margin-left: 1vw" @click="goToWebsite">
          Download App
        </button>
      </div>
      <img @click="displayHeader" src="../assets/menu-hor.svg" alt="menu-bar.svg" style="height: 2vh" v-if="!viewIcon" />
      <img @click="displayHeader" src="../assets/Close1.svg" alt="menu-bar.svg" style="height: 4vh" v-else />
    </div>
  </div>
  <div :class="{ blurBackground: viewIcon }">
    <div class="india">
      <!-- <div class="img-main">
        <video class="img2" autoplay loop muted>
          <source src="../assets/homepage/Homepageslider01_5.mp4" type="video/mp4" />
        </video>
      </div> -->
      <div class="testing">
        <img :src="currentImage" alt="01">
      </div>
      <div class="text">
        <h1>
          <b>Most Inclusive and Omnichannel Fintech<br />Of Bharat, By Bharat,
            For Bharat</b>
        </h1>
      </div>
    </div>

    <div class="about">
      <h1 class="phead">About Credin</h1>

      <div class="row">
        <div class="about-item">
          <div class="about-img">
            <img class="about-mainimg" src="../assets/homepage/AboutCredin01.svg" />
          </div>
        </div>
        <div class="about-item">
          <div class="about-text">
            <p>
              Credin is a Lending Platform focused on Under/ Un-Banked customers in Rural/Semi-Urban India.
            </p>
            <p>
              Credin has technology driven distribution model backed by smart branches to give Fintech Experience to next
              100 Million Rural Customers.
            </p>
            <p>
              Fintech Inclusion is our core for wider & effective Social Impact.
            </p>
          </div>
        </div>
      </div>
      <div class="row-about">
        <div class="about-item">
          <div class="about-text">
            <p>
              Credin helps the Lenders (Banks & NBFCs) to reach out to last mile credit worthy customer through its robust
              technology and distribution network in Rural Areas.
            </p>
            <p>
              Credin also helps the Lenders to roll out relevant product for the target segment and manages the entire
              loan life-cycle from origination to Collections.
            </p>
          </div>
        </div>
        <div class="about-item">
          <div class="about-img">
            <img class="about-mainimg" src="../assets/homepage/AboutCredin02.svg" />
          </div>
        </div>
      </div>
    </div>

    <div class="feature">
      <div>
        <h1 class="phead">Highlights of Credin</h1>
        <p class="feature-p">
          The word “Inclusive” is a true highlight for Credin. We are inclusive
          in our purpose, product, presence, employment and everything that we
          do.
        </p>
      </div>

      <div class="feature-row">
        <div class="feature-row2">
          <div class="feature-item">
            <div class="feature-item-one">
              <img src="../assets/img/Group211.svg" />
            </div>
            <div class="feature-item-two">
              <p>Promoting Sustainable Finance.</p>
            </div>
          </div>

          <div class="feature-item">
            <div class="feature-item-one">
              <img src="../assets/img/Group210.svg" />
            </div>
            <div class="feature-item-two">
              <p>Providing Fintech experience to the next billion users.</p>
            </div>
          </div>
        </div>

        <div class="feature-row2">
          <div class="feature-item">
            <div class="feature-item-one">
              <img src="../assets/img/Group213.svg" />
            </div>
            <div class="feature-item-two">
              <p>Products inclined towards the priority sector end use.</p>
            </div>
          </div>

          <div class="feature-item">
            <div class="feature-item-one">
              <img src="../assets/img/Group212.svg" />
            </div>
            <div class="feature-item-two">
              <p>70% TAT reduction in secured/unsecured term credit.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="impect">
      <h1 class="phead">Impact of Credin</h1>
      <div>
        <div class="row">
          <div class="row2">
            <div class="rnd">
              <div class="impect-item">
                <img class="impect-img" src="../assets/Impact01.svg" />
              </div>
              <div class="impect-text">
                <p class="let">
                  Democratizing Hassle-free and affordable credit access.
                </p>
              </div>
            </div>
            <div class="rnd">
              <div class="impect-item">
                <img class="impect-img" src="../assets/Impact02.svg" />
              </div>
              <div class="impect-text">
                <p class="let">
                  Unconventional approach towards our customers.
                </p>
              </div>
            </div>
          </div>
          <div class="row2">
            <div class="rnd">
              <div class="impect-item">
                <img class="impect-img" src="../assets/Impact03.svg" />
              </div>
              <div class="impect-text">
                <p class="let">
                  98% automated collections in tier 2 and below markets.
                </p>
              </div>
            </div>
            <div class="rnd">
              <div class="impect-item">
                <img class="impect-img" src="../assets/Impact04.svg" />
              </div>
              <div class="impect-text">
                <p class="let">
                  APIs for swift collaboration with financial institutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="product">
      <h1 class="phead">Products</h1>
      <div class="row">

        <div class="product-item" @click="$router.push('/aarambh')">
          <div class="product-img">
            <!-- <a href="#"><img src="../assets/img/CredinAarambh.svg" /></a> -->
            <h3>Credin Aarambh Loan (Unsecured)</h3>
          </div>
          <div class="product-text">
            <p>Unsecured business loan for Mincro/Nano Entreprenures & Dairy Farmers.</p>
          </div>
        </div>

        <div class="product-item" @click="$router.push('/aarambh')">
          <div class="product-img">
            <!-- <a href="#"><img src="../assets/img/CredinShiksha.svg" /></a> -->
            <h3>Credin Aarambh Loan (Secured)</h3>
          </div>
          <div class="product-text">
            <p>
              Mortgage loan for Mincro/Nano Entreprenures & Dairy Farmers.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="api">
      <h1 class="phead">Origination & Collections</h1>
      <div class="row">
        <div class="api-item">
          <div class="api-text">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">
              Direct to customer approach
            </p>
          </div>
          <div class="api-text">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">
              100% Digital Onboarding through assisted model
            </p>
          </div>
          <div class="api-text">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">Instant Soft-Approval</p>
          </div>
          <div class="api-text">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">
              Rule based Underwriting
            </p>
          </div>
          <div class="api-text">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">Automated Collections</p>
          </div>
        </div>
        <video class="api-mainimg" autoplay loop muted>
          <source src="../assets/homepage/AppAPI_v01.mp4" type="video/mp4" />
        </video>
      </div>
    </div>

    <div class="investor">
      <h1 class="phead">Our Investor</h1>
      <div class="investor-main">
        <p class="invesor-p">GAIL (India) Limited</p>
      </div>
    </div>

    <div class="nbfc">
      <h1 class="phead">NBFC Partners</h1>
      <div class="nbfc-main">
        <img class="nbfc-img" @click="$router.push('/nbfc-partners')" src="../assets/nbfcpartner/HDB.svg" />
        <img class="nbfc-img" @click="$router.push('/nbfc-partners')" src="../assets/nbfcpartner/Aarvog.svg" />
        <img class="nbfc-img" @click="$router.push('/nbfc-partners')" src="../assets/nbfcpartner/Maxemo.svg" />
        <img style="width: 15%" class="nbfc-img" @click="$router.push('/nbfc-partners')"
          src="../assets/nbfcpartner/Lendtree.svg" />
      </div>
      <div class="nbfc-main">
        <img class="nbfc-img" @click="$router.push('/nbfc-partners')" src="../assets/nbfcpartner/CTLlogo.svg" />
        <img class="nbfc-img" @click="$router.push('/nbfc-partners')" src="../assets/nbfcpartner/Pincab.svg" />
        <!-- <img
          class="nbfc-img"
          @click="$router.push('/nbfc-partners')"
          src="../assets/nbfcpartner/ambit_logo.png"
        /> -->
        <img style="width: 10%" class="nbfc-img" @click="$router.push('/nbfc-partners')"
          src="../assets/nbfcpartner/Northern_Arc-Logo.png" />
        <img class="nbfc-img" @click="$router.push('/nbfc-partners')"
          src="../assets/nbfcpartner/Arthmate-final-logo.png" />
      </div>
      <div class="nbfc-main" style="display: flex; justify-content: space-evenly">
        <img class="nbfc-img" style="width: 10%" @click="$router.push('/nbfc-partners')" src="../assets/nbfcpartner/ramaiah.jpg" />
        <img class="nbfc-img" style="width: 10%" @click="$router.push('/nbfc-partners')" src="../assets/nbfcpartner/fintree.jpg" />
        <img class="nbfc-img" @click="$router.push('/nbfc-partners')" src="../assets/nbfcpartner/trimurti.png" />
      </div>
    </div>

    <div class="presence">
      <h1 class="phead">Omnichannel Presence</h1>
      <p class="pre-text">
        Credin has a distribution first approach and is the only omnichannel
        Fintech platform in India. The omnichannel approach helps us to reach
        out to the last mile creditworthy customer and to deliver the best
        Fintech experience.
      </p>
      <div class="pre-item">
        <a href="./omnichannel">
          <video style="width: 70%" autoplay loop muted>
            <source class="impect-img" src="../assets/homepage/OmnichanalChartV01.mp4" type="video/mp4" />
          </video>
        </a>
      </div>
    </div>

    <!-- <div class="ma" :class="{ displaynone: viewIcon }">
      <h1 class="phead">Media & Awards</h1>
      <div class="row">
        <div class="story-row">
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/Forbes.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/img_134603.png"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">03 June, 2020</p>
            </div>
            <p class="ma-p3">
              <b>Credin- The need of the hour fintech platform</b>
            </p>
             <p class="ma-p2">
            The happy faces you see here are of Birju Naik and Rupesh Bishnoi,
            Founders, Credin who didn't take a single day off even during the
            lockdown...
          </p> 
            <div class="ma-div">
              <a
                href="https://www.forbesindia.com/article/brand-connect/credin-the-need-of-the-hour-fintech-platform/59829/1"
              >
                <p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/TOI.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/business-man-dollar.png"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">14 May, 2021</p>
            </div>
            <p class="ma-p3">
              <b>40% of parents finding it tough to pay fees</b>
            </p>
             <p class="ma-p2">
            The survet comes ahead of the Gujarat high court's verdict on fixing
            school fees for the current academic year. It was carried out by a
            leading...
          </p> 
            <div class="ma-div">
              <a
                href=" https://timesofindia.indiatimes.com/city/ahmedabad/40-of-parents-finding-it-tough-to-pay-fees-survey/articleshow/82614001.cms"
                ><p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/ani.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/cute-girl-with-father.png"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">07 June, 2021</p>
            </div>
            <p class="ma-p3">
              <b>Credin Shiksha for parents to pay fee in installmets</b>
            </p>
             <p class="ma-p2">
            About 40% of parents face difficulties paying school fees post
            lockdown this year. As the businesses and jobs have hit hard, the
            parent community is foc...
          </p> 
            <div class="ma-div">
              <a
                href="https://www.aninews.in/news/business/business/udgam-school-for-children-and-zebar-school-for-children-offer-credin-shiksha-program-for-parents-to-pay-fee-in-installments20210607141433/"
              >
                <p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/BusinessStandard.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/BusinessStandard.jpg"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">06 july, 2021</p>
            </div>
            <p class="ma-p3">
              <b
                >Udgam School and Zebar School for Children offer 'Credin
                Shiksha Program'.
              </b>
            </p>
             <p class="ma-p2">
            Whole country is badly affected due to second wave of COVID-19 and
            still there is no clarity on when the schools will open regularly,
            parents are...
          </p> 
            <div class="ma-div">
              <a
                style="border: none"
                href="https://www.business-standard.com/content/press-releases-ani/udgam-school-for-children-and-zebar-school-for-children-offer-credin-shiksha-program-for-parents-to-pay-fee-in-installments-121060700769_1.html"
                ><p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/Dailyhunt.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/Dailyhunt.jpg"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">06 December, 2020</p>
            </div>
            <p class="ma-p3">
              <b>
                Credin Shiksha offers a 0% EMI solution to cover families'
                educational expenses
              </b>
            </p>
             <p class="ma-p2">
            Credin partners with educational institutes, preschools and K12
            schools for Credin Shiksha program marking collaboration in 8
            states, 200+...
          </p> 
            <div class="ma-div">
              <a
                style="border: none"
                href="https://m.dailyhunt.in/news/india/english/life+and+trendz-epaper-lifntr/credin+shiksha+offers+a+0+emi+solution+to+cover+families+educational+expenses-newsid-n233663562?pgs=N&pgn=0&&nsk=trending-updates-trending"
                ><p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/DivyaBhaskar.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/DivyaBhaskar.jpg"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">05 May, 2021</p>
            </div>
            <p class="ma-p3">
              <b>
                લોકડાઉન પછી 40 ટકા વાલીઓને સ્કૂલ ફી ચૂકવવામાં તકલીફ.
                 60 ટકાની હપતા
              સિસ્ટમની ઈચ્છા સાથે સ્કૂલ-સરકાર તરફથી ફીમાં ડિસ્કાઉન્ટની આશા 
              </b>
            </p>
             <p class="ma-p2">
            કોવિડ-19ના કેસો વધી રહ્યા છે અને સ્કૂલો ફરી ક્યારે નિયમિતપણે ચાલુ
            થશે એ અંગે અનિશ્ચિતતાઓ પ્રવર્તી રહી છે. ત્યારે વાલીઓ નવા શૈક્ષણિક
            વર્ષ માટે સ્કૂલ...
          </p> 
            <div class="ma-div">
              <a
                style="border: none"
                href="https://www.divyabhaskar.co.in/local/gujarat/ahmedabad/news/40-per-cent-parents-have-difficulty-in-paying-school-fees-after-lockdown-128488519.html?_branch_match_id=919554956908407804&utm_campaign=128488519&utm_medium=sharing
"
                ><p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/Forbes.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/img_134603.png"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">03 June, 2020</p>
            </div>
            <p class="ma-p3">
              <b>Credin- The need of the hour fintech platform</b>
            </p>
             <p class="ma-p2">
            The happy faces you see here are of Birju Naik and Rupesh Bishnoi,
            Founders, Credin who didn't take a single day off even during the
            lockdown...
          </p> 
            <div class="ma-div">
              <a
                href="https://www.forbesindia.com/article/brand-connect/credin-the-need-of-the-hour-fintech-platform/59829/1"
              >
                <p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/TOI.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/business-man-dollar.png"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">14 May, 2021</p>
            </div>
            <p class="ma-p3">
              <b>40% of parents finding it tough to pay fees</b>
            </p>
             <p class="ma-p2">
            The survet comes ahead of the Gujarat high court's verdict on fixing
            school fees for the current academic year. It was carried out by a
            leading...
          </p> 
            <div class="ma-div">
              <a
                href=" https://timesofindia.indiatimes.com/city/ahmedabad/40-of-parents-finding-it-tough-to-pay-fees-survey/articleshow/82614001.cms"
                ><p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/ani.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/cute-girl-with-father.png"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">07 June, 2021</p>
            </div>
            <p class="ma-p3">
              <b>Credin Shiksha for parents to pay fee in installmets</b>
            </p>
             <p class="ma-p2">
            About 40% of parents face difficulties paying school fees post
            lockdown this year. As the businesses and jobs have hit hard, the
            parent community is foc...
          </p> 
            <div class="ma-div">
              <a
                href="https://www.aninews.in/news/business/business/udgam-school-for-children-and-zebar-school-for-children-offer-credin-shiksha-program-for-parents-to-pay-fee-in-installments20210607141433/"
              >
                <p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/BusinessStandard.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/BusinessStandard.jpg"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">06 july, 2021</p>
            </div>
            <p class="ma-p3">
              <b
                >Udgam School and Zebar School for Children offer 'Credin
                Shiksha Program'.
              </b>
            </p>
             <p class="ma-p2">
            Whole country is badly affected due to second wave of COVID-19 and
            still there is no clarity on when the schools will open regularly,
            parents are...
          </p> 
            <div class="ma-div">
              <a
                style="border: none"
                href="https://www.business-standard.com/content/press-releases-ani/udgam-school-for-children-and-zebar-school-for-children-offer-credin-shiksha-program-for-parents-to-pay-fee-in-installments-121060700769_1.html"
                ><p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/Dailyhunt.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/Dailyhunt.jpg"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">06 December, 2020</p>
            </div>
            <p class="ma-p3">
              <b>
                Credin Shiksha offers a 0% EMI solution to cover families'
                educational expenses
              </b>
            </p>
             <p class="ma-p2">
            Credin partners with educational institutes, preschools and K12
            schools for Credin Shiksha program marking collaboration in 8
            states, 200+...
          </p> 
            <div class="ma-div">
              <a
                style="border: none"
                href="https://m.dailyhunt.in/news/india/english/life+and+trendz-epaper-lifntr/credin+shiksha+offers+a+0+emi+solution+to+cover+families+educational+expenses-newsid-n233663562?pgs=N&pgn=0&&nsk=trending-updates-trending"
                ><p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/DivyaBhaskar.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/DivyaBhaskar.jpg"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">05 May, 2021</p>
            </div>
            <p class="ma-p3">
              <b>
                લોકડાઉન પછી 40 ટકા વાલીઓને સ્કૂલ ફી ચૂકવવામાં તકલીફ.
                 60 ટકાની હપતા
              સિસ્ટમની ઈચ્છા સાથે સ્કૂલ-સરકાર તરફથી ફીમાં ડિસ્કાઉન્ટની આશા 
              </b>
            </p>
             <p class="ma-p2">
            કોવિડ-19ના કેસો વધી રહ્યા છે અને સ્કૂલો ફરી ક્યારે નિયમિતપણે ચાલુ
            થશે એ અંગે અનિશ્ચિતતાઓ પ્રવર્તી રહી છે. ત્યારે વાલીઓ નવા શૈક્ષણિક
            વર્ષ માટે સ્કૂલ...
          </p> 
            <div class="ma-div">
              <a
                style="border: none"
                href="https://www.divyabhaskar.co.in/local/gujarat/ahmedabad/news/40-per-cent-parents-have-difficulty-in-paying-school-fees-after-lockdown-128488519.html?_branch_match_id=919554956908407804&utm_campaign=128488519&utm_medium=sharing
"
                ><p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/Forbes.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/img_134603.png"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">03 June, 2020</p>
            </div>
            <p class="ma-p3">
              <b>Credin- The need of the hour fintech platform</b>
            </p>
             <p class="ma-p2">
            The happy faces you see here are of Birju Naik and Rupesh Bishnoi,
            Founders, Credin who didn't take a single day off even during the
            lockdown...
          </p> 
            <div class="ma-div">
              <a
                href="https://www.forbesindia.com/article/brand-connect/credin-the-need-of-the-hour-fintech-platform/59829/1"
              >
                <p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/TOI.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/business-man-dollar.png"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">14 May, 2021</p>
            </div>
            <p class="ma-p3">
              <b>40% of parents finding it tough to pay fees</b>
            </p>
             <p class="ma-p2">
            The survet comes ahead of the Gujarat high court's verdict on fixing
            school fees for the current academic year. It was carried out by a
            leading...
          </p> 
            <div class="ma-div">
              <a
                href=" https://timesofindia.indiatimes.com/city/ahmedabad/40-of-parents-finding-it-tough-to-pay-fees-survey/articleshow/82614001.cms"
                ><p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/ani.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/cute-girl-with-father.png"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">07 June, 2021</p>
            </div>
            <p class="ma-p3">
              <b>Credin Shiksha for parents to pay fee in installmets</b>
            </p>
             <p class="ma-p2">
            About 40% of parents face difficulties paying school fees post
            lockdown this year. As the businesses and jobs have hit hard, the
            parent community is foc...
          </p> 
            <div class="ma-div">
              <a
                href="https://www.aninews.in/news/business/business/udgam-school-for-children-and-zebar-school-for-children-offer-credin-shiksha-program-for-parents-to-pay-fee-in-installments20210607141433/"
              >
                <p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/BusinessStandard.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/BusinessStandard.jpg"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">06 july, 2021</p>
            </div>
            <p class="ma-p3">
              <b
                >Udgam School and Zebar School for Children offer 'Credin
                Shiksha Program'.
              </b>
            </p>
             <p class="ma-p2">
            Whole country is badly affected due to second wave of COVID-19 and
            still there is no clarity on when the schools will open regularly,
            parents are...
          </p> 
            <div class="ma-div">
              <a
                style="border: none"
                href="https://www.business-standard.com/content/press-releases-ani/udgam-school-for-children-and-zebar-school-for-children-offer-credin-shiksha-program-for-parents-to-pay-fee-in-installments-121060700769_1.html"
                ><p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/Dailyhunt.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/Dailyhunt.jpg"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">06 December, 2020</p>
            </div>
            <p class="ma-p3">
              <b>
                Credin Shiksha offers a 0% EMI solution to cover families'
                educational expenses
              </b>
            </p>
             <p class="ma-p2">
            Credin partners with educational institutes, preschools and K12
            schools for Credin Shiksha program marking collaboration in 8
            states, 200+...
          </p> 
            <div class="ma-div">
              <a
                style="border: none"
                href="https://m.dailyhunt.in/news/india/english/life+and+trendz-epaper-lifntr/credin+shiksha+offers+a+0+emi+solution+to+cover+families+educational+expenses-newsid-n233663562?pgs=N&pgn=0&&nsk=trending-updates-trending"
                ><p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
          <div class="ma-item">
            <div class="ma-img">
              <img
                class="ma-img1"
                src="../assets/img/DivyaBhaskar.svg"
                alt="forbes.svg"
              />
              <img
                class="ma-media-img"
                src="../assets/img/DivyaBhaskar.jpg"
                alt="credin.svg"
              />
            </div>
            <div class="ma-three">
              <img src="../assets/img/watch.svg" alt="credin.svg" />
              <p class="ma-p1">05 May, 2021</p>
            </div>
            <p class="ma-p3">
              <b>
                લોકડાઉન પછી 40 ટકા વાલીઓને સ્કૂલ ફી ચૂકવવામાં તકલીફ.
                 60 ટકાની હપતા
              સિસ્ટમની ઈચ્છા સાથે સ્કૂલ-સરકાર તરફથી ફીમાં ડિસ્કાઉન્ટની આશા 
              </b>
            </p>
             <p class="ma-p2">
            કોવિડ-19ના કેસો વધી રહ્યા છે અને સ્કૂલો ફરી ક્યારે નિયમિતપણે ચાલુ
            થશે એ અંગે અનિશ્ચિતતાઓ પ્રવર્તી રહી છે. ત્યારે વાલીઓ નવા શૈક્ષણિક
            વર્ષ માટે સ્કૂલ...
          </p> 
            <div class="ma-div">
              <a
                style="border: none"
                href="https://www.divyabhaskar.co.in/local/gujarat/ahmedabad/news/40-per-cent-parents-have-difficulty-in-paying-school-fees-after-lockdown-128488519.html?_branch_match_id=919554956908407804&utm_campaign=128488519&utm_medium=sharing
"
                ><p>Learn More <i class="fa fa-angle-right"></i></p
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="enable">
      <div class="container">
        <h1 class="phead">Enabling Lives</h1>
        <div class="row">
          <div class="enable-row">
            <div class="row2">
              <div class="enable-item">
                <div class="cases">25K+</div>
                <p class="enable-p2">
                  Registered<br />
                  User
                </p>
              </div>

              <div class="enable-item">
                <div class="customer">₹50 Cr+</div>
                <p class="enable-p2">Disbursement <br />till Date</p>
              </div>
            </div>
            <div class="row2">
              <div class="enable-item">
                <div class="amount">₹75K+</div>
                <p class="enable-p2">Average <br />Ticket Size</p>
              </div>
              <div class="enable-item">
                <div class="team">₹200 Cr+</div>
                <p class="enable-p2">
                  Loan<br />
                  Processed
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-main">
      <div class="footer">
        <div style="padding: 0 10%" class="row-footer">
          <div class="row2-footer" id="row2">
            <div class="sub">
              <img class="img" src="../assets/img/Credinlogo.svg" alt="credin" @click="$router.push('/home')"
                style="cursor: pointer" />
              <p style="cursor: pointer; font-weight: bold; margin-top: 0" @click="$router.push('/contact-us')">
                Contact Us
              </p>
              <p>+919879015554</p>
              <p><a class="foota" href="help@credin.in">help@credin.in</a></p>
              <div style="display: flex; margin-top: 3vh">
                <a href="https://www.facebook.com/credin25" class="mn1" style="padding: 0px 10px 8px 0">
                  <img src="../assets/Fb.svg" /></a>
                <a href="https://www.instagram.com/credinindia/" class="mn1"><img src="../assets/Insta.svg" /></a>
                <a href="https://twitter.com/home-" class="mn1"><img src="../assets/Twitter.svg" /></a>
                <a href="https://www.youtube.com/channel/UC-H2CRfZIdPDsqk_JrfPRtw" class="mn1"><img
                    src="../assets/YouTube.svg" /></a>
                <a href="https://in.linkedin.com/company/upkram" class="mn1"><img src="../assets/Linkdin.svg" /></a>
              </div>
            </div>
            <div class="sub">
              <p style="cursor: pointer" @click="$router.push('/omnichannel')">
                Omni Channel
              </p>
              <p style="cursor: pointer" @click="$router.push('/nbfc-partners')">
                NBFC Partners
              </p>
              <p style="cursor: pointer" @click="$router.push('/faq')">FAQs</p>
              <p style="cursor: pointer" @click="$router.push('/privacy-policy')">
                Privacy policy
              </p>
              <p style="cursor: pointer" @click="$router.push('/terms-conditions')">
                Terms & Conditions
              </p>
            </div>
            <div class="sub" style="border-bottom: none">
              <p style="font-weight: bold">Registered office</p>
              <p style="font-size: 14px; font-weight: bold; color: #034ea2">
                Upkram Technologies Pvt. Ltd.
              </p>
              <p>3-4,3rd Floor, Riyaplex,</p>
              <p>80 Urmi Society, BPC Road,</p>
              <p>Vadodara, 390 020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <p>All right reserved &#169; Upkram Technologies Pvt. Ltd. @{{ new Date().getFullYear() }}</p>
  </div>
</template>

<script>
import img1 from '../assets/homepage/New2023/dec-03.jpg'
import img2 from '../assets/homepage/New2023/dec-02.jpg'
import img4 from '../assets/homepage/New2023/dec-04.jpg'

export default {
  data() {
    return {
      //Header
      viewIcon: false,
      images: [
        img2,
        img1,
        img4
      ],
      currentIndex: 0
    };
  },

  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    },
  },

  async created() {
    window.scrollTo(0, 0);
    setInterval(this.changeImage, 5000);
  },

  methods: {
    changeImage() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },

    displayHeader() {
      this.viewIcon = !this.viewIcon;
      console.log(this.viewIcon);
      var x = document.getElementById("myTopnav");
      if (x.className === "header-one") {
        x.className += " responsive";
      } else {
        x.className = "header-one";
      }
      var y = document.getElementById("myTopnavOne");
      if (y.className === "header") {
        y.className += "responsive-one";
      } else {
        y.className = "header";
      }
      var z = document.getElementById("myTopnavTwo");
      if (z.className === "credin-logo") {
        z.className += "responsive-two";
      } else {
        z.className = "credin-logo";
      }
    },
    goToWebsite() {
      window.location.href = "https://play.google.com/store/apps/details?id=com.credin.app";
    },
  },
};
</script>

<!-- @format -->

<style scoped>
  /* header Start*/

  .displaynone {
    display: none;
  }

  .blurBackground {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1% 6%;
    box-shadow: 0 4px 2px -2px #4141415a;
    top: 0;
    background: #ffffff;
    z-index: 1;
    position: sticky;
  }

  .header-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  #current {
    color: #034ea2;
  }

  #current-one {
    text-decoration: underline;
  }

  .header-one p {
    font-size: 17px;
    cursor: pointer;
  }

  .header-one button {
    height: 6vh;
    width: 9vw;
    border: none;
    background-color: #03931b;
    color: #ffffff;
    font-size: 13px;
    border-radius: 7px;
    cursor: pointer;
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 600px) {
    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      width: 10vw;
      text-align: center;
    }

    .header-one img {
      display: none;
    }

    .applynow img {
      width: 40%;
    }

    .mail {
      color: #034ea2;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1% 8%;
      box-shadow: 0 4px 2px -2px #4141415a;
      top: 0;
      background: #ffffff;
      z-index: 1;
      position: sticky;
    }

    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      text-align: center;
    }

    .header-one button,
    .header-one p {
      display: none;
    }

    .header-one {
      display: block;
      width: 30vw;
    }

    .header-one img {
      float: right;
      right: 0;
      display: block;
    }

    .header.responsive-one {
      background-color: #1f2934;
    }

    .credin-logo.responsive-two {
      display: none;
    }

    .header-one.responsive {
      width: 75%;
      margin-left: 25%;
      background-color: #ffffff;
      color: #000000;
      position: fixed;
      height: 100%;
      z-index: 1;
    }

    .header-one.responsive img {
      position: absolute;
      margin-right: 50%;
      margin-top: 25px;
    }

    .header-one.responsive p {
      float: none;
      display: block;
      text-align: center;
      margin-top: 25px;
      font-size: 20px;
      font-weight: 400;
      margin: 0;
      margin-top: 25px;
      width: 90%;
    }
  }

  /* header end */
  * {
    font-family: "Lato", sans-serif;
  }

  body {
    width: auto;
    display: grid;
    overflow-x: hidden;
  }

  .bg1 {
    background-color: #ffffff;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  .phead {
    text-align: center;
    color: #034ea2;
  }

  .review-arrow {
    display: flex;
    padding-left: 20px;
    width: auto;
    height: 35px;
    margin: 15px 10px;
  }
  .leftarrow {
    background-image: url("../assets/img/LeftArrowGrey.svg");
    width: 30px;
    background-repeat: no-repeat;
  }
  .leftarrow:hover {
    background-image: url("../assets/img/LeftArrowBlack.svg");
    width: 30px;
    background-repeat: no-repeat;
  }
  .rightarrow {
    background-image: url("../assets/img/RightArrowGrey.svg");
    width: 50px;
    background-repeat: no-repeat;
    border: none;
  }
  .rightarrow:hover {
    background-image: url("../assets/img/RightArrowBlack.svg");
    width: 50px;
    background-repeat: no-repeat;
  }

  /* text animation end */

  /* Slideshow container */
  .slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
  }

  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }

  /* The dots/bullets/indicators */
  .dot {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }

  .active {
    background-color: #717171;
  }

  /* Fading animation */
  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  /* On smaller screens, decrease text size */
  @media only screen and (max-width: 300px) {
    .text {
      font-size: 11px;
    }
  }

  @media screen and (min-width: 650px) {
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .review-row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .colunm1 {
      display: flex;
      flex-direction: column;
      margin: 10px;
      align-items: flex-start;
    }

    .navbar {
      margin-left: 115px;
      color: pink;
      text-decoration: none;
      /* padding: 20px; */
    }

    /* .img {
      width: 100px;
      height: 75px;
      margin-right: 60px;
    } */

    .navnav {
      float: right;
      display: flex;
      margin-right: 145px;
    }

    .btn {
      margin-left: 50px;
      margin-right: 50px;
      margin-top: 5px;
      /* padding: 10px 28px 10px 28px; */
      background-color: #00944a;
      color: #ffffff;
      font-size: 20px;
      border: none;
      border-radius: 3px;
      font-weight: 600;
      width: 120px;
      text-align: center;
      height: 44px;
    }

    #in {
      margin-left: 230px;
      margin-right: 60px;
    }

    #in1 {
      margin-right: 200px;
      margin-left: 60px;
    }

    #box-item {
      /* width: 800px; */
      height: 300px;
      margin-left: 20px;
      margin-right: 20px;
    }

    .text {
      margin: 20px;
      text-align: center;
      color: #000000;
      font-style: bold;
    }

    /********About CSS *********/

    .about {
      /* padding: 20px 0; */
      margin: 0 10%;
    }

    .about-p {
      line-height: 1.6;
      font-size: 18px;
      text-align: center;
    }

    #about-txt {
      text-align: center;
    }
    .about-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    /********About CSS *********/

    .highlight {
      margin: 0 10%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .highlight-p {
      /* line-height: 1.6; */
      /* margin-left: 40px; */
      align-items: center;
      display: flex;
      font-size: 18px;
    }

    .highlight-btn {
      background-color: #034ea2;
      color: #ffffff;
      width: 110px;
      height: 36px;
      font-size: 17px;
      border: none;
      border-radius: 4px;
      margin-left: 45px;
      margin-top: 10px;
    }

    .high-head {
      margin: 20px 0px 10px 0px;
      display: flex;
      flex-direction: initial;
    }
    .highlight-gif {
      width: 25vw;
    }

    .highlight-img {
      margin: 0px 20px 3px 0px;
      animation: animate 2s linear infinite;
    }

    @keyframes animate {
      0% {
        opacity: 0;
      }

      25% {
        opacity: 0.7;
      }

      50% {
        opacity: 1;
      }

      75% {
        opacity: 0.7;
      }

      100% {
        opacity: 0;
      }
    }

    /*******FAQ CSS *******/

    .faq-one {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .faq-three {
      border: 2px solid #034ea2;
      border-radius: 6px;
      width: 22vw;
      padding: 1vw;
      cursor: pointer;
      color: #1f2934;
      margin: 3vh 0vh;
    }
    .faq-four h3 {
      width: 20vw;
      font-size: 16px;
      line-height: 1.6;
    }

    .faq-five p {
      font-size: 17px;
      color: gray;
      text-align: left;
      margin-top: 2vh;
    }

    .eli-btn-one {
      background-color: #034ea2;
      color: #ffffff;
      width: 300px;
      height: 50px;
      font-size: 25px;
      border: none;
      border-radius: 5px;
    }

    .elibtn-main {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    /********Review CSS *********/

    .item {
      background: #ffffff;
      text-align: center;
      margin-bottom: 30px;
      height: auto;
      padding: 15px 30px 5px 0px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    .review-text {
      margin: 15px 10px;
      font-size: 18px;
      line-height: 2.3;
      text-align: left;
      padding-left: 20px;
      height: 200px;
    }

    .review-img {
      background-image: url("../assets/product/Aarambh02.jpg");
      width: 335px;
      height: 400px;
      border-radius: 20px;
    }

    .review-img1 {
      background-image: url("../assets/product/Aarambh01.jpg");
      width: 335px;
      height: 400px;
      border-radius: 20px;
    }

    .review-img2 {
      background-image: url("../assets/product/Aarambh03.jpg");
      width: 335px;
      height: 400px;
      border-radius: 20px;
    }

    /*********FAQ CSS *********/

    .home-faq-one {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .home-faq-three {
      border: 2px solid #034ea2;
      border-radius: 6px;
      width: 35vw;
      padding: 0.5vw;
      cursor: pointer;
      color: #1f2934;
      margin: 4vh 0vh 2vh 0vh;
    }
  }

  @media screen and (max-width: 650px) {
    .nav {
      display: none;
    }

    .navbar {
      margin-right: 50px;
      color: #000000;
      text-decoration: none;
      /* padding: 20px; */
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .navnav {
      float: right;
      display: flex;
      margin-left: 50px;
    }

    .btn {
      margin-left: 40px;
      margin-right: 20px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #00944a;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }

    .colunm {
      display: flex;
      flex-direction: row;
    }

    .colunm1 {
      display: flex;
      flex-direction: column;
      margin: 10px;
    }

    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .review-row {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #in,
    #in1,
    #box-item {
      margin-left: 10px;
      margin-right: 10px;
    }

    .text {
      margin: 20px 0;
      text-align: center;
      color: #000000;
      font-style: bold;
    }

    /********About CSS *********/

    .about {
      margin: 0 10%;
    }

    .about-p {
      line-height: 1.6;
      text-align: center;
      font-size: 17px;
    }

    .about-box {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    /********Highlight CSS *********/

    .highlight {
      /* padding: 20px; */
      margin: 0 10%;
    }

    .highlight-p {
      /* line-height: 1.6; */
      /* text-align: center; */
      align-items: center;
      display: flex;
      font-size: 20px;
      margin-left: 50px;
      display: flex;
      margin: 15px 0px 15px 0px;
    }
    .highlight-gif {
      width: 100%;
    }

    .highlight-img {
      margin: 0px 20px 3px 0px;
      animation: animate 2s linear infinite;
    }

    @keyframes animate {
      0% {
        opacity: 0;
      }

      25% {
        opacity: 0.7;
      }

      50% {
        opacity: 1;
      }

      75% {
        opacity: 0.7;
      }

      100% {
        opacity: 0;
      }
    }

    .highlight-btn {
      background-color: #034ea2;
      color: #ffffff;
      width: 110px;
      height: 36px;
      font-size: 17px;
      border: none;
      border-radius: 4px;
      margin-left: 45px;
    }

    /*******FAQ CSS *******/

    .faq-one {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .faq-three {
      border: 2px solid #034ea2;
      border-radius: 6px;
      padding: 1vw;
      cursor: pointer;
      color: #1f2934;
      margin: 2vh 0;
    }

    .faq-four h3 {
      /* width: 20vw; */
      font-size: 16px;
      line-height: 1.6;
    }

    .faq-five p {
      font-size: 17px;
      color: gray;
      text-align: justify;
      margin-top: 2vh;
    }

    .elibtn-main {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .eli-btn-one {
      background-color: #034ea2;
      color: #ffffff;
      width: 300px;
      height: 50px;
      font-size: 25px;
      border: none;
      border-radius: 5px;
      margin-bottom: 15px;
    }

    /********Review CSS *********/

    .item {
      background: #ffffff;
      text-align: center;
      margin-bottom: 30px;
      height: auto;
      width: 340px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .review-text {
      font-size: 18px;
      line-height: 2.3;
      text-align: left;
    }

    .review-img {
      background-image: url("../assets/product/Aarambh02.jpg");
      width: 330px;
      height: 430px;
      border-radius: 20px;
    }

    .review-img1 {
      background-image: url("../assets/product/Aarambh01.jpg");
      width: 330px;
      height: 430px;
      border-radius: 20px;
    }

    .review-img2 {
      background-image: url("../assets/product/Aarambh03.jpg");
      width: 330px;
      height: 430px;
      border-radius: 20px;
    }

    /*********FAQ CSS *********/

    .home-faq-one {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .home-faq-three {
      border: 2px solid #034ea2;
      border-radius: 6px;
      padding: 1vw;
      cursor: pointer;
      color: #1f2934;
      margin: 2vh 0vh;
    }
  }

  @media (max-width: 800px) and (min-width: 650px) {
    .row {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .review-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .item {
      background: #ffffff;
      text-align: center;
      margin-bottom: 30px;
      height: auto;
      padding: 25px 5px;
      border-radius: 4px;
    }
  }

  @media (max-width: 1100px) and (min-width: 800px) {
    .sub {
      margin-left: 60px;
      margin-right: 60px;
      text-align: left;
      font-size: 22px;
    }

    .review-row {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    #row2 {
      padding: 5px;
    }
  }

  /********** Nav Bar CSS **********/

  .part {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 2px -2px rgb(130, 131, 134);
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5% 2.5%;
    cursor: pointer;
    z-index: 1;
  }

  .img {
    width: 125px;
    height: 75px;
  }

  .dropdown {
    float: left;
    overflow: hidden;
  }

  .dropdown .dropbtn {
    font-size: 17px;
    border: none;
    color: #034ea2;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }

  .dropdown:hover .dropbtn:hover {
    background-color: #ffffff;
    color: #034ea2;
    border-radius: 4px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: #000000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    color: #034ea2;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    color: #034ea2;
  }

  .navbarsmap:hover {
    border-bottom: 2px solid #034ea2;
  }

  #current-span {
    border-bottom: 1.5px solid #034ea2;
  }

  .menu:hover {
    color: #034ea2;
  }

  .menu:focus {
    background-color: #e6e6e6;
  }

  /****** India CSS******/

  .img-main {
    display: flex;
  }

  .img2 {
    margin: auto;
    width: 100%;
    /* height: 600px; */
  }

  .ptext {
    margin: 0px;
    text-align: center;
    font-size: 30px;
    color: #034ea2;
  }

  /*********Highlight css ********/

  .highlight-btn:hover {
    color: #034ea2;
    background-color: #ffffff;
    border: 2px solid #034ea2;
    height: 36px;
    font-size: 17px;
    border-radius: 4px;
    margin-left: 45px;
    margin-top: 10px;
  }
  /******* Eligibily CSS*******/

  .eligibility {
    margin: 0 10%;
    margin-bottom: 30px;
  }
  .contactus-faq {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 2px solid #034ea2;
    border-radius: 6px;
    padding: 1% 4%;
    margin: 0 28%;
    font-size: 17px;
    color: gray;
  }

  .contactus-faq p {
    cursor: pointer;
  }

  .home-faq {
    margin: 2% 0% 1% 0%;
  }

  .home-faq h1 {
    font-size: 40px;
    color: #1f2934;
    margin-top: 2vh;
    text-align: center;
  }

  .home-faq-four {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 3px;
  }

  .home-faq-four h3 {
    /* width: 20vw; */
    font-size: 18px;
  }

  .home-faq-five p {
    font-size: 18px;
    color: gray;
    text-align: justify;
    margin-top: 2vh;
  }

  .new1 {
    border-top: 1px solid gray;
    margin-top: 2vh;
  }

  .contactus-faq {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 2px solid #034ea2;
    border-radius: 6px;
    padding: 1% 4%;
    margin: 0 28%;
    font-size: 17px;
    color: gray;
  }

  .eli-btn {
    /* margin: 0% 10%; */
    text-align: center;
  }

  .eli-btn-one:hover {
    border: 2px solid;
    background-color: #ffffff;
    color: #034ea2;
    width: 300px;
    height: 50px;
    font-size: 25px;
    border-radius: 5px;
  }

  /********Review CSS *********/

  .review-box {
    width: auto;
    margin: 0 10%;
  }

  .review-icon1 {
    display: flex;
    float: initial;
  }

  .review-icon2 {
    display: flex;
    float: right;
  }

  /* .review-img {
    width: 400px;
    height: 480px;
    border-radius: 20px;
  } */

  .review-name {
    margin: 40px 10px 15px 10px;
    padding: 5px 20px;
    text-align: left;
    color: #034ea2;
    font-weight: 600;
  }
  .review-small {
    margin: 15px 10px;
    padding: 5px 20px;
    text-align: left;
    color: black;
    font-size: 16px;
    font-weight: 600;
  }

  /*******FAQ CSS*******/

  .faq {
    margin: 0 10%;
    /* margin-bottom: 30px; */
  }
  .contactus-faq {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 2px solid #034ea2;
    border-radius: 6px;
    padding: 1% 4%;
    margin: 0 28%;
    font-size: 17px;
    color: gray;
  }

  .contactus-faq p {
    cursor: pointer;
  }

  .faq-four {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 3px;
  }

  .new1 {
    border-top: 1px solid gray;
    margin-top: 2vh;
  }

  .contactus-faq {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 2px solid #034ea2;
    border-radius: 6px;
    padding: 1% 4%;
    margin: 0 28%;
    font-size: 17px;
    color: gray;
  }

  .faq-btn {
    background-color: #034ea2;
    color: #ffffff;
    width: 110px;
    border: none;
    height: 36px;
    font-size: 17px;
    border-radius: 4px;
  }
  .faq-btn:hover {
    color: #034ea2;
    background-color: #ffffff;
    width: 110px;
    height: 36px;
    font-size: 17px;
    border: 2px solid #034ea2;
    border-radius: 4px;
  }
  /********* Footer CSS **********/

  .sub p {
    margin: 0;
    margin-top: 2.5vh;
  }

  @media (min-width: 650px) {
    .about-pbox {
      line-height: 1.6;
      font-size: 18px;
      border: 2px solid #034ea2;
      padding: 15px 20px;
      border-radius: 4px;
      width: 90%;
      display: flex;
      align-items: center;
      margin: 10px;
    }

    .about-pbox:hover {
      line-height: 1.6;
      font-size: 18px;
      color: #ffffff;
      background-color: #034ea2;
      padding: 15px 20px;
      width: 90%;
      margin: 10px;
    }
    /********FOOTER CSS ********/
    .footer-main {
      height: 350px;
    }

    .footer {
      font-size: 14px;
      background: url("../assets/footer/WebsiteFooter.gif");
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #000000;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom;
    }

    .row2-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 350px;
      width: 100%;
    }

    .foota {
      color: #000000;
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .sub {
      display: flex;
      flex-direction: column;
      margin: 0 25px;
      text-align: left;
      font-size: 18px;
      width: 100%;
    }

    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #fff;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      background-color: #034da2;
    }
  }

  @media (max-width: 650px) {
    /*******About CSS *******/

    .about-pbox {
      line-height: 1.6;
      font-size: 18px;
      border: 2px solid #034ea2;
      padding: 15px 20px;
      border-radius: 4px;
      width: 90%;
      display: flex;
      align-items: center;
      margin: 10px;
    }

    .about-pbox:hover {
      line-height: 1.6;
      font-size: 18px;
      color: #ffffff;
      background-color: #034ea2;
      padding: 15px 20px;
      width: 90%;
      margin: 10px;
    }

    .footer {
      background-color: #034ea2;
      font-size: 14px;
      background-size: contain;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #fff;
      padding-top: 3vh;
    }

    .row-footer {
      display: flex;
      flex-direction: column;
    }

    .row2-footer {
      display: flex;
      flex-direction: column;
    }

    .foota {
      color: #ffffff;
      margin-top: 1em;
    }

    .img {
      display: none;
    }

    .sub {
      font-size: 16px;
      margin-top: 1vh;
      padding-bottom: 1vh;
      border-bottom: 2px solid white;
    }
    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #034da2;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      background-color: white;
    }
  }

  .foota {
    margin-bottom: 5px;
    text-decoration: none;
  }

  /* footer end */

  .mn1 {
    align-items: center;
    justify-content: center;
    padding: 0px 6px 8px 6px;
    margin: 1px;
    color: #ffffff;
    text-decoration: none;
  }

  .copytop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 40px;
    background-color: #6c6c6d;
    border-top: 2px solid #ffffff;
  }
</style>

<template>
  <div class="header" id="myTopnavOne">
    <img
      v-if="!viewIcon"
      src="../assets/CREDINLOGO.svg"
      class="credin-logo"
      id="myTopnavTwo"
      alt="logo.svg"
      height="70"
      width="140"
      @click="$router.push('/home')"
      style="cursor: pointer"
    />
    <div class="header-one" id="myTopnav">
      <p class="menu" @click="$router.push('/home')">
        <span class="navbarsmap">Ho</span>me
      </p>
      <div v-if="!viewIcon">
        <div class="menu">
          <div class="dropdown">
            <button class="dropbtn">
              <span id="current-span">Aar</span>ambh
            </button>
            <div class="dropdown-content">
              <!-- <a @click="$router.push('/pratham')">Pratham</a> -->
              <a href="/aarambh">Aarambh</a>
              <!-- <a href="/shiksha">Shiksha</a> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- <p class="menu" @click="$router.push('/pratham')">Pratham</p> -->
        <p class="menu" @click="$router.push('/aarambh')">Aarambh</p>
        <!-- <p class="menu" @click="$router.push('/shiksha')">Shiksha</p> -->
      </div>
      <p class="menu" @click="$router.push('/ourjourney')">
        <span class="navbarsmap">Our</span> Journey
      </p>
      <div class="menu">
        <button style="height: 6vh; margin-left: 1vw" @click="goToWebsite">
          Download App
        </button>
      </div>
      <img
        @click="displayHeader"
        src="../assets/menu-hor.svg"
        alt="menu-bar.svg"
        style="height: 2vh"
        v-if="!viewIcon"
      />
      <img
        @click="displayHeader"
        src="../assets/Close1.svg"
        alt="menu-bar.svg"
        style="height: 4vh"
        v-else
      />
    </div>
  </div>

  <div :class="{ blurBackground: viewIcon }">
    <div class="india">
      <div class="img-main">
        <video class="img2" autoplay loop muted>
          <source
            src="../assets/arambhpage/CredinCollage_Aarambh.mp4"
            type="video/mp4"
          />
        </video>
      </div>

      <div class="text">
        <h2>Ab credin aaramabh ke sang, aap ki rukavate hogi kam.</h2>
      </div>
    </div>

    <div class="about">
      <div class="phead">
        <img id="about-txt" src="../assets/img/Aarambh.svg" />
        <h1>Business Loan</h1>
      </div>

      <p Class="about-p">
        Credin Aarambh, is here to create true financial inclusion through easy
        access to affordable credit for all the businesses under-served by
        Banks.
      </p>
      <div class="about-box">
        <p Class="about-pbox">
          Inclusive | <br />Covers all the business segments.
        </p>
        <p Class="about-pbox">
          Convenient |<br />
          Get the service at your doorstep.
        </p>
        <p Class="about-pbox">Transparent | <br />No Hidden Charges.</p>
      </div>
    </div>

    <div style="text-align: center">
      <h1 class="phead">Highlights of the Product</h1>
    </div>
    <div class="highlight">
      <div>
        <div>
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">No ITR Required.</p>
          </div>
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">Pan India coverage.</p>
          </div>
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p style="margin: 12px 0px">Credit for all your business needs.</p>
          </div>

          <div v-if="highlight">
            <div class="highlight-p">
              <img class="highlight-img" src="../assets/img/Check.svg" />
              <p style="margin: 12px 0px">
                Unsecured Business Loan for Small/ Medium Businesses.
              </p>
            </div>
            <div class="highlight-p">
              <img class="highlight-img" src="../assets/img/Check.svg" />
              <p style="margin: 12px 0px">
                Loan against property (Green Mortgages).
              </p>
            </div>
            <div class="highlight-p">
              <img class="highlight-img" src="../assets/img/Check.svg" />
              <p style="margin: 12px 0px">
                Unsecured Credit for dairy farmers.
              </p>
            </div>
            <div class="highlight-p">
              <img class="highlight-img" src="../assets/img/Check.svg" />
              <p style="margin: 12px 0px">
                Agri commodity supply chain finance.
              </p>
            </div>
          </div>
          <button @click="knowMore" class="highlight-btn">Know more</button>
        </div>
      </div>
      <div>
        <img
          class="highlight-gif"
          src="../assets/arambhpage/AarambhSlider.gif"
        />
      </div>
    </div>

    <div class="eligibility">
      <h1 class="phead">Eligibility Criteria</h1>
      <div v-if="generalFaq">
        <div class="faq-one">
          <div class="faq-two">
            <div class="faq-three" @click="callPopUpOne">
              <div class="faq-four">
                <h3>Criteria for applying loan</h3>
                <img
                  v-if="!popUpOne"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="faq-five" v-if="popUpOne">
                <hr class="new1" />
                <p>Indian Citizen.</p>
                <p>Should have business set-up.</p>
                <p>No past overdue/ settled credit accounts.</p>
                <p>Must have an active bank account.</p>
                <p>Business vintage to be >= 2 years.</p>
              </div>
            </div>
          </div>

          <div class="faq-two">
            <div class="faq-three" @click="callPopUpTwo">
              <div class="faq-four">
                <h3>Target Segment</h3>
                <img
                  v-if="!popUpTwo"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="faq-five" v-if="popUpTwo">
                <hr class="new1" />
                <p>
                  Self-employed proprietor-run businesses having formal/
                  informal business set-up.
                </p>
              </div>
            </div>
          </div>

          <div class="faq-two">
            <div class="faq-three" @click="callPopUpThree">
              <div class="faq-four">
                <h3>Required Documents</h3>
                <img
                  v-if="!popUpOne"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="faq-five" v-if="popUpThree">
                <hr class="new1" />
                <p>Colour photo of the applicant.</p>
                <p>Identity Proof (Pan card).</p>
                <p>Valid current address proof.</p>
                <p>Last 6 month’s digital bank statement.</p>
                <p>Business registration proof.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="review-box">
      <h1 class="phead"><b>Customer Reviews</b></h1>
      <div id="review-main">
        <div class="review-row" v-if="contentOne">
          <div class="item" id="review-item">
            <img class="review-img1" />
          </div>
          <div class="item" id="review-item">
            <div>
              <p class="review-text">
                As I wanted to expand my business, I was considering options for
                loan, but turnaround time was more than one month. Meanwhile I
                came across Credin, as they disbursed my loan within 48 hours.
                That helped me diversify my business with great returns. I’m
                thankful &amp; grateful to Credin for delivering their promise.
              </p>
            </div>
            <div>
              <p class="review-name">- Pandya Nareshkumar Jiyabhai</p>
              <p class="review-small">- Idar</p>
              <div class="review-arrow">
                <div class="review-row">
                  <div
                    class="rightarrow"
                    style="cursor: pointer"
                    @click="movePart"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="review-row" v-if="contentTwo">
          <div class="item" id="review-item">
            <img class="review-img2" />
          </div>
          <div class="item" id="review-item">
            <div>
              <p class="review-text">
                I wanted to construct my house and was looking for loan, but was
                not able to receive positive reply from different financial
                institutions. As I came across Credin all my worries were put to
                end because received my loan amount within48 hours without any
                documentation or procedure hassles.
              </p>
            </div>
            <div>
              <p class="review-name">
                - Vinod Patel father of Krish and Shlok Patel,
              </p>
              <p class="review-small">- St.Josheph school, Vijapur</p>
              <div class="review-arrow">
                <div class="review-row">
                  <div
                    class="rightarrow"
                    style="cursor: pointer"
                    @click="movePart"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="faq">
      <h1 class="phead">FAQ</h1>
      <div v-if="generalFaq" class="home-faq">
        <div class="home-faq-one">
          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUpOne">
              <div class="home-faq-four">
                <h3>What are the Aarambh Loan Terms?</h3>
                <img
                  v-if="!popUpOne"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpOne">
                <hr class="new1" />
                <p>Max. Loan amount up to 40 Lacs</p>
                <p>Max. Tenure 36 months</p>
                <p>Fix interest rate of up to 1.5% per month</p>
                <p>Processing fees up to 3.99% + GST</p>
                <p>
                  The loan amount will be credited within 48 hours post
                  sanctioning of the loan
                </p>
                <p>EMI will be deducted from the applicant's bank account</p>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpFour">
              <div class="home-faq-four">
                <h3>Who provides the Loan?</h3>
                <img
                  v-if="!popUpFour"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpFour">
                <hr class="new1" />
                <p>
                  Credin is a DPIIT, Govt of India recognised Financial
                  Technology company and loans are approved and disbursed by
                  Credin’s partner NBFCs, regulated by RBI.
                </p>
              </div>
            </div>
          </div>

          <div class="home-faq-two">
            <div class="home-faq-three" @click="callPopUpTwo">
              <div class="home-faq-four">
                <h3>Does it require any collateral?</h3>
                <img
                  v-if="!popUpTwo"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpTwo">
                <hr class="new1" />
                <p>
                  We have both programs. Collateral backed up to 40 Lacs and
                  Collateral Free up to 5 Lacs. We do loans against property in
                  a collateral-backed program. Under which we cover liquid
                  income and assess income program.
                </p>
              </div>
            </div>
            <div class="home-faq-three" @click="callPopUpFive">
              <div class="home-faq-four">
                <h3>Am I eligible for a loan without a credit history?</h3>
                <img
                  v-if="!popUpFive"
                  src="../assets/homepage/Navigationicon.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
                <img
                  v-else
                  src="../assets/homepage/Upperarrow.svg"
                  class="v-arrow"
                  alt="navigation.io"
                />
              </div>
              <div class="home-faq-five" v-if="popUpFive">
                <hr class="new1" />
                <p>
                  Yes, you can avail the loan without a credit history if all
                  other checks are in line with the policy Criteria.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin-bottom: 30px">
        <button
          @click="$router.push('/faq')"
          style="cursor: pointer"
          class="faq-btn"
        >
          Know More
        </button>
      </div>
    </div>

    <div class="footer-main">
      <div class="footer">
        <div style="padding: 0 10%" class="row-footer">
          <div class="row2-footer" id="row2">
            <div class="sub">
              <img
                class="img"
                src="../assets/img/Credinlogo.svg"
                alt="credin"
                @click="$router.push('/home')"
                style="cursor: pointer"
              />
              <p
                style="cursor: pointer; font-weight: bold; margin-top: 0"
                @click="$router.push('/contact-us')"
              >
                Contact Us
              </p>
              <p>+919879015554</p>
              <p><a class="foota" href="help@credin.in">help@credin.in</a></p>
              <div style="display: flex; margin-top: 3vh">
                <a
                  href="https://www.facebook.com/credin25"
                  class="mn1"
                  style="padding: 0px 10px 8px 0"
                >
                  <img src="../assets/Fb.svg"
                /></a>
                <a href="https://www.instagram.com/credinindia/" class="mn1"
                  ><img src="../assets/Insta.svg"
                /></a>
                <a href="https://twitter.com/home-" class="mn1"
                  ><img src="../assets/Twitter.svg"
                /></a>
                <a
                  href="https://www.youtube.com/channel/UC-H2CRfZIdPDsqk_JrfPRtw"
                  class="mn1"
                  ><img src="../assets/YouTube.svg"
                /></a>
                <a href="https://in.linkedin.com/company/upkram" class="mn1"
                  ><img src="../assets/Linkdin.svg"
                /></a>
              </div>
            </div>
            <div class="sub">
              <p style="cursor: pointer" @click="$router.push('/omnichannel')">
                Omni Channel
              </p>
              <p
                style="cursor: pointer"
                @click="$router.push('/nbfc-partners')"
              >
                NBFC Partners
              </p>
              <p style="cursor: pointer" @click="$router.push('/faq')">FAQs</p>
              <p
                style="cursor: pointer"
                @click="$router.push('/privacy-policy')"
              >
                Privacy policy
              </p>
              <p
                style="cursor: pointer"
                @click="$router.push('/terms-conditions')"
              >
                Terms & Conditions
              </p>
            </div>
            <div class="sub" style="border-bottom: none">
              <p style="font-weight: bold">Registered office</p>
              <p style="font-size: 14px; font-weight: bold; color: #034ea2">
                Upkram Technologies Pvt. Ltd.
              </p>
              <p>3-4,3rd Floor, Riyaplex,</p>
              <p>80 Urmi Society, BPC Road,</p>
              <p>Vadodara, 390 020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>All right reserved &#169; Upkram Technologies Pvt. Ltd. @{{ new Date().getFullYear() }}</p>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        popUp: false,
        popUpOne: false,
        popUpTwo: false,
        popUpThree: false,
        popUpFour: false,
        popUpFive: false,
        generalFaq: true,
        paymentFaq: false,
        serviceFaq: false,
        refundFaq: false,
        contactFaq: false,
        highlight: false,
        //arraow
        contentOne: true,
        contentTwo: false,
        //Header
        viewIcon: false,
      };
    },

    async created() {
      window.scrollTo(0, 0);
    },

    methods: {
      displayHeader() {
        this.viewIcon = !this.viewIcon;
        console.log(this.viewIcon);
        var x = document.getElementById("myTopnav");
        if (x.className === "header-one") {
          x.className += " responsive";
        } else {
          x.className = "header-one";
        }
        var y = document.getElementById("myTopnavOne");
        if (y.className === "header") {
          y.className += "responsive-one";
        } else {
          y.className = "header";
        }
        var z = document.getElementById("myTopnavTwo");
        if (z.className === "credin-logo") {
          z.className += "responsive-two";
        } else {
          z.className = "credin-logo";
        }
      },

      movePart() {
        this.contentOne = !this.contentOne;
        this.contentTwo = !this.contentTwo;
      },

      knowMore() {
        this.highlight = !this.highlight;
      },

      goToWhatsapp() {
        window.location.href = "https://wa.me/+919879015554";
      },

      goToWebsite() {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.credin.app";
      },

      goToMap() {
        window.location.href = "https://goo.gl/maps/rR6763JJZUVbi3bv8";
      },

      goToFacebook() {
        window.location.href = "https://www.facebook.com/credin25/";
      },

      goToTwitter() {
        window.location.href = "https://twitter.com/credin4";
      },

      goToInstagram() {
        window.location.href = "https://www.instagram.com/credinindia/?hl=en";
      },

      goToLinkdin() {
        window.location.href = "https://in.linkedin.com/company/upkram";
      },

      callPopUp() {
        this.popUp = !this.popUp;
        this.popUpOne = false;
        this.popUpTwo = false;
        this.popUpThree = false;
        this.popUpFour = false;
        this.popUpFive = false;
      },

      callPopUpOne() {
        this.popUpOne = !this.popUpOne;
        this.popUp = false;
        this.popUpTwo = false;
        this.popUpThree = false;
        this.popUpFour = false;
        this.popUpFive = false;
      },

      callPopUpTwo() {
        this.popUpTwo = !this.popUpTwo;
        this.popUpOne = false;
        this.popUp = false;
        this.popUpThree = false;
        this.popUpFour = false;
        this.popUpFive = false;
      },

      callPopUpThree() {
        this.popUpThree = !this.popUpThree;
        this.popUpOne = false;
        this.popUpTwo = false;
        this.popUp = false;
        this.popUpFour = false;
        this.popUpFive = false;
      },

      callPopUpFour() {
        this.popUpFour = !this.popUpFour;
        this.popUpOne = false;
        this.popUpTwo = false;
        this.popUpThree = false;
        this.popUp = false;
        this.popUpFive = false;
      },

      callPopUpFive() {
        this.popUpFive = !this.popUpFive;
        this.popUpOne = false;
        this.popUpTwo = false;
        this.popUpThree = false;
        this.popUpFour = false;
        this.popUp = false;
      },

      goToGeneral() {
        this.generalFaq = true;
        this.paymentFaq = false;
        this.serviceFaq = false;
        this.refundFaq = false;
        this.contactFaq = false;
      },

      goToPayment() {
        this.generalFaq = false;
        this.paymentFaq = true;
        this.serviceFaq = false;
        this.refundFaq = false;
        this.contactFaq = false;
      },

      goToServices() {
        this.paymentFaq = false;
        this.generalFaq = false;
        this.serviceFaq = true;
        this.refundFaq = false;
        this.contactFaq = false;
      },

      goToRefund() {
        this.paymentFaq = false;
        this.generalFaq = false;
        this.serviceFaq = false;
        this.refundFaq = true;
        this.contactFaq = false;
      },

      goToContact() {
        this.paymentFaq = false;
        this.generalFaq = false;
        this.serviceFaq = false;
        this.refundFaq = false;
        this.contactFaq = true;
      },
    },
  };
</script>

<!-- @format -->
<style scoped>
  /* header Start*/

  .displaynone {
    display: none;
  }

  .blurBackground {
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1% 6%;
    box-shadow: 0 4px 2px -2px #4141415a;
    top: 0;
    background: #ffffff;
    z-index: 1;
    position: sticky;
  }

  .header-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  #current {
    color: #034ea2;
  }

  #current-one {
    text-decoration: underline;
  }

  .header-one p {
    font-size: 17px;
    cursor: pointer;
  }

  .header-one button {
    height: 6vh;
    width: 9vw;
    border: none;
    background-color: #03931b;
    color: #ffffff;
    font-size: 13px;
    border-radius: 7px;
    cursor: pointer;
  }

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 600px) {
    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      width: 10vw;
      text-align: center;
    }

    .header-one img {
      display: none;
    }

    .applynow img {
      width: 40%;
    }

    .mail {
      color: #034ea2;
      font-size: 16px;
    }
  }

  @media screen and (max-width: 600px) {
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1% 8%;
      box-shadow: 0 4px 2px -2px #4141415a;
      top: 0;
      background: #ffffff;
      z-index: 1;
      position: sticky;
    }

    .menu {
      float: right;
      margin: 2px;
      text-decoration: none;
      font-size: 17px;
      text-align: center;
    }

    .header-one button,
    .header-one p {
      display: none;
    }

    .header-one {
      display: block;
      width: 30vw;
    }

    .header-one img {
      float: right;
      right: 0;
      display: block;
    }

    .header.responsive-one {
      background-color: #1f2934;
    }

    .credin-logo.responsive-two {
      display: none;
    }

    .header-one.responsive {
      width: 75%;
      margin-left: 25%;
      background-color: #ffffff;
      color: #000000;
      position: fixed;
      height: 100%;
      z-index: 1;
    }

    .header-one.responsive img {
      position: absolute;
      margin-right: 50%;
      margin-top: 25px;
    }

    .header-one.responsive p {
      float: none;
      display: block;
      text-align: center;
      margin-top: 25px;
      font-size: 20px;
      font-weight: 400;
    }

    .header-one.responsive p {
      margin: 0;
      margin-top: 25px;
      width: 90%;
    }
  }

  /* header end */

  .container {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .colunm {
    display: flex;
    flex-direction: column;
  }

  /********** Nav Bar CSS **********/

  .part {
    /* color: #000000; */
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 2px -2px rgb(130, 131, 134);
    /* border-bottom: 1px solid #034ea2; */
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5% 2.5%;
    /* margin-left: 10px;
      margin-right: 10px; */
    cursor: pointer;
  }

  .img {
    width: 125px;
    height: 75px;
  }

  .menu {
    float: right;
    margin: 2px;
    text-decoration: none;
    font-size: 17px;
  }

  .dropdown {
    float: left;
    overflow: hidden;
  }

  .dropdown .dropbtn {
    font-size: 17px;
    border: none;
    color: #000000;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }

  .dropdown:hover .dropbtn:hover {
    background-color: #ffffff;
    color: #034ea2;
    border-radius: 4px;
  }
  .dropbtn:hover .dropimg:hover {
    display: none;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: #000000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    color: #034ea2;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    color: #034ea2;
  }

  .btn {
    margin-left: 50px;
    margin-right: 10px;
    margin-top: 5px;
    /* padding: 10px 28px 10px 28px; */
    background-color: #00944a;
    color: #ffffff;
    font-size: 20px;
    border: none;
    border-radius: 3px;
    font-weight: 600;
    width: 120px;
    text-align: center;
    height: 44px;
  }

  .navbarsmap:hover {
    border-bottom: 2px solid #034ea2;
  }

  .menu:hover {
    color: #034ea2;
  }

  .menu:focus {
    background-color: #e6e6e6;
  }

  /* main portion */

  .privacy-contant {
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #ebf4ff 0%, #5991cf 100%);
    padding-bottom: 30px;
  }

  .privacy-contant-main {
    margin: 1.5% 6%;
    padding: 1% 4%;
    background-color: #ffffff;
    border-radius: 7px;
    box-shadow: rgb(0 0 0 / 5%) 0px 5px 15px;
  }

  .privacy-contant-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 10px;
    cursor: pointer;
  }

  .privacy-contant-two {
    font-size: 16px;
    color: gray;
    text-align: justify;
    margin-top: 4vh;
  }
  .highlight-p {
    margin: 1.2rem;
    margin-left: 5px;
    /* align-items: center; */
    display: flex;
    align-items: flex-start;
  }

  .highlight-p p {
    margin: 0;
    font-size: 17px;
  }

  /********* Footer CSS **********/

  .sub p {
    margin: 0;
    margin-top: 2.5vh;
  }

  @media (min-width: 650px) {
    .footer-main {
      height: 350px;
    }

    .footer {
      font-size: 14px;
      background: url("../assets/footer/WebsiteFooter.gif");
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #000000;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom;
    }

    .row2-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 350px;
      /* width: 100%; */
    }

    .foota {
      color: #000000;
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .sub {
      display: flex;
      flex-direction: column;
      margin: 0 25px;
      text-align: left;
      font-size: 18px;
      width: 100%;
    }

    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #fff;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      background-color: #034da2;
    }
  }

  @media (max-width: 650px) {
    .footer {
      background-color: #034ea2;
      font-size: 14px;
      background-size: contain;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      color: #fff;
      padding-top: 3vh;
    }

    .row-footer {
      display: flex;
      flex-direction: column;
    }

    .row2-footer {
      display: flex;
      flex-direction: column;
      padding: 0 10%;
    }

    .foota {
      color: #ffffff;
      margin-top: 1em;
    }

    .img {
      display: none;
    }

    .sub {
      font-size: 16px;
      margin-top: 1vh;
      padding-bottom: 1vh;
      border-bottom: 2px solid white;
    }
    .subh {
      text-align: left;
      font-size: 18px;
    }

    .copyright {
      display: flex;
      height: 5vh;
      color: #034da2;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      background-color: white;
    }
  }

  .foota {
    margin-bottom: 5px;
    text-decoration: none;
  }

  /* footer end */

  .mn1 {
    align-items: center;
    justify-content: center;
    padding: 0px 6px 8px 6px;
    margin: 1px;
    color: #ffffff;
    text-decoration: none;
  }

  .copytop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 40px;
    background-color: #6c6c6d;
    border-top: 2px solid #ffffff;
  }

  @media only screen and (max-device-width: 600px) {
    .privacy-header {
      font-size: 17px;
      font-weight: 500;
    }

    .privacy-contant-main {
      margin: 2% 8%;
      padding: 1% 4%;
      box-shadow: rgb(0 0 0 / 35%) 0px 2px 5px;
    }

    .mail {
      color: #034ea2;
    }

    .aboutus-newsletter h1 {
      font-size: 20px;
      text-align: center;
    }

    .aboutus-newsletter {
      display: flex;
      flex-direction: column;
      padding: 5% 10% 5% 10%;
      justify-content: space-between;
      align-items: center;
      background-color: #034ea2;
      color: #ffffff;
      margin-top: 10%;
    }
  }

  @media screen and (max-width: 551px) {
    .container {
      max-width: 100%;
      overflow-x: hidden;
    }

    #current {
      color: #034ea2;
    }

    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .nav {
      display: none;
    }

    .navbar {
      margin-right: 50px;
      color: #000000;
      text-decoration: none;
    }

    .img {
      width: 100px;
      height: 75px;
      margin-right: 50px;
    }

    .navnav {
      float: right;
      display: flex;
      margin-left: 50px;
    }

    .btn {
      margin-left: 40px;
      margin-right: 20px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #00944a;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }
    .blackshadow {
      background-color: rgba(0, 0, 0, 0.6);
    }

    .containermain {
      position: fixed;
      max-height: 100%;
      overflow-y: hidden;
    }

    .highlight-img {
      margin: 0px 10px 3px 0px;
    }
  }

  @media (min-width: 600px) {
    .navbar {
      margin-left: 50px;
      color: pink;
      text-decoration: none;
    }

    .navnav {
      float: right;
      display: flex;
      margin-right: 135px;
    }

    .btn {
      margin-left: 80px;
      margin-right: 10px;
      margin-top: 5px;
      padding: 10px 25px 10px 25px;
      background-color: #03931b;
      color: #ffffff;
      font-size: 18px;
      border: none;
      border-radius: 4px;
      font-weight: 600;
    }

    #in {
      margin-left: 230px;
      margin-right: 60px;
    }

    #in1 {
      margin-right: 200px;
      margin-left: 60px;
    }

    #box-item {
      height: 300px;
      margin-left: 20px;
      margin-right: 20px;
    }

    .text {
      margin: 20px;
      text-align: center;
      color: #000000;
      font-style: bold;
    }

    .img2 {
      margin: auto;
      width: 1296px;
      height: 600px;
    }

    .highlight-img {
      margin: 0px 5px 0px 0px;
      float: top;
      padding-top: 0;
    }
  }

  @media (max-width: 1000px) and (min-width: 650px) {
    .img2 {
      margin: auto;
      width: 690px;
    }
    .row {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .item {
      background: #ffffff;
      text-align: center;
      margin-bottom: 30px;
      height: auto;
      padding: 25px 5px;
      border-radius: 4px;
    }
  }

  @media (max-width: 1200px) and (min-width: 1000px) {
    .sub {
      margin-left: 60px;
      margin-right: 60px;
      text-align: left;
      font-size: 22px;
    }
    #row2 {
      padding: 5px;
    }
  }
</style>
<template>
  <div class="header" id="myTopnavOne">
    <img
      v-if="!viewIcon"
      src="../assets/CREDINLOGO.svg"
      class="credin-logo"
      id="myTopnavTwo"
      alt="logo.svg"
      height="70"
      width="140"
      @click="$router.push('/home')"
      style="cursor: pointer"
    />
    <div class="header-one" id="myTopnav">
      <p class="menu" @click="$router.push('/home')">
        <span class="navbarsmap">Ho</span>me
      </p>
      <div v-if="!viewIcon">
        <div class="menu">
          <div class="dropdown">
            <button class="dropbtn">Product</button>
            <div class="dropdown-content">
              <!-- <a @click="$router.push('/pratham')">Pratham</a> -->
              <a href="/aarambh">Aarambh</a>
              <a href="/shiksha">Shiksha</a>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- <p class="menu" @click="$router.push('/pratham')">Pratham</p> -->
        <p class="menu" @click="$router.push('/aarambh')">Aarambh</p>
        <!-- <p class="menu" @click="$router.push('/shiksha')">Shiksha</p> -->
      </div>
      <p class="menu" @click="$router.push('/ourjourney')">
        <span class="navbarsmap">Our</span> Journey
      </p>
      <div class="menu">
        <button style="height: 6vh; margin-left: 1vw" @click="goToWebsite">
          Download App
        </button>
      </div>
      <img
        @click="displayHeader"
        src="../assets/menu-hor.svg"
        alt="menu-bar.svg"
        style="height: 2vh"
        v-if="!viewIcon"
      />
      <img
        @click="displayHeader"
        src="../assets/Close1.svg"
        alt="menu-bar.svg"
        style="height: 4vh"
        v-else
      />
    </div>
  </div>

  <div :class="{ blurBackground: viewIcon }">
    <div class="privacy-contant">
      <div class="privacy-contant-main" @click="showPolicy">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Terms of use</h1>
          <img
            v-if="!privacyPopUp"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="privacyPopUp">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              Welcome to our website. Upkram Technologies Private Limited
              (hereinafter referred to as “Company”, “We”, “Us”, or “Our”) owns
              and maintains this website, www.credin.in (“Website”). Through
              this Website, the Company provides the credit and other financial
              products to its clients (“Services”).
            </p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              By using the Website or the Services, you (hereinafter referred to
              as “End User,” “You,” or “Your”, “Yourself”) are agreeing to be
              bound by the following terms and conditions (“Terms of Use”).
              Please note that by browsing the Website, completing any online
              form, application or survey or availing any Service offered by the
              Company, You will be deemed to have read, understood and accepted
              the Terms of Use.
            </p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              By mere access to the Platform or any part thereof, you signify
              your assent to this Privacy Policy and consent to the processing
              of your personally identifiable information (Personal Information,
              Sensitive Personal Data or Information) to Upkram technologies pvt
              ltd (“we” or “our” or “us” or “Credin”). This Privacy Policy is
              incorporated into and subject to the Terms of Use of the Platform.
            </p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              The Company may make changes, without prior notification, to the
              Terms of Use from time to time at the Company’s sole discretion.
              The revised Terms of Use shall be made available on the Website.
              You are requested to regularly visit the Website to view the most
              current Terms of Use. The Website may require You to provide Your
              consent to the updated Terms of Use in a specified manner before
              any further use of the Website and/or the Services is permitted.
              If no such separate consent is sought, Your continued use of the
              Website or the Services, following changes to the Terms of Use,
              will constitute Your acceptance of those changes
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCUPI">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">End User Agreement</h1>
          <img
            v-if="!CUPI"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="CUPI">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              End User agrees to the following: 1. End User will use the Website
              for lawful purposes only and comply with all applicable laws and
              regulations while using and transacting on the Website or availing
              any of the Services. 2. End User will provide authentic and true
              information in all instances where such information is requested
              from the End User. The Company reserves the right to confirm and
              validate the information and other details provided by the End
              User at any point of time. If upon confirmation, End User details
              are found not to be true (wholly or partly), the Company has the
              right in its sole discretion to reject the registration and debar
              the End User from using the Website, without prior intimation
              whatsoever. 3. End User shall access the Website and transact at
              End User’s sole risk and are using the End User’s best and prudent
              judgment before entering into any transaction through this
              Website.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCFSI">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">End User Eligibility</h1>
          <img
            v-if="!CFSI"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="CFSI">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              You understand and accept that not all products and Services
              offered on this Website are available in all geographic areas, and
              You may not be eligible for all the products or Services offered
              by the Company on the Website. The Company reserves the right to
              determine the availability and eligibility for any product or
              Service
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCDDI">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Prohibited Use</h1>
          <img
            v-if="!CDDI"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="CDDI">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              The Website and the Services may be used only for lawful purposes.
              You are prohibited from using any robot, spider or any other data
              mining technology or automatic or manual process to monitor,
              cache, extract data from, copy or distribute the intellectual
              property, proprietary material or other user content available on
              or accessed through the Website. You are also prohibited from
              using the Website in any manner that could damage, disable or
              impair the Website, or interfere with any other party’s use, or
              enjoyment of the Website.
            </p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              Personal information You provide to the Company on the Website is
              governed by the Company’s Privacy Policy located at
              www.credin.in/privacypolicy. Your election to use the Website or
              avail the Services indicates Your acceptance of the terms of the
              Company’s Privacy Policy.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCCI">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Intellectual Property Rights</h1>
          <img
            v-if="!CCI"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="CCI">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              You are aware and accept that all information, content, materials,
              products (including, but not limited to text, content,
              photographs, graphics, video and audio content) on the Website is
              protected by copyright in favour of the Company under the
              applicable copyright laws and the general intellectual property
              law. You acknowledge that the Company owns all right, title and
              interest in and to the Services, including without limitation all
              intellectual property rights including, with respect to the
              applications and the Website associated with the Services.
              Accordingly, You agree that you will not copy, reproduce, sell,
              redistribute, publish, enter into a database, display, perform,
              modify, transmit, license, create derivatives from, transfer, or
              in any way exploit, any part of any information, content,
              materials, Services available on or through the Website, except
              that which you may download for your own personal, non-commercial
              use. You also agree that You will not use any robot, spider, other
              automated device to monitor or copy any content from the Website.
              The Company is the sole owner of the Company marks, service marks,
              trade name and any other marks used by the Company (collectively
              “Trademarks”). You agree not to interfere with Our rights in the
              Trademarks, including challenging Our use, registration or
              application to register the Trademarks, anywhere in the world, and
              that You will not harm, misuse, or bring into disrepute any of the
              Trademarks. The goodwill derived from using the Trademarks or any
              part thereof, shall inure exclusively to the benefit of the
              Company. All Trademarks, domain name, trade dress including the
              look, feel and design of the Website, interfaces, etc., and the
              selection and arrangements thereof, is the property of the
              Company, unless otherwise indicated. All this is protected under
              copyright, trademark and other applicable intellectual property
              laws and may not be used by You, except as permitted by the
              Company. The Website is operated by and is the sole property of
              the Company. Any and all material on the Website is protected by
              patents, copyrights, trademarks, and other intellectual property
              rights that are owned by the Company or by other parties that have
              licensed such material to the Company. Modification of the
              materials on the Website or use of the Website, Services or other
              materials for any purpose other than for the purpose as agreed to
              between the parties is a violation of the said patents,
              copyrights, trademarks and other intellectual proprietary rights,
              and is expressly prohibited.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCIA">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Disclaimer of Warranties</h1>
          <img
            v-if="!CIA"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="CIA">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p style="font-weight: bold">
              TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, THE
              MATERIALS AND CONTENT ON THIS WEBSITE ARE PROVIDED “AS IS” AND
              WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESSED OR IMPLIED AND THE
              COMPANY DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING,
              BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND
              FITNESS FOR A PARTICULAR PURPOSE.
            </p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              The information, software, contents, products and services
              included in or available through our Website may include
              inaccuracies, typographical errors and pricing errors. We assume
              no responsibility for any error, omission, interruption, deletion,
              corruption, defect, delay in operation or transmission,
              communications line failure, theft or destruction or unauthorized
              access to, or alteration of, any End User communication or any
              content provided through the Website. The Company is not
              responsible for any problems or technical malfunction of any
              telephone network or lines, electronic networks, computer online
              systems, servers or providers, computer equipment, software,
              failure of any email or players due to technical problems or
              traffic congestion on the Internet or on any of the Services or
              combination thereof, including any injury or damage to End Users
              or to any End User’s computer related to or resulting from
              participation or downloading materials in connection with the
              Website or the Services. Under no circumstances shall the Company
              be responsible for any loss or damage resulting from use of the
              Website or the Services, or from the conduct of any End Users of
              the Website or the Services, whether online or offline. The
              Services are provided “as-is” and as available and the Company
              makes no representations or warranties of any kind as to the
              Website, the Services or the content thereof, including without
              limitation, as to availability of the Website and/ or Services for
              access and use. In particular, the Company expressly disclaims all
              warranties of fitness for a particular purpose, accuracy,
              completeness or non-infringement. We cannot guarantee and do not
              promise any specific results from use of the Website and the
              Services.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCamera">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Indemnification</h1>
          <img
            v-if="!camera"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="camera">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              You agree to defend, indemnify and hold harmless the Company, Our
              officers, directors, employees and agents, from and against any
              and all claims, damages, obligations, losses, liabilities, costs
              or debt, and expenses (including but not limited to attorney’s
              fees) arising from: (i) Your use of and access to the Website;
              (ii) Your violation of any term of these Terms of Use or any other
              Company policy; (iii) Your violation of any third party right,
              including without limitation any intellectual property right or
              privacy right; (iv) or as a result of any infringing, threatening,
              libelous, obscene, harassing or offensive material uploaded /
              transmitted by You on the Website
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showMicrophone">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Limitation Of Liability</h1>
          <img
            v-if="!microphone"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="microphone">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              To the fullest extent permitted by applicable law, the Company
              shall not be liable for any direct, indirect, incidental, special,
              punitive or consequential damages, or lost profits resulting from
              Your access to or use of the Website or Service, whether based on
              breach of contract, breach of warranty, tort (including
              negligence), or any other legal theory. This includes Your
              inability to access or use (including due to modification,
              suspension, blocking, discontinuance, cancellation, or termination
              of the Website or any part thereof) the Website. These limitations
              apply to any matter related to the Website, third party Internet
              sites, programs or conduct, viruses or other disabling features,
              incompatibility between the Website and other services, software,
              or hardware, and any delay or failure in initiating, conducting,
              or completing any transmission or transaction in connection with
              the Website in an accurate or timely manner. These limitations
              also apply even if this remedy does not fully compensate You for
              any losses, or fails its essential purpose; or even if the Company
              knew or should have known about the possibility of the damages.
              Without limiting the foregoing, under no circumstances shall the
              Company be held liable for any delay or failure in performance
              resulting directly or indirectly from acts of nature, forces, or
              causes beyond its reasonable control, including, without
              limitation, Internet failures, computer equipment failures,
              telecommunication equipment failures, other equipment failures,
              electrical power failures, strikes, labor disputes, riots,
              insurrections, civil disturbances, shortages of labor or
              materials, fires, floods, storms, explosions, acts of God, war,
              governmental actions, orders of domestic or foreign courts or
              tribunals, non-performance of third parties, or loss of or
              fluctuations in heat, light, or air conditioning.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showStorage">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Third Party Websites</h1>
          <img
            v-if="!storage"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="storage">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              You understand and accept that the Company is not responsible for
              the availability of content, or other services on third-party
              sites linked from the Website. You are aware that the access of
              hyperlinks to other internet websites are at your own risk, and
              the content, accuracy, opinions expressed, and other links
              provided by these websites are not verified, monitored or endorsed
              by the Company in any way. The Company does not make any
              warranties, and expressly disclaims all warranties, express or
              implied, including without limitation, those of merchantability
              and fitness for a particular purpose, title or non-infringement
              with respect to any information or services or products that are
              available or advertised or sold through these third-party
              websites.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCONI">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Choice Of Law And Jurisdiction</h1>
          <img
            v-if="!CONI"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="CONI">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              This Agreement shall be construed in accordance with the
              applicable laws of India. The Courts at vadodara shall have
              exclusive jurisdiction in any proceedings arising out of this
              Terms of Use
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showUDYO">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Severability</h1>
          <img
            v-if="!UDYO"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="UDYO">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              If any term, provision, covenant or restriction of these Terms of
              Use is held by a court of competent jurisdiction to be invalid,
              void or unenforceable, the remainder of the terms, provisions,
              covenants and restrictions of these Terms of Use shall remain in
              full force and effect and shall in no way be affected, impaired or
              invalidated.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showPCI">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">No Waiver</h1>
          <img
            v-if="!PCI"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="PCI">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              The rights and remedies available under this Terms of Use may be
              exercised as often as necessary and are cumulative and not
              exclusive of rights or remedies provided by law. It may be waived
              only in writing. Delay in exercising or non-exercise of any such
              right or remedy does not constitute a waiver of that right or
              remedy, or any other right or remedy.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showDTP">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">General</h1>
          <img
            v-if="!DTP"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="DTP">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              You cannot assign or otherwise transfer the Terms of Use, or any
              right granted hereunder to any third party. These Terms of Use
              comprise the full and complete agreement between You and the
              Company with respect to the use of the Cloud Service and
              supersedes and cancels all prior communications, understandings
              and agreements between You and the Company, whether written or
              oral, expressed or implied with respect thereto
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showLTS">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Cancellation Policy</h1>
          <img
            v-if="!LTS"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="LTS">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              Since Credin is incurring costs and dedicating time, manpower,
              technology resources and effort to your service and loan
              processing therefore cancellation of loan is not allowed and no
              amount/ fees/ charges will be refunded.
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showCPP">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Refund Policy</h1>
          <img
            v-if="!CPP"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="CPP">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              During an application processing, Credin charges fee at defined
              rates (Mentioned in the FAQ) and communicated to the applicant
              well in advance. Refund cannot be provided for charged fee because
              resources and man hours spent on delivering the service are
              non-returnable in nature. Further, we can’t refund or credit any
              money paid to government entities such as taxes, or to other third
              parties with a role in processing loan application. Under any
              circumstance, fees and charges are non-refundable. For any
              grievance you can reach out to help@credin.in
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showAIC">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Force Majeure</h1>
          <img
            v-if="!AIC"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="AIC">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              Credin shall not be considered in breach of any terms of service,
              and shall not be liable to the Client for any cessation,
              interruption, or delay in the performance of its obligations by
              reason of earthquake, flood, fire, storm, lightning, drought,
              landslide, hurricane, cyclone, typhoon, tornado, natural disaster,
              act of God or the public enemy, epidemic, famine or plague, action
              of a court or public authority, change in law, explosion, war,
              terrorism, armed conflict, labor strike, lockout, boycott or
              similar event beyond our reasonable control, whether foreseen or
              unforeseen (each a “Force Majeure Event”).
            </p>
          </div>
        </div>
      </div>
      <div class="privacy-contant-main" @click="showYPC">
        <div class="privacy-contant-one">
          <h1 class="privacy-header">Credit Bureau T &amp; C</h1>
          <img
            v-if="!YPC"
            src="../assets/homepage/Navigationicon.svg"
            alt="navigation.io"
          />
          <img
            v-else
            src="../assets/homepage/Upperarrow.svg"
            alt="navigation.io"
          />
        </div>
        <div class="privacy-contant-two" v-if="YPC">
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              In connection with submission of the application for my credit
              information (“Consumer Credit Information”) offered by Equifax
              (“CIC”) through Upkram Technologies Pvt Ltd (referred to as the
              “Company”) and delivery of the Consumer Credit Information to the
              Company, I hereby acknowledge and agree to the following:
            </p>
          </div>
          <!-- <br /> -->
          <div class="highlight-p">
            <img class="highlight-img" src="../assets/img/Check.svg" />
            <p>
              A. The Company is my lawfully appointed agent and he / it has
              agreed to be my agent for the purposes, including, without
              limitation, to receive the Consumer Credit Information from CIC on
              my behalf and use it in the manner consistent with the Agreement
              entered into between the Company and CIC, and the Company has
              granted its consent for being appointed for the aforesaid
              purpose.<br />B. I grant my unconditional consent to the Company
              to receive the Consumer Credit Information from CIC on my behalf
              and use it in the manner consistent with the Agreement entered
              into between the Company and CIC, as the case may be, and the
              Company has granted its consent for being appointed for the
              aforesaid purpose. I hereby represent and acknowledge that: (a) I
              have carefully read the terms and conditions of the Agreement
              entered into between the Company and CIC understood the same; or
              (b) the Terms of Understanding in relation to the use of the
              Consumer Credit Information has been agreed between me and the
              Company.<br />C. I hereby expressly grant unconditional consent
              to, and direct, CIC to deliver and / or transfer my Consumer
              Credit Information to the Company on my behalf.<br />D. I shall
              not hold CIC responsible or liable for any loss, claim, liability,
              or damage of any kind resulting from, arising out of, or in any
              way related to: (a) delivery of my Consumer Credit Information to
              the Company; (b) any use, modification or disclosure by the
              Company of the contents, in whole or in part, of my Consumer
              Credit Information, whether authorized or not; (c) any breach of
              confidentiality or privacy in relation to delivery of my Consumer
              Credit Information to the Company ; (d) for any use made by the
              Company which is contrary to the Agreement entered into between
              the Company and CIC<br />E. I acknowledge and accept that: (a) CIC
              has not made any promises or representations to me in order to
              induce me to provide my Consumer Credit Information or seek any
              consent or authorization in this regard; and (b) the
              implementation of the Agreement between CIC and the Company is
              solely the responsibility of the Company.<br />F. I agree that I
              may be required to record my consent / provide instructions
              electronically and in all such cases I understand that by clicking
              on the “I Accept” button below, I am providing “written
              instructions” to Company authorizing Company to obtain my Consumer
              Credit Information from my personal credit profile from Equifax. I
              further authorize Company to obtain such information solely to
              confirm my identity and display my Consumer Credit Information to
              me. Further in all such cases by checking this box and clicking on
              the Authorize button, I agree to the terms and conditions,
              acknowledge receipt of CIC privacy policy and agree to its terms,
              and confirm my authorization for Company to obtain my Consumer
              Credit Information.<br />G. I understand that in order to deliver
              the product to me, I hereby authorize Company, to obtain my
              Consumer Credit Information from CIC.<br />H. By submitting this
              registration form, I understand that I am providing express
              written instructions for Company to request and receive
              information about me from third parties, including but not limited
              to a copy of my consumer credit report and score from consumer
              reporting agencies, at any time for so long as I have an active
              Company account. I further authorize Company to retain a copy of
              my information for use in accordance with Company’s Terms of Use
              and Privacy Policy.<br />I. I UNDERSTAND THAT THE PRODUCT IS
              PROVIDED ON AN “AS-IS”, “AS AVAILABLE” BASIS AND CIC EXPRESSLY
              DISCLAIMS ALL WARRANTIES, INCLUDING THE WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT.<br />J. I shall not sue or otherwise make or
              present any demand or claim, and I irrevocably, unconditionally
              and entirely release, waive and forever discharge CIC, its
              officers, directors, employees, agents, licensees, affiliates,
              successors and assigns, jointly and individually (hereinafter
              “Releasee”), from any and all manner of liabilities, claims,
              demands, losses, claims, suits, costs and expenses (including
              court costs and reasonable attorney fees) (“Losses”), whatsoever,
              in law or equity, whether known or unknown, which I ever had, now
              have, or in the future may have against the Releasee with respect
              to the submission of my Consumer Credit Information and / or my
              decision to provide CIC with the authority to deliver my Consumer
              Credit Information to the Company. I agree to defend, indemnify,
              and hold harmless the Releasee from and against any and all Losses
              resulting from claims made against CIC L by third parties arising
              from and in connection with this letter.<br />K. I agree that the
              terms of this confirmation letter shall be governed by the laws of
              India and shall be subject to the exclusive jurisdiction of the
              courts located in Mumbai in regard to any dispute arising hereof.
              CIC is entitled to assign its rights hereunder to any third person
              without taking my prior written consent
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-main">
      <div class="footer">
        <div class="row-footer" style="padding: 0px 10%">
          <div class="row2-footer" id="row2">
            <div class="sub">
              <img
                class="img"
                src="../assets/img/Credinlogo.svg"
                alt="credin"
                @click="$router.push('/home')"
                style="cursor: pointer"
              />
              <p
                style="cursor: pointer; font-weight: bold; margin-top: 0"
                @click="$router.push('/contact-us')"
              >
                Contact Us
              </p>
              <p>+919879015554</p>
              <p><a class="foota" href="help@credin.in">help@credin.in</a></p>
              <div style="display: flex; margin-top: 3vh">
                <a
                  href="https://www.facebook.com/credin25"
                  class="mn1"
                  style="padding: 0px 10px 8px 0"
                >
                  <img src="../assets/Fb.svg"
                /></a>
                <a href="https://www.instagram.com/credinindia/" class="mn1"
                  ><img src="../assets/Insta.svg"
                /></a>
                <a href="https://twitter.com/home-" class="mn1"
                  ><img src="../assets/Twitter.svg"
                /></a>
                <a
                  href="https://www.youtube.com/channel/UC-H2CRfZIdPDsqk_JrfPRtw"
                  class="mn1"
                  ><img src="../assets/YouTube.svg"
                /></a>
                <a href="https://in.linkedin.com/company/upkram" class="mn1"
                  ><img src="../assets/Linkdin.svg"
                /></a>
              </div>
            </div>
            <div class="sub">
              <p style="cursor: pointer" @click="$router.push('/omnichannel')">
                Omni Channel
              </p>
              <p
                style="cursor: pointer"
                @click="$router.push('/nbfc-partners')"
              >
                NBFC Partners
              </p>
              <p style="cursor: pointer" @click="$router.push('/faq')">FAQs</p>
              <p
                style="cursor: pointer"
                @click="$router.push('/privacy-policy')"
              >
                Privacy policy
              </p>
              <p
                style="cursor: pointer"
                @click="$router.push('/terms-conditions')"
              >
                Terms & Conditions
              </p>
            </div>
            <div class="sub" style="border-bottom: none">
              <p style="font-weight: bold">Registered office</p>
              <p style="font-size: 14px; font-weight: bold; color: #034ea2">
                Upkram Technologies Pvt. Ltd.
              </p>
              <p>3-4,3rd Floor, Riyaplex,</p>
              <p>80 Urmi Society, BPC Road,</p>
              <p>Vadodara, 390 020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>
        All right reserved &#169; Upkram Technologies Pvt. Ltd. @{{
          new Date().getFullYear()
        }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        privacyPopUp: false,
        CUPI: false,
        CFSI: false,
        CDDI: false,
        CCI: false,
        CIA: false,
        camera: false,
        microphone: false,
        storage: false,
        CONI: false,
        UDYO: false,
        PCI: false,
        DTP: false,
        LTS: false,
        CPP: false,
        AIC: false,
        YPC: false,
        SP: false,
        LOS: false,
        YC: false,
        GO: false,
        //Header
        viewIcon: false,
      };
    },

    async created() {
      window.scrollTo(0, 0);
    },

    methods: {
      displayHeader() {
        this.viewIcon = !this.viewIcon;
        var x = document.getElementById("myTopnav");
        if (x.className === "header-one") {
          x.className += " responsive";
        } else {
          x.className = "header-one";
        }
        var y = document.getElementById("myTopnavOne");
        if (y.className === "header") {
          y.className += "responsive-one";
        } else {
          y.className = "header";
        }
        var z = document.getElementById("myTopnavTwo");
        if (z.className === "credin-logo") {
          z.className += "responsive-two";
        } else {
          z.className = "credin-logo";
        }
      },

      goToWhatsapp() {
        window.location.href = "https://wa.me/+919879015554";
      },

      goToWebsite() {
        window.location.href = "https://play.google.com/store/apps/details?id=com.credin.app";
      },

      goToMap() {
        window.location.href = "https://goo.gl/maps/rR6763JJZUVbi3bv8";
      },

      goToFacebook() {
        window.location.href = "https://www.facebook.com/credin25/";
      },

      goToTwitter() {
        window.location.href = "https://twitter.com/credin4";
      },

      goToInstagram() {
        window.location.href = "https://www.instagram.com/credinindia/?hl=en";
      },

      goToLinkdin() {
        window.location.href = "https://in.linkedin.com/company/upkram";
      },

      showPolicy() {
        this.privacyPopUp = !this.privacyPopUp;
      },

      showCUPI() {
        this.CUPI = !this.CUPI;
      },

      showCFSI() {
        this.CFSI = !this.CFSI;
      },

      showCDDI() {
        this.CDDI = !this.CDDI;
      },

      showCCI() {
        this.CCI = !this.CCI;
      },

      showCIA() {
        this.CIA = !this.CIA;
      },

      showCamera() {
        this.camera = !this.camera;
      },

      showMicrophone() {
        this.microphone = !this.microphone;
      },

      showStorage() {
        this.storage = !this.storage;
      },

      showCONI() {
        this.CONI = !this.CONI;
      },

      showUDYO() {
        this.UDYO = !this.UDYO;
      },

      showPCI() {
        this.PCI = !this.PCI;
      },

      showDTP() {
        this.DTP = !this.DTP;
      },

      showLTS() {
        this.LTS = !this.LTS;
      },

      showCPP() {
        this.CPP = !this.CPP;
      },

      showAIC() {
        this.AIC = !this.AIC;
      },

      showYPC() {
        this.YPC = !this.YPC;
      },

      showSP() {
        this.SP = !this.SP;
      },

      showLOS() {
        this.LOS = !this.LOS;
      },

      showYC() {
        this.YC = !this.YC;
      },

      showGO() {
        this.GO = !this.GO;
      },
    },
  };
</script>
